@import "../../../../../../../../variables.scss";
@import "../../../../../../../Common/Modal/styles.module.scss";

%editorMobileCommon {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #DADADA;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 300;
  min-height: 46px;
}

.quillContainer {
  width: 100%;
}

.editorDesktop {
  display: block;
  width: 100%;
}

.editorModal {
  max-width: 460px;
  height: auto;
  max-height: 400px;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }

  .editorMobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 0 0;
  
    > div {
      width: 100%;
    }

    .buttonContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 20px 0 0 0;
  
      button {
        @extend %big-first-letter;
        @extend %violet-gradient-button;
        font-size: 14px;
        padding: 10px 30px;
        width: 180px;
      }
    }
  }
}
