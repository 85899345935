$main-color: #e2dffd;
$pink: #d87d97;
$violet: #5b5bb4;
$violet-dark: #22225f;
$medium-grey: #bebebe;
$light-grey: #dadada;
$whitey-grey: #f4f4f4;
$snow-white: #f9f9fd;
$grey: #656565;
$border-grey: #979797;
$dark-grey: #4a4a4a;
$blue-grey: #f2f4f6;
$red: #ff0000;
$green: #03ad00;
$green-light: #5cb55a;
$yellow: #efc260;

$violent-violet: #181059;

$violet-gradient: linear-gradient(198.46deg, #7474cb -25%, #40409b 115%);
$disabled-gradient: linear-gradient(198deg, #d8d8d8 -25%, #979797 115%);
$pink-gradient: linear-gradient(198.46deg, #ef97b1 -25%, #d87d98 115%);

$box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.05);

$large-screen: "only screen and (max-width : 1200px)";
$medium-screen: "only screen and (max-width : 1024px)";
$small-screen: "only screen and (max-width : 667px)";
$extra-small-screen: "only screen and (max-width : 375px)";

%transparent-button {
  background: transparent;
  color: $violet;
  border: 1px solid $violet;
  border-radius: 20px;
  text-decoration: none;
}

%violet-gradient-button {
  background: $violet-gradient;
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
}

%disabled-button {
  cursor: auto;
  pointer-events: none;
  background: $disabled-gradient;
  color: white;
}

%big-first-letter {
  &::first-letter {
    text-transform: uppercase;
  }
}

%back-to-homepage {
  @extend %transparent-button;
  padding: 12px 45px;
  font-size: 14px;
  align-self: flex-start;
  margin: 50px 0 0 0;

  @extend %big-first-letter;
}
