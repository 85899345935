@import "../../../variables.scss";

@mixin box-violet {
  background: $violet;

  > span {
    color: white;
  }

  > svg {
    path {
      fill: white;
    }
  }

  .radio i {
    background: white;
  }
}

.subjectBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  box-shadow: $box-shadow;
  transition: background 0.5s ease;

  > span {
    color: $dark-grey;
    margin: 10px 0 0 0;
    white-space: pre;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: normal;
  }

  > svg {
    height: 50px;
    margin: 0 0 20px 0;
  }

  label {
    height: 18px;
    width: 18px;
    padding: 0;
    margin: 0;

    span {
      position: initial;
      height: 18px;
      width: 18px;
    }
  }

  @media (hover: hover) {
    &:hover {
      @include box-violet;
    }
  }

  @media #{$small-screen} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 65px;

    .checkbox {
      order: 1;
      margin: 0;
    }

    > span {
      order: 2;
      margin: 0;
      font-size: 16px;
    }

    > svg {
      height: 35px;
      margin: 0;
      order: 3;
    }
  }

  @media #{$small-screen} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 65px;

    .checkbox {
      order: 1;
      margin: 0;
    }

    > span {
      order: 2;
      margin: 0;
      font-size: 16px;
    }

    > svg {
      height: 35px;
      margin: 0;
      order: 3;
    }
  }
}

.subjectBox.active {
  @include box-violet;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border: 1px solid $light-grey;
  border-radius: 6px;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border: 1px solid $light-grey;
  border-radius: 100%;

  i {
    background: $violet-dark;
    border-radius: 100%;
    width: 8px;
    height: 8px;
  }
}
