@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1;
  background: $whitey-grey;
  overflow: auto;
  padding: 65px 0 0 0;

  .innerContainer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 100%;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    .box {
      display: flex;
      width: calc(100% - 50px);
      max-width: 775px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
      border-radius: 8px;
      padding: 40px 70px;
      margin: 40px 0;

      @media #{$small-screen} {
        padding: 40px 20px;
      }

      > h1 {
        @extend %big-first-letter;
        font-weight: 600;
        font-size: 32px;
        color: #5858B1;
        margin: 10px;

        @media #{$small-screen} {
          font-size: 22px;
          margin: 10px 0;
        }

        > span {
          font-weight: 300;
          color: $dark-grey;
        }
      }

      > p {
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        color: $grey;

        @media #{$small-screen} {
          font-size: 14px;
        }
      }

      .studentCode {
        display: flex;
        margin: 40px 10px 35px 10px;
        align-items: flex-end;

        @media #{$small-screen} {
          margin: 20px 0;
        }

        > span {
          @extend %big-first-letter;
          font-weight: 600;
          font-size: 15px;
          color: #5858B1;
          margin: 0 5px 0 0;
        }
      }

      .studentNumber {
        display: flex;

        @media #{$small-screen} {
          flex-direction: column;
        }

        > span {
          @extend %big-first-letter;
          font-weight: 600;
          font-size: 15px;
          color: #5858B1;
          margin: 5px 5px 0 0;
          min-width: 150px;
        }

        .numbersContainer {
          display: flex;
          flex-wrap: wrap;
          max-width: 450px;
          margin: 0 10px 45px 10px;

          @media #{$small-screen} {
            margin: 10px 10px 45px -4px;
          }

          > label {
            display: flex;
            align-self: flex-start;
            margin: 4px;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            width: 22px;
            height: 22px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0;

            > span {
              font-size: 14px;
              color: #C4C4C4;
              margin: 0;
              padding: 0;
            }

            > input {
              margin: 0;
              padding: 0;
              display: none;
            }

            &.active {
              background: #5858B1;
              border: 1px solid #5858B1;

              > span {
                color: white;
              }
            }
          }
        }
      }

      > button {
        @extend %big-first-letter;
        @extend %violet-gradient-button;
        font-size: 14px;
        padding: 10px 30px;
        min-width: 120px;
  
        &:disabled {
          @extend %disabled-button;
          border: none;
        }
      }
    }
  }
}
