@import "../../../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0 5px 0;
  border-bottom: 1px solid $violet;
  margin: 0 0 35px 0;
  z-index: 2;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    margin: 0 0 10px 0;

    > span:first-of-type {
      display: inline-block;
      @extend %big-first-letter;
    }

    .right {
      display: flex;
      flex-direction: row;

      .textContainer {
        margin: 0 15px;

        > span {
          display: inline-block;
          @extend %big-first-letter;
        }
      }
    }

    @media #{$small-screen} {
      flex-direction: column;
      margin: 0 0 5px 0;

      > span {
        order: 2;
      }

      .right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
  
        .textContainer {
          margin: 0 15px 5px 15px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 15px 0 0;
    }

    input {
      font-size: 28px;
      background: transparent;
      border: none;
      max-width: 100%;
      flex: 1;
      width: 100%;
      color: $dark-grey;

      &.error {
        color: $red;
      }

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $blue-grey inset;
        -webkit-text-fill-color: $dark-grey;
      }
    }

    .bottomInfo {
      span {
        color: $dark-grey;
        font-size: 14px;
        margin: 0 10px;

        &.error {
          color: $red;
        }
      }
    }
  }
}

span.error {
  color: $red;
}

svg.error {
  path {
    fill: $red;
  }
}
