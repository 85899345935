@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > h1 {
    font-size: 32px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px;
    line-height: initial;
    text-align: center;

    @extend %big-first-letter;

    span {
      font-weight: 600;
      color: $violet;
    }
  }

  > p {
    color: $grey;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 10px;
    text-align: center;
    line-height: initial;

    @extend %big-first-letter;
  }

  > .testTypeList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 45px 10px;
    list-style: none;
    justify-content: center;

    @media #{$extra-small-screen} {
      flex-direction: column;
    }

    > li {
      display: flex;
      width: 120px;
      height: 120px;
      background: #FFFFFF;
      box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.0545509);
      border-radius: 8px;
      overflow: hidden;
      margin: 8px;
      padding: 0;
      
      button {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        padding: 20px;
        background: transparent;

        svg {
          margin: 5px 5px 10px 5px;
        }

        span {
          @extend %big-first-letter;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #4A4A4A;
        }
      }

      &.active {
        button {
          background: #5858B1;
          svg {
            path {
              fill: white;
            }
          }

          span {
            color: white;
          }
        }
      }
    }
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;

  a {
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
    min-width: 140px;
    text-decoration: none;

    @extend %big-first-letter;

    &.disabled {
      cursor: auto;
      pointer-events: none;
      background: $disabled-gradient;
      color: white;
    }
  }
}