@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 0 10px 0;

      input {
        background: transparent;
        font-weight: 300;
        font-size: 36px;
        color: $violet;
        border: none;
        max-width: 100%;
        min-width: 220px;
        margin: 0;
        padding: 0;

        @media #{$small-screen} {
          font-size: 24px;
          max-width: 200px;
          min-width: 200px;
        }
      }

      > a {
        margin: 0 10px 0 0;
      }

      button {
        display: flex;
        height: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        transition: all 0.5s ease;
        padding: 8px;
        margin: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        position: relative;

        @media #{$small-screen} {
          padding: 5px;
        }

        &:hover {
          svg {
            margin: 0;
            path {
              fill: $violet;
            }
          }
        }
      }

      > svg {
        display: flex;
        height: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        margin: 0 10px 8px 10px;

        @media #{$small-screen} {
          margin: 0 10px 5px 10px;
        }

        path {
          fill: $violet;
        }
      }

      .error {
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 12px;
        color: $red;
      }
    }

    > svg {
      margin: 0 10px 0 0;

      @media #{$small-screen} {
        height: 15px;
      }
    }

    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      button:first-of-type {
        @extend %violet-gradient-button;
      }

      button {
        @extend %transparent-button;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 10px 20px 10px 0;
        min-width: 120px;
        display: flex;
        font-size: 14px;
        transition: all 0.5s ease;
  
        svg {
          path {
            fill: $violet;
          }
        }
  
        &:hover {
          background-color: $violet;
  
          svg {
            path {
              fill: white;
            }
          }
  
          span {
            color: white;
          }
        }
  
        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }
      }
    }
  }

  .classes {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7px;
    justify-content: flex-start;

    @media #{$small-screen} {
      flex-direction: column;
    }

    > li {
      width: 340px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 7px;

      @media #{$small-screen} {
        width: 100%;
        border-radius: 14px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $violet;
        font-weight: 300;
        font-size: 24px;
        text-decoration: none;
        padding: 10px;

        svg {
          margin: 0 15px 0 0;
        }

        span {
          @extend %big-first-letter;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .curriculum {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px 0;

    h3 {
      align-items: center;
      display: flex;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      border: none;
      width: 100%;
      margin: 0 0 20px 0;
      padding: 0;
    }

    > .subject {
      width: 255px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 7px;
      position: relative;

      @media #{$small-screen} {
        width: 100%;
        border-radius: 14px;
      }

      .topRightText {
        text-align: end;
        position: absolute;
        top: 10px;
        right: 10px;
        font-weight: 800;
        font-size: 8px;
        color: #5858B1;
        text-shadow: 0px 2px 4px rgba(88, 88, 177, 0.64);
        max-width: 80px;
        min-width: 50px;
        text-transform: uppercase;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $violet;
        font-weight: 300;
        font-size: 24px;
        text-decoration: none;
        padding: 10px;
        text-align: center;

        span {
          @extend %big-first-letter;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.modal {
  max-width: 360px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.deleteClassModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    width: 40px;
    height: 40px;
  }

  > .description {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 250px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0 0 0;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 20px;
      margin: 0 5px 0 0;
      min-width: 120px;

      @media #{$small-screen} {
        min-width: 100px;
      }

      &:disabled {
        @extend %disabled-button;
      }

      &:nth-last-of-type(1) {
        @extend %violet-gradient-button;
        margin: 0 0 0 5px;
      }
    }
  }
}