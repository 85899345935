@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }
  }

  .boxContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$medium-screen} {
      flex-direction: column;
    }

    .leftBox {
      display: flex;
      width: calc(50% - 8px);
      flex-direction: column;
      max-width: 1000px;

      @media #{$medium-screen} {
        margin: 0 0 20px 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
