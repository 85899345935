@import "../../../../../../variables.scss";

.container {
  position: relative;
  height: 65px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 30px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.07);
}

.logoLink {
  height: 100%;

  .logo {
    display: flex;
    height: 100%;
    width: auto;
    padding: 5px 0;
    align-self: flex-start;
  
    @media #{$medium-screen} {
      display: flex;
    }
  }
}
