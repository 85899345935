@import "../../../../../../../variables.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  background: white;
  margin: 10px 0;
  border-radius: 8px;
  padding: 20px 0;

  .fillInTheGapsGapsOptionsContent {
    display: flex;
    width: 100%;
    padding: 10px 25px 25px 25px;
  
    @media #{$small-screen} {
      flex-direction: column;
    }
  }
  
  .fillInTheGapsGapsOptions {
    display: flex;
    width: 100%;
    padding: 10px;
    border: 1px solid $violet;
    list-style: none;
  
    > li {
      font-size: 14px;
      color: $violet;
      margin: 5px;
    }
  }
}