@import "../../../../../variables.scss";
@import "../styles.module.scss";

.sidebarLeft {
  @extend .sidebar;
  left: 0;
  top: 0;

  > button {
    position: fixed;
  }

  @media #{$small-screen} {
    width: 100%;

    > button {
      position: inherit;
    }
  }
}
