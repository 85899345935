@import "../../../../variables.scss";

.container {
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;

  .left,
  .right {
    display: flex;
    flex: 1;
    align-items: center;
    width: 50%;
  }

  .left {
    justify-content: center;
    background: #e2e0fd;
    padding: 100px;

    @media #{$large-screen} {
      padding: 100px 50px;
    }

    > svg {
      width: 500px;
    }

    @media #{$medium-screen} {
      display: none;
    }

    .info {
      display: flex;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      position: relative;

      h1 {
        margin: 24px 0 16px 0;
        color: $violent-violet;
        font-size: 24px;
        line-height: 48px;
        z-index: 1;
      }

      p {
        color: $violent-violet;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 24px 0;
        z-index: 1;
      }

      .logo {
        width: 117px;
        height: 46px;
        min-height: 46px;
      }

      .cloud,
      .card,
      .bulb {
        position: absolute;
      }

      .cloud {
        top: 5%;
        right: 25%;
      }

      .card {
        bottom: 15%;
        right: 0;
      }

      .bulb {
        bottom: -5%;
        left: 5%;
      }
    }
  }

  .right {
    align-items: flex-start;
    align-self: center;
    flex-direction: column;
    padding: 0px 100px 30px 100px;
    overflow: auto;
    max-height: 100vh;

    @media #{$large-screen} {
      padding: 0px 50px 30px 50px;
    }

    @media #{$medium-screen} {
      align-self: flex-start;
      padding: 25px 20px;
      width: 360px;
      flex: none;
      overflow: initial;
      max-height: initial;
    }

    @media #{$extra-small-screen} {
      align-self: flex-start;
      padding: 5px 20px 30px 20px;
      width: 280px;
      flex: none;
    }

    .logoLink {
      margin: 20px 0 50px 0;

      > svg {
        width: 120px;
      }

      @media #{$medium-screen} {
        margin: 40px 0 40px 0;
      }
    }

    > h1 {
      display: flex;
      font-size: 32px;
      font-weight: 300;
      margin: 30px 0;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      > span {
        @extend %big-first-letter;
      }

      @media #{$medium-screen} {
        margin: 20px 0 35px 0;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  %state {
    display: flex;

    svg {
      margin: 0 25px 0 0;
    }

    @media #{$medium-screen} {
      > span {
        margin: 25px 0 0 0;
      }
    }
  }

  h1.success {
    @extend %state;
    color: $green;
  }

  h1.error {
    @extend %state;
    color: $red;
  }
}
