@import "../../../variables.scss";

.sidebarContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  z-index: 1;
  max-width: 230px;

  @media #{$medium-screen} {
    display: none;
  }
}

.sidebarScroll {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  padding: 140px 0 25px 0;
  box-shadow: 16px 2px 16px rgba(0, 0, 0, 0.04);
  z-index: 3;
  position: relative;
  min-height: 100%;

  .top {
    position: fixed;
    width: 230px;
    background: white;
    z-index: -1;
    height: 140px;
    top: 0;
  }

  .logoLink {
    display: flex;
    height: 70px;
    margin: 0 40px;
    padding: 15px 0;

    .logo {
      width: 100%;
      height: 100%;
      max-width: 120px;
    }
  }

  .discover {
    display: flex;
    width: 100%;
    align-items: center;
    color: $dark-grey;
    text-decoration: none;
    margin: 20px 0 25px 0;
    position: relative;
    padding: 0 40px;

    @media #{$medium-screen} {
      padding: 0 15px;
    }

    > svg {
      margin: 0 10px 0 0;
    }

    span {
      @extend %big-first-letter;
      font-weight: 600;
    }

    &.active {
      color: $violet;

      > svg {
        path {
          fill: $violet;
        }
      }
    }
  }

  .testsBase {
    display: flex;
    width: 100%;
    align-items: center;
    color: $dark-grey;
    text-decoration: none;
    margin: 25px 0;
    position: relative;
    padding: 0 40px;

    @media #{$medium-screen} {
      padding: 0 15px;
    }

    > svg {
      margin: 0 10px 0 0;
    }

    span {
      @extend %big-first-letter;
      font-weight: 600;
    }

    &.active {
      color: $violet;

      > svg {
        path {
          fill: $violet;
          stroke: $violet;
        }
      }
    }
  }

  .headingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 0 0 0 40px;
    width: 230px;
    z-index: -2;

    .heading {
      display: flex;
      color: $medium-grey;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin: 10px 0;
      position: flex;
    }
  }

  > ul.list {
    width: 100%;
    list-style: none;
    margin: 0 0 25px 0;
    -webkit-overflow-scrolling: touch;
    z-index: -2;

    @media #{$medium-screen} {
      padding: 0 15px;
    }

    > li {
      margin: 0;

      &:hover {
        background: #fafafa;
      }

      &.active {
        background: $blue-grey;

        > a > span {
          color: $violet;
          font-weight: 600;
        }
      }
    }

    li {
      button {
        display: flex;
        background: transparent;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: $dark-grey;
        margin: 0;
        border: none;
        padding: 0;
        position: relative;
        padding: 10px 40px;

        span {
          flex: 1;
          text-align: left;
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: normal;
        }

        svg {
          margin: 0 5px;
          width: 15px;
          height: 5px;
          transition: all 0.5s ease;

          path {
            fill: $violet;
          }

          &.active {
            transform: rotate(180deg);
          }
        }

        &.active {
          color: $violet;
          font-weight: 600;
        }
      }

      > a {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        color: $dark-grey;
        width: 100%;
        padding: 10px 40px;

        span {
          @extend %big-first-letter;
        }
      }

      ul {
        list-style: none;
        padding: 0 0 5px 0;

        li {
          display: flex;

          &.active {
            a {
              color: $violet;
            }
          }

          a {
            @extend %big-first-letter;
            text-decoration: none;
            font-size: 14px;
            color: $dark-grey;
            padding: 10px 50px;
            width: 100%;

            &:hover {
              color: $violet;
            }
          }
        }
      }
    }
  }

  .createTest {
    @extend %violet-gradient-button;
    margin: 15px 40px;

    @media #{$medium-screen} {
      margin: 20px 15px 10px 15px;
    }
  }

  .addClass {
    @extend %transparent-button;
    margin: 0 30px 0 0;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .createTest {
    display: flex;
    padding: 10px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    justify-content: center;

    span {
      @extend %big-first-letter;
      display: inline-block;
    }
  }
}
