@import "../../../../../../variables.scss";

.endTimeContainer {
  display: flex;
  min-width: 165px;
  padding: 9px;
  background: white;
  border-radius: 8px;
  margin: 10px 0;
  align-items: center;

  > span {
    margin: 0 0 0 8px;
    font-size: 14px;
    color: #554455;
  }
}

.modal {
  max-width: 500px;
  height: auto;
  max-height: 50vh;
  min-height: 200px;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 90%;
    max-height: 500px;
  }
}

.modalContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    font-size: 32px;
    color: $violet;
    margin: 0 0 30px 0;
    font-weight: 700;
  }

  > p {
    font-size: 16px;
    font-weight: 300;
    margin: 0 0 10px 0;
    text-align: center;
    color: $grey;

    .modalContentTime {
      color: $violet;
      font-weight: 700;
    }
  }

  > button {
    @extend %violet-gradient-button;
    @extend %big-first-letter;
    padding: 10px 25px;
    width: 120px;
    margin: 20px 0 0 0;
    font-size: 14px;
  }
}
