@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: inherit;
  position: relative;
}

.settingsContainer {
  display: flex;
  height: 40px;
  max-width: 210mm;
  width: 100%;
  position: relative;
  position: fixed;
  z-index: 2;
  bottom: 20px;

  @media #{$medium-screen} {
    bottom: 100px;
  }

  .returnBtn {
    @extend %violet-gradient-button;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 12px 40px;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }
  }
}
