@import "../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
}

.settingsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 794px;
  justify-content: flex-end;
  margin: 55px 0 100px 0;

  @media #{$small-screen} {
    margin: 45px 0 0 0;
  }

  .dndEnabledBtn {
    @extend %transparent-button;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    right: 10px;
    padding: 10px 30px;
    background: transparent;
    height: 40px;
    margin: 0 10px;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }

    @media #{$small-screen} {
      margin: 0 0 10px 10px;
    }
  }

  .previewBtn {
    @extend %transparent-button;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    right: 10px;
    padding: 10px 30px;
    background: transparent;
    height: 40px;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }

    @media #{$small-screen} {
      margin: 0 0 10px 10px;
    }
  }

  .publishBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    right: 10px;
    margin: 0 20px;
    padding: 12px 30px;
    background: $yellow;
    color: white;
    height: 100%;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }
  }

  button {
    @extend %violet-gradient-button;
    @extend %big-first-letter;

    padding: 10px 50px;
    font-size: 14px;
    margin: 0 0 0 15px;
    height: 40px;
    text-align: center;

    &:disabled {
      @extend %disabled-button;
    }
  }
}


.customFinishModal {
  max-width: 555px;
  height: auto;
  max-height: 390px;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.finishModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    margin: 20px 0;
  }

  > p {
    @extend %big-first-letter;
    font-size: 14px;
    color: #545454;
    text-align: center;
    line-height: 20px;
  }

  .downloadingOptions {
    display: flex;
    max-width: 430px;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;

    .downloadingOptionsBox {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0 0 0 10px;

      &:first-of-type {
        margin: 0 10px 0 0;
      }

      @media #{$small-screen} {
        margin: 0;

        &:first-of-type {
          margin: 0;
        }
      }

      > span {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5B5BB4;
        margin: 15px 0;
      }

      > label {
        display: flex;
        > span {
          @extend %big-first-letter;
        }
      }
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0 0 0;

    @media #{$small-screen} {
      flex-direction: column;
      justify-content: center;
    }

    a {
      @extend %transparent-button;
      @extend %big-first-letter;

      text-align: center;
      padding: 10px 30px;
      font-size: 14px;
      margin: 10px 5px 0 0;
      min-width: 150px;

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }
    }

    > button {
      @extend %violet-gradient-button;
      @extend %big-first-letter;

      justify-content: center;
      padding: 10px 30px;
      font-size: 14px;
      margin: 10px 0 0 5px;
      min-width: 150px;

      &:disabled {
        @extend %disabled-button;
      }

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }
    }
  }
}

.addQuestionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  width: calc(100% - 100px);
  flex-wrap: wrap;

  @media #{$medium-screen} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  @media #{$small-screen} {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.testContent {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.summaryBox {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
  align-items: center;
  margin: 0 0 16px 0;

  @media #{$small-screen} {
    width: 100%;
  }

  > span {
    @extend %big-first-letter;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-end;

    @media #{$small-screen} {
      flex-direction: column;
      flex: auto;
      width: 100%;
      margin: 10px 0 0 0;
      justify-content: flex-start;
    }

    .textContainer {
      @extend %big-first-letter;
      margin: 0 15px 0 15px;
      font-size: 14px;
      color: #545454;

      @media #{$small-screen} {
        margin: 3px 0;
      }
    }
  }
}

.dndQuestionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 8px;
  width: 100%;
  margin: 0 0 10px 0;

  &:last-child {
    margin: 0;
  }

  > div {
    width: 100%;
  }
}

.dndQuestionDraggable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  width: 100%;
  position: relative;
  margin: 0 0 20px 0;
  z-index: 2;
  user-select: none;
  padding: 15px 0;

  > div {
    width: 100%;
  }

  @media #{$small-screen} {
    width: 100%;
  }
}

.editTimeLimitButton {
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;

  svg {
    width: 15px;
    height: 15px;
  }
}

.editTimeLimitModal {
  max-width: 751px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 90%;
    max-height: 500px;
  }
}

.editTimeLimitModalBottomContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;

  button {
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
    min-width: 140px;
    text-decoration: none;

    @extend %big-first-letter;

    &.disabled {
      cursor: auto;
      pointer-events: none;
      background: $disabled-gradient;
      color: white;
    }
  }
}
