@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;

    h3 {
      display: flex;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      a {
        text-decoration: none;
        color: $violet;
      }

      span {
        @extend %big-first-letter;
      }

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    button {
      @extend %violet-gradient-button;
      padding: 10px 30px;
      display: flex;
      font-size: 14px;

      span {
        &:last-child {
          @extend %big-first-letter;
        }
      }
    }
  }

  .students {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 -7px;

    .headingItem,
    .listItem {
      > .studentNumber {
        width: 25%;
        display: flex;
        justify-content: flex-start;

        @media #{$medium-screen} {
          width: 31%;
        }

        @media #{$small-screen} {
          width: 50%;
          height: 30px;
        }
      }
      > .accessCode {
        width: 15%;
        display: flex;
        justify-content: center;

        @media #{$medium-screen} {
          width: 14%;
        }

        @media #{$small-screen} {
          display: none;
        }
      }
      > .tests {
        width: 13%;
        display: flex;
        justify-content: center;

        @media #{$medium-screen} {
          width: 14%;
        }

        @media #{$small-screen} {
          display: none;
        }
      }
      > .pointsAverage {
        width: 13%;
        display: flex;
        justify-content: center;

        @media #{$medium-screen} {
          width: 14%;
        }

        @media #{$small-screen} {
          display: none;
        }
      }
      > .date {
        width: 13%;
        display: flex;
        justify-content: center;

        @media #{$medium-screen} {
          width: 14%;
        }

        @media #{$small-screen} {
          display: none;
        }
      }
      > .actions {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media #{$medium-screen} {
          width: 17%;

          > span {
            display: none;
          }
        }

        @media #{$small-screen} {
          width: 50%;
          height: 30px;
        }

        button,
        a {
          justify-content: center;
          display: flex;
          border: none;
          background: transparent;
          padding: 5px;
          margin: 0 5px;

          svg {
            cursor: pointer;
            path {
              fill: #b8b8b8;
            }
          }

          &:hover {
            svg path {
              fill: $violet;
            }
          }
        }

        .toggleIcon {
          display: none;

          @media #{$small-screen} {
            display: flex;
          }

          svg,
          svg path {
            transition: all 0.5s ease;
          }

          &.active {
            svg {
              transform: rotate(180deg);
              path {
                fill: $violet;
                stroke: $violet;
              }
            }
          }
        }

        .tooltipContainer {
          .tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            color: color;
            height: 25px;
            max-width: 300px;
            background: $pink;
            padding: 10px 20px;
            font-size: 12px;
            border-radius: 8px;

            &::after {
              display: none;
            }

            p {
              @extend %big-first-letter;
            }
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .headingItem {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 2px 8px;
      padding: 10px;

      @media #{$small-screen} {
        display: none;
      }

      div {
        height: 30px;
        align-items: center;
        span {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 1px;
          font-weight: 600;
          text-transform: uppercase;
          color: $medium-grey;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .listItem {
      width: 100%;
      height: 45px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 8px;
      padding: 10px;

      @media #{$small-screen} {
        flex-wrap: wrap;
        height: auto;
      }

      div {
        display: flex;
        height: 100%;
        > span {
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding: 5px 20px;
          font-weight: 400;
        }
      }

      .tests,
      .date {
        span {
          font-weight: 600;
          color: #545454;
        }
      }

      .pointsAverage {
        span {
          font-weight: 600;
          color: #a194ef;
        }
      }

      .studentNumber {
        .number {
          background: $main-color;
          border-radius: 4px;
          color: $violet;
          text-transform: uppercase;
          justify-content: center;
          letter-spacing: 1px;
          font-size: 12px;
          width: 130px;
        }

        .new {
          color: #db7d97;
          font-weight: 1000;
          font-size: 13px;
          text-shadow: 2px 2px 2px rgba(219, 125, 151, 0.2);
          text-transform: uppercase;
        }
      }

      .dropDownContainer {
        display: none;
        width: 100%;

        @media #{$small-screen} {
          display: flex;
        }

        > ul {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 10px 0 0 0;
          padding: 0;
          list-style: none;
          li {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            margin: 0;
            padding: 0;

            > span {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              color: $medium-grey;
              text-overflow: ellipsis;
              justify-content: flex-start;
              overflow: hidden;
              white-space: nowrap;
              flex: 1;
              padding: 5px 5px 5px 0;
            }

            &.tests,
            &.date {
              span:nth-of-type(2) {
                font-weight: 600;
                color: #545454;
              }
            }

            &.accessCode {
              span:nth-of-type(2) {
                font-weight: 600;
                color: $violet;
              }
            }

            &.pointsAverage {
              span:nth-of-type(2) {
                font-weight: 600;
                color: #a194ef;
              }
            }
          }
        }
      }
    }
  }
}

.deleteStudentModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}

.addStudentModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  h3 {
    @extend %big-first-letter;
    width: 100%;
    color: $dark-grey;
    font-size: 32px;
    font-weight: 300;
    text-align: center;
    margin: 10px 0 10px 0;

    span:nth-of-type(2) {
      color: $violet;
      font-weight: 600;
    }

    &.violet {
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 10px 0 30px 0;

      svg {
        margin: 0 10px 0 0;
      }

      span {
        @extend %big-first-letter;
        color: $violet;
        font-weight: 600;
      }
    }
  }

  p {
    @extend %big-first-letter;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #545454;
  }

  > div:nth-child(3) {
    width: 180px;
    margin: 20px 0 15px 0;
  }

  input {
    width: 180px;
  }

  div.buttonContainerDouble {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 40px;
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      @extend %violet-gradient-button;
      padding: 10px 30px;
      display: flex;
      font-size: 14px;
      margin: 15px 0 5px 0;

      &:disabled {
        @extend %disabled-button;
      }

      span {
        &:last-child {
          @extend %big-first-letter;
        }
      }
    }
  }
}
