@import "../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > h1 {
    font-size: 32px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px;
    line-height: initial;
    text-align: center;

    @extend %big-first-letter;

    span {
      font-weight: 600;
      color: $violet;
    }
  }

  > p {
    color: $grey;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 10px;
    text-align: center;
    line-height: initial;

    @extend %big-first-letter;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    width: 712px;
    max-width: 100%;
    border-radius: 16px;
    margin: 45px 10px;

    > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 45px 10px 10px 10px;
      list-style: none;
      justify-content: center;
  
      @media #{$extra-small-screen} {
        flex-direction: column;
      }
  
      > li {
        margin: 8px;

        > .timeLimitButton {
          outline: none;
          width: 84px;
          height: 84px;
          background: #F5F5F5;
          border-radius: 12px;
          border: none;
          font-weight: 300;
          font-size: 24px;
          line-height: 29px;
          color: $grey;
          transition: all 0.5s ease;

          &.active {
            background: $violet-gradient;
            color: white;

            &:disabled {
              @extend %disabled-button;
            }
          }

          &:disabled {
            @extend %disabled-button;
          }

          > span {
            font-size: 14px;
          }
        }
      }
    }

    > span {
      margin: 10px 5px;
      font-size: 16px;
      line-height: 19px;
      color: #5252AC;
    }

    > input {
      width: 84px;
      height: 84px;
      background: #F5F5F5;
      border-radius: 12px;
      border: none;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      color: $grey;
      margin: 10px 10px 45px 10px;
      text-align: center;

      &::after {
        content: "min";
      }
    }

    .noTimeLimitButton {
      outline: none;
      height: 84px;
      width: 84px;
      background: #F5F5F5;
      border-radius: 12px;
      border: none;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      color: $grey;
      transition: all 0.5s ease;

      &.active {
        background: $violet-gradient;
        color: white;

        &:disabled {
          @extend %disabled-button;
        }
      }

      &:disabled {
        @extend %disabled-button;
      }
    }
  }
}

.tooltipContainer {
  width: 25px;
  height: 25px;

  .tooltip {
    color: color;
    width: 100%;
    max-width: 300px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.07);
    border-radius: 8px;

    &::after {
      display: none;
    }

    p {
      @extend %big-first-letter;
    }

    @media #{$medium-screen} {
      max-width: 200px;
    }
  }

  i {
    svg {
      cursor: pointer;
    }
  }
}

