@import "../../../../../../../variables.scss";

.creditCardContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > p {
    @extend %big-first-letter;
    font-size: 14px;
    color: $violet;
    margin: 0 0 10px 0;
  }

  > button {
    @extend %violet-gradient-button;
    display: flex;
    margin: 30px 0 25px auto;
    min-width: 160px;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &:disabled {
      @extend %disabled-button;
    }

    span {
      @extend %big-first-letter;
      font-size: 12px;
    }
  }

  .creditCardInputContainer {
    width: 100%;
  }

  .cardExpiryInputContainer, .cardCvcInputContainer {
    width: 45%;
    min-width: 140px;
  }

  .creditCardInputContainer, .cardExpiryInputContainer, .cardCvcInputContainer {
    position: relative;
    margin: 10px 0;

    .labelContainer {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding: 10px 15px;
      align-items: center;
      cursor: text;
  
      .labelHiddenOnActive {
        background: transparent;
        font-size: 16px;
        padding: 0 2px 0 1px;
      }
    
      .labelHiddenOnActive.active {
        display: none;
        transform: none;
      }
  
      label {
        position: relative;
        z-index: 1;
        font-size: 13px;
        color: #787878;
        cursor: text;
        background: $main-color;
        padding: 0 2px;
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),color 150ms cubic-bezier(0.4, 0, 0.2, 1);
        overflow: hidden;
  
        @extend %big-first-letter;
  
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
  
      label.active {
        transform: translateY(-18px) translateX(-6px) scale(0.85);
      }
    }
  }

  .error {
    font-size: 12px;
    color: $red;
  }
}
