@import "../../../../../variables.scss";

.createTestLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  width: 195px;
  height: 195px;
  margin: 20px 15px 20px 0;
  background: transparent;
  border: 1px solid $border-grey;
  border-style: dashed;
  padding: 40px;
  border-radius: 12px;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: $dark-grey;

  span:nth-child(2) {
    @extend %big-first-letter;
  }

  @media #{$small-screen} {
    width: 132px;
    height: 132px;
    padding: 20px;
    font-size: 18px;
    margin: 15px 15px 15px 0;
  }
}

.tests {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  h3 {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    color: $medium-grey;
  }

  .allTestsList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
}

.testsCompleted {
  @extend .tests;
  flex: initial;
}
