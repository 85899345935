@import "../../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    margin: auto;
  }
  ul, ol {
    padding: 0;
    margin: 0 0 0 1.5em;
    li {
      padding: 0;
      margin: 0 0 0 3em;
    }
  }
}
