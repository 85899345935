@import "../../../../../variables.scss";

.bottomContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;

  a {
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
    min-width: 140px;
    text-decoration: none;

    @extend %big-first-letter;

    &.disabled {
      cursor: auto;
      pointer-events: none;
      background: $disabled-gradient;
      color: white;
    }
  }
}
