@import "../../../variables.scss";


.inputContainer {
  display: flex;
  position: relative;

  @media #{$extra-small-screen} {
    margin: 0;
  }

  &:nth-child(1) {
    flex: 1;
    min-width: 150px;
  }

  > div {
    display: flex;
    width: 100%;

    .wrapper {
      width: 100%;
      position: relative;
      align-items: flex-start;

      > div {
        display: flex;
        width: 100%;
        margin: 0;
        position: relative;

        > div {
          width: 100%;
          margin: 0;
        }
      }

      .dropdown {
        position: absolute;
        width: 100%;
        background: #FCFCFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        padding: 10px 15px;
        z-index: 3;
        max-height: 200px;
        overflow: auto;
        margin: 0 0 20px 0;
  
        ul {
          width: 100%;
          list-style: none;
  
          li {
            margin: 5px 0;
  
            button {
              @extend %big-first-letter;
              width: 100%;
              background: transparent;
              border: none;
              font-size: 12px;
              color: $dark-grey;
              text-align: left;
            }

            span {
              font-size: 12px;
              color: $dark-grey;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
