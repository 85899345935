@import "../../../../../../variables.scss";

.onlineQuestionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 794px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: default;

  > div {
    display: flex;
    width: 100%;
    padding: 0;
  }
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 794px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: default;

  .content {
    display: flex;
    width: 100%;
    padding: 25px;

    @media #{$medium-screen} {
      padding: 12.5px;
    }
  
    @media #{$small-screen} {
      padding: 10px;
    }
  
    @media #{$extra-small-screen} {
      padding: 8.92px;
    }
  }
}

.questionNotes {
  display: flex;
  width: 100%;
  max-width: 95%;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  @media #{$medium-screen} {
    margin: 5px 0;
  }

  @media #{$small-screen} {
    margin: 4.54px 0;
  }

  @media #{$extra-small-screen} {
    margin: 0 0 8.92px 25.35px;
    width: 4px 0
  }

  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;

    &:first-child {
      margin: 0;
    }
  }

  .blankNoteType {
    display: flex;
    flex-direction: column;
    border: 1px solid #AFAFAF;

    svg {
      width: 100%;
      height: auto;
      opacity: 0;
    }
  }
}
