@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 200px);
  height: inherit;
  position: relative;
  margin: 0 0 20mm 0;

  @media #{$medium-screen} {
    margin: 0 0 10mm 0;
  }

  @media #{$small-screen} {
    width: 100%;
    margin: 0 0 8mm 0;
  }

  @media #{$extra-small-screen} {
    margin: 0 0 7.14mm 0;
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 297mm;
  margin: 20mm 0 0 0;

  @media #{$medium-screen} {
    min-height: calc(297mm / 2);
    margin: 10mm 0 0 0;
  }

  @media #{$small-screen} {
    min-height: calc(297mm / 2.5);
    margin: 8mm 0 0 0;
  }

  @media #{$extra-small-screen} {
    min-height: calc(297mm / 2.8);
    margin: 7.14mm 0 0 0;
  }
}

.a4 {
  position: absolute;
  top: 0;
  height: 297mm;
  width: 210mm;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;

  @media #{$medium-screen} {
    height: calc(297mm / 2);
    width: calc(210mm / 2);
    padding: calc(20px / 2);
  }

  @media #{$small-screen} {
    max-width: calc(100% - 30px);
    height: calc(297mm / 2.5);
    width: calc(210mm / 2.5);
    padding: calc(20px / 2.5);
  }

  @media #{$extra-small-screen} {
    height: calc(297mm / 2.8);
    width: calc(210mm / 2.8);
    padding: calc(20px / 2.8);
  }
}
