@import "../../../../variables.scss";

.paymentsBox {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: 22px;
  min-width: 424px;
  width: 424px;
  margin: 10px;
  height: 200px;
  position: relative;
  overflow: hidden;

  @media #{$small-screen} {
    width: 100%;
    min-width: calc(100% - 20px);
    min-height: 200px;
    height: auto;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $medium-grey;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0 0 0;

    > span {
      font-weight: 600;
      font-size: 48px;
      color: #A194EF;
    }

    > p {
      @extend %big-first-letter;
      font-size: 14px;
      color: #545454;
      flex: 1;
      padding: 0 40px;

      @media #{$small-screen} {
        padding: 0 10px 0 40px;
      }
    }
  }

  > a {
    @extend %transparent-button;
    @extend %big-first-letter;
    background: white;
    font-size: 14px;
    padding: 8px 20px;
    margin: 10px 0;
    align-self: flex-end;
    text-align: center;
    transition: background-color 0.5s ease;

    &:hover {
      color: white;
      background: $violet;
    }
  }
}