@import "../../../../../../variables.scss";

.studentCodeInput {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #C4C4C4;
  border-left: none;
  border-color: #C4C4C4;
  width: 25px;
  margin: 0 4px;
  text-align: center;
  color: $dark-grey;
  font-size: 18px;
  border-radius: 0;
  padding: 0;

  @media #{$small-screen} {
    flex-direction: column;
  }
}
