@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 16px;
      color: $medium-grey;
      margin: 0 20px 15px 0;
      width: 100%;

      > a {
        text-decoration: none;
        color: $medium-grey;
      }

      > span {
        font-weight: 600;
      }

      @media #{$small-screen} {
        font-size: 14px;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      button {
        @extend %violet-gradient-button;
        padding: 10px 30px;
        display: flex;
        font-size: 14px;
        min-width: 135px;
        align-items: center;
        justify-content: center;
        margin: 3px 0;

        &:first-child {
          margin: 3px 15px 3px 0;
        }
  
        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }
  
        &:disabled {
          @extend %disabled-button;
        }
      }

      .tooltipContainer {
        .tooltip {
          display: flex;
          justify-content: center;
          align-items: center;
          color: color;
          max-height: 55px;
          max-width: 300px;
          background: $pink;
          padding: 10px 20px;
          font-size: 12px;
          border-radius: 8px;

          @media #{$small-screen} {
            max-width: 160px;
            max-height: 155px;
          }
      
          &::after {
            display: none;
          }
      
          p {
            @extend %big-first-letter;
          }
        }
      
        div {
          @extend %big-first-letter;
        }
      }
    }
  }

  .boxInfo {
    width: 100%;
    background: $main-color;
    border-radius: 8px;
    padding: 20px;
    margin: 0 0 10px 0;

    p {
      font-size: 14px;
      line-height: 17px;
      color: #5858B1;

      b {
        font-weight: 600;

        &:first-of-type {
          text-transform: uppercase;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .deleteStudentResultButton {
    @extend %transparent-button;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    padding: 10px 30px;
    font-size: 14px;
    min-width: 135px;
    margin: 10px 0;

    span {
      @extend %big-first-letter;
    }
  }
}

.deleteStudentResultModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
