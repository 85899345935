@import "../../../../../../variables.scss";

.listItem {
  display: flex;
  flex-direction: column;
  padding: 10px 25px;

  .content {
    display: flex;
    width: 100%;
    align-items: center;

    label {
      display: flex;
      position: relative;
      padding: 0;
      min-width: 30px;
      align-items: center;
      margin: 0;

      input {
        min-height: 18px;
        min-width: 20px;
        margin-right: 15px;
        position: initial;
        margin: 0;
      }
    }

    > div {
      flex: 1;
      font-size: 14px;
      color: $dark-grey;
    }
  }

  ul {
    margin: 5px 5px 0 5px;
  }
}
