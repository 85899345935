@import "../../../variables.scss";

.instruction {
  display: flex;
  flex-direction: column;
  padding: 17px;
  margin: 0 0 25px 0;
  border: 1px solid #5E5EB6;

  @media #{$medium-screen} {
    padding: calc(17px / 2);
    margin: 7.5px 0 12.5px 0;
  }

  @media #{$small-screen} {
    padding: calc(17px / 2.5);
    margin: 6px 0 10px 0;
  }

  @media #{$extra-small-screen} {
    padding: calc(17px / 2.8);
    margin: 5.35px 0 8.92px 0;
  }

  > h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #5E5EB6;
    margin: 0 0 10px 0;

    @media #{$medium-screen} {
      font-size: calc(18px / 2);
      margin: 0 0 5px 0;
    }
  
    @media #{$small-screen} {
      font-size: calc(18px / 2.5);
      margin: 0 0 4px 0;
    }
  
    @media #{$extra-small-screen} {
      font-size: calc(18px / 2.8);
      margin: 0 0 3.57px 0;
    }
  }

  > p {
    font-size: 12px;
    line-height: 24px;
    color: $dark-grey;

    @media #{$medium-screen} {
      font-size: calc(12px / 2);
      line-height: calc(24px / 2);
    }
  
    @media #{$small-screen} {
      font-size: calc(12px / 2.5);
      line-height: calc(24px / 2.5);
    }
  
    @media #{$extra-small-screen} {
      font-size: calc(12px / 2.8);
      line-height: calc(24px / 2.8);
    }

    span {
      vertical-align: middle;
    }

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: black;
      margin: 0 5px;
      vertical-align: middle;

      @media #{$medium-screen} {
        width: calc(14px / 2);
        height: calc(14px / 2);
        margin: 0 2.5px;
      }
    
      @media #{$small-screen} {
        width: calc(14px / 2.5);
        height: calc(14px / 2.5);
        margin: 0 2px;
      }
    
      @media #{$extra-small-screen} {
        width: calc(14px / 2.8);
        height: calc(14px / 2.8);
        margin: 0 1.78px;
      }
    }

    .circle {
      display: inline-block;
      padding: 0px;
      border: 1px solid black;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      margin: 0 5px;
      position: relative;

      @media #{$medium-screen} {
        width: calc(30px / 2);
        height: calc(30px / 2);
        margin: 0 2.5px;
      }
    
      @media #{$small-screen} {
        width: calc(30px / 2.5);
        height: calc(30px / 2.5);
        margin: 0 2px;
      }
    
      @media #{$extra-small-screen} {
        width: calc(30px / 2.8);
        height: calc(30px / 2.8);
        margin: 0 1.78px;
      }

      > i {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        right: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
