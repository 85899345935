@import "../../../../../../variables.scss";

.searchContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 3;
}

.headerContainer {
  margin: 40px 0 35px 0;

  > .header {
    @extend %big-first-letter;

    color: $violet;
    font-size: 18px;
    font-weight: 700;
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: calc(100% - 100px);

  @media #{$small-screen} {
    flex-direction: column;
    max-width: calc(100% - 50px);
  }

  .searchButtonContainer {
    @media #{$small-screen} {
      width: 100%;
    }

    .searchButton {
      @extend %violet-gradient-button;

      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 10px;
      margin: 0 0 6px 30px;
      padding: 12px 60px;
      color: white;
      height: 100%;
      min-width: 160px;
      min-height: 40px;

      @media #{$small-screen} {
        width: 100%;
        margin: 0;
      }

      &:disabled {
        @extend %disabled-button;
      }

      span, svg {
        @extend %big-first-letter;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

.searchOptionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px 0 0 0;
  width: calc(100% - 100px);

  @media #{$small-screen} {
    width: calc(100% - 50px);
  }

  .label {
    display: flex;
    margin-right: 10px;
    align-items: center;
    font-size: 14px;
    color: $medium-grey;
    text-transform: uppercase;

    @media #{$small-screen} {
      font-size: 12px;
      margin: 0 0 4px 4px;
    }
  }

  .questionsTypeContainer, .curriculumContainer {
    display: flex;
    align-self: center;
    margin: 8px 40px 8px 0;

    @media #{$small-screen} {
      flex-direction: column;
    }

    > ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      > li {
        @extend %big-first-letter;

        @media #{$small-screen} {
          margin: 0 0 5px 0;
        }

        label {
          margin: 0;

          span {
            font-size: 14px;
            color: $dark-grey;
            margin: 0 8px; 

            @media #{$small-screen} {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .sortContainer {
    display: flex;
    align-self: center;
    position: relative;
    margin: 8px 0 8px auto;

    @media #{$small-screen} {
      flex-direction: column;
      margin-left: 0;
    }

    &:nth-child(1) {
      width: 100%;
      min-width: 260px;
      max-width: 360px;

      @media #{$small-screen} {
        min-width: 100%;
      }
    }

    input {
      cursor: pointer;
      min-width: 200px;
      border: 1px solid #DADADA;
      border-radius: 20px;
      padding: 10px 35px 10px 15px;

      @media #{$small-screen} {
        font-size: 12px;
      }
    }

    > div {
      display: flex;
      width: 100%;

      .wrapper {
        width: 100%;
        position: relative;

        > div {
          display: flex;
          width: 100%;
          margin: 0;
          position: relative;

          > div {
            margin: 0;
          }
        }

        .dropdown {
          position: absolute;
          width: calc(100% - 30px);
          background: #FCFCFC;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          padding: 10px 15px;
          margin: 0 15px;
          z-index: 20;
          max-height: 300px;
          overflow: auto;

          ul {
            width: 100%;
            list-style: none;

            li {
              margin: 5px 0;

              button {
                @extend %big-first-letter;
                display: flex;
                align-items: center;
                width: 100%;
                background: transparent;
                border: none;
                font-size: 12px;
                color: $dark-grey;
                text-align: left;
                padding: 10px 0;

                > svg {
                  width: 16px;
                  height: 16px;
                  margin: 0 10px 0 0;

                  path {
                    fill: $dark-grey;
                  }
                }

                > span {
                  @extend %big-first-letter;
                }
              }
            }
          }
        }
      }
    }
  }
}

hr.divider {
  margin: 25px 0 10px 0;
  width: calc(100% - 50px);
  border: none;
  border-top: 2px solid $whitey-grey;
}
