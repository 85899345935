@import "../../../../../../../variables.scss";

.creditCardDisplayContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 40px 0 0 0;

  h3 {
    margin: 0 0 20px 0;
  }

  .creditCardDisplayContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .card {
      display: flex;
      width: 220px;
      height: 137px;
      position: relative;
      > img {
        height: 100%;
      }
  
      span {
        position: absolute;
        left: 25px;
        bottom: 25px;
        color: white;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0 20px 0;

    button {
      display: flex;
      padding: 10px 20px;
      margin: 10px 0;
      min-width: 120px;
      align-items: center;
      justify-content: center;

      span {
        @extend %big-first-letter;
        font-size: 12px;
      }

      &:first-of-type {
        @extend %transparent-button;

        &:disabled {
          @extend %disabled-button;
          border: none;
        }
      }

      &:nth-of-type(2) {
        
        @extend %violet-gradient-button;
      }
    }
  }
}

.removeCardModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 200px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
