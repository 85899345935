@import "../../../variables.scss";

.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: $dark-grey;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.disabledContainer {
  opacity: 0.5;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $light-grey;
}

.checkbox {
  .checkmark {
    border-radius: 6px;
  }
}

.radio {
  .checkmark {
    border-radius: 20px;
  }
}

.noIcon {
  .checkmark {
    svg {
      display: none;
    }
  }
}

.noIconChecked {
  .checkmark {
    &:after {
      content: "";
      background: #5B5BB4;
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
}

.noIconChecked {
  .checkmark {
    &:after {
      border-radius: 2px;
    }
  }
}

.radio.noIconChecked {
  .checkmark {
    &:after {
      border-radius: 100%;
    }
  }
}

.container > a {
  color: $dark-grey;
}
