@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1068px;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }
  }

  .boxesContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -7px 30px -7px;

    .referralLinkBox, .referralInfoBox {
      border-radius: 8px;
      min-width: 520px;
      min-height: 160px;
      margin: 7px;
      padding: 30px;

      @media #{$small-screen} {
        min-width: 100%;
      }
    }

    .referralLinkBox {
      background: white;
      
      > span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.7;
        text-transform: uppercase;
        color: $medium-grey;
      }

      .inputContainer {
        width: 100%;
        margin: 30px 0 0 0;
        position: relative;

        input {
          border: 1px solid #5858B1;
          color: #5858B1;
          border-radius: 8px;
          font-size: 14px;
          padding: 12px 120px 12px 20px;
          width: 100%;
          margin: 0;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          top: 0;
          min-width: 115px;
          background: #5858B1;
          border-radius: 8px;
          border: none;
          z-index: 1;
          height: 100%;
          font-size: 14px;
          color: white;
          margin: 0;

          span {
            @extend %big-first-letter;
          }
        }
      }
    }

    .referralInfoBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background: #A194EF;
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        height: 100%;
        z-index: 0;
      }

      > span {
        z-index: 1;
        font-size: 48px;
        color: white;
        margin: 0 0 14px 0;
      }

      > p {
        @extend %big-first-letter;
        z-index: 1;
        font-size: 14px;
        color: white;
        max-width: 210px;
      }
    }
  }

  > h4 {
    margin: 15px 0;
    font-size: 14px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: #5858B1;
    width: 100%;
  }
}
