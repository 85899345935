@import "../../../variables.scss";

.container {
  position: fixed;
  bottom: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  min-width: 250px;
  min-height: 40px;
  border-radius: 8px;
  z-index: 3;
  padding: 10px 20px;

  @media #{$medium-screen} {
    bottom: 110px;
  }

  svg {
    margin: 0 20px 0 0;
  }

  span {
    @extend %big-first-letter;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
  }
}

.success {
  background: #bae3b9;

  span {
    color: #03AD00;
  }
}

.error {
  background: $red;

  span {
    color: white;
  }

  svg {
    height: 20px;

    path {
      fill: white;
      stroke: white;
    }
  }
}

.info {
  background: #A194EF;
  align-self: flex-start;
  margin: 0 0 0 20px;
  min-height: 50px;
  min-width: 350px;
  max-width: 450px;

  @media #{$small-screen} {
    min-width: 200px;
    max-width: calc(100vw - 40px);
  }

  span {
    color: white;
    text-align: left;
  }

  svg {
    width: 40px;

    path {
      fill: white;
      stroke: white;
    }
  }
}
