@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;

  h1 {
    display: flex;
    align-self: flex-start;
    font-weight: 300;
    font-size: 36px;
    color: $violet;
    margin: 0 20px 20px 0;

    span {
      @extend %big-first-letter;
    }

    @media #{$small-screen} {
      font-size: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 30px;
    box-shadow: $box-shadow;
    border-radius: 8px;
    max-width: 800px;
    width: calc(100% - 100px);
    position: relative;
    z-index: 2;
    margin: 0 0 20px 0;
  
    @media #{$small-screen} {
      width: 100%;
    }

    .box {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 10px 0 20px 0;

      h2 {
        @extend %big-first-letter;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1.7px;
        color: #BEBEBE;
        margin: 5px 0 18px 0;

        span {
          color: #5B5BB4;
        }
      }

      .error {
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 12px;
        color: $red;
      }
    }
  }

  .buttonContainer {
    display: flex;
    max-width: 800px;
    width: calc(100% - 100px);
    justify-content: flex-end;

    .submitButton {
      @extend %violet-gradient-button;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 30px;
      color: white;
      height: 100%;
  
      span {
        @extend %big-first-letter;
        font-size: 14px;
        text-align: center;
      }

      &:disabled {
        @extend %disabled-button;
      }
    }
  }
}
