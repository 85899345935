@import "../../../../../variables.scss";
@import "../styles.module.scss";

.sidebarRight {
  @extend .sidebar;
  right: 0;
  bottom: 100px;
  align-items: flex-end;

  @media #{$small-screen} {
    position: absolute;
    top: 96px;
    bottom: auto;
  }

  > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;

    &:disabled {
      @extend %disabled-button;

      span {
        color: white;
      }

      svg {
        font-size: 20px;
        path {
          fill: white;
        }
      }
    }

    &:first-of-type {
      bottom: 242px;

      @media #{$medium-screen} {
        bottom: 292px;
      }
    }

    &:nth-of-type(2) {
      bottom: 146px;

      @media #{$medium-screen} {
        bottom: 196px;
      }
    }

    &:nth-of-type(3) {
      bottom: 50px;

      @media #{$medium-screen} {
        bottom: 100px;
      }
    }

    > span {
      @extend %big-first-letter;
      font-size: 10px;
      margin: 8px 0 0 0;
      color: $dark-grey;
    }

    &:hover {
      > span {
        color: white;
      }
    }
  }
}

.deleteQuestionModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
