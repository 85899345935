@import "../../../../../../../variables.scss";

.container {
  display:  flex;
  width: 100%;
  flex-direction: column;

  .error {
    font-size: 12px;
    color: $red;
    margin: 10px 0 0 0;
  }
}
