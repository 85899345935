@import "../../../../../variables.scss";

.container {
  position: fixed;
  top: 0;
  height: 65px;
  width: 100%;
 
  @media #{$medium-screen} {
    height: auto;
  }
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 30px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.07);
}

.logoLink {
  height: auto;
  max-height: 65px;

  .logo {
    display: flex;
    height: auto;
    width: auto;
    max-width: 90px;
    padding: 5px 0;
    align-self: flex-start;
  
    @media #{$medium-screen} {
      display: flex;
    }
  }
}

.buttonContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 167px;

  > button {
    @extend %violet-gradient-button;
    display: flex;
    padding: 10px 30px;
    margin: 0 0 0 15px;
    min-width: 130px;
    font-size: 14px;
    justify-content: center;
  
    span {
      @extend %big-first-letter;
    }
  }
}

.modal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: calc(100% - 50px);
  }
}

.sendAnswersModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    @extend %big-first-letter;
    font-size: 20px;
    color: $violet;
    margin: 10px 0;
  }

  > .description {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 300px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  > .studentCode {
    display: flex;
    margin: 20px 10px 20px 10px;
    align-items: flex-end;

    > span {
      @extend %big-first-letter;
      font-weight: 600;
      font-size: 15px;
      color: #5858B1;
      margin: 0 5px 0 0;
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0 0 0;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 20px;
      margin: 0 5px 0 0;
      min-width: 120px;

      @media #{$small-screen} {
        min-width: 100px;
      }

      &:disabled {
        @extend %disabled-button;
      }

      &:nth-last-of-type(1) {
        @extend %violet-gradient-button;
        margin: 0 0 0 5px;
      }
    }
  }
}
