@import "../../../variables.scss";

.customFinishModal {
  max-width: 555px;
  height: auto;
  max-height: 390px;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.finishModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    margin: 20px 0;
  }

  > p {
    @extend %big-first-letter;
    font-size: 14px;
    color: #545454;
    text-align: center;
    line-height: 20px;
  }

  .error {
    color: $red;
  }

  .downloadingOptions {
    display: flex;
    max-width: 430px;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;

    .downloadingOptionsBox {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0 0 0 10px;

      &:first-of-type {
        margin: 0 10px 0 0;
      }

      @media #{$small-screen} {
        margin: 0;

        &:first-of-type {
          margin: 0;
        }
      }

      > span {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5B5BB4;
        margin: 15px 0;
      }

      > label {
        display: flex;
        > span {
          @extend %big-first-letter;
        }
      }
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0 0 0;

    @media #{$small-screen} {
      flex-direction: column;
      justify-content: center;
    }

    a {
      @extend %transparent-button;
      @extend %big-first-letter;

      text-align: center;
      padding: 10px 30px;
      font-size: 14px;
      margin: 10px 5px 0 0;
      min-width: 150px;

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }
    }

    > button {
      @extend %violet-gradient-button;
      @extend %big-first-letter;

      justify-content: center;
      padding: 10px 30px;
      font-size: 14px;
      margin: 10px 0 0 5px;
      min-width: 150px;
  
      &:disabled {
        @extend %disabled-button;
      }

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }
    }
  }

  .buttonContainerOnline {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0 0 0;

    @media #{$small-screen} {
      flex-direction: column;
      justify-content: center;
    }

    a {
      @extend %violet-gradient-button;
      @extend %big-first-letter;

      text-align: center;
      padding: 10px 30px;
      font-size: 14px;
      margin: 10px 5px 0 0;
      min-width: 150px;

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }
    }
  }
}

.tooltipContainer {
  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    color: color;
    max-height: 55px;
    max-width: 300px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 8px;

    &::after {
      display: none;
    }

    p {
      @extend %big-first-letter;
    }
  }

  div {
    @extend %big-first-letter;
  }
}
