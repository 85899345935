@import "../../../../variables.scss";

@mixin box-violet {
  background: $violet;

  > span {
    color: white;
  }

  > svg {
    path {
      fill: white;
    }
  }
}

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;

  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;

    h3 {
      display: flex;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      a {
        text-decoration: none;
        color: $violet;
      }

      span {
        @extend %big-first-letter;
      }

      @media #{$small-screen} {
        font-size: 24px;
      }
    }
  }

  .placeholder {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .createTestContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      height: 100%;

      h3 {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: $medium-grey;
      }
    }
  }

  .createTestLink {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    width: 195px;
    height: 195px;
    margin: 20px 7px;
    background: transparent;
    border: 1px solid $border-grey;
    border-style: dashed;
    padding: 40px;
    border-radius: 12px;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    color: $dark-grey;

    span:nth-child(2) {
      @extend %big-first-letter;
    }
  }

  > a {
    @extend %back-to-homepage;
    text-align: center;
  }
}
