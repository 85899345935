@import "../../../../../../../../variables.scss";

.info {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  align-items: center;
  background: $snow-white;
  font-size: 16px;
  border-radius: 8px;
  margin: 10px 25px 0 70px;
  padding: 10px 10px;

  @media #{$medium-screen} {
    align-items: flex-start;
  }

  @media #{$small-screen} {
    flex-direction: column;
    margin: 10px 25px 0 25px;
    width: calc(100% - 50px);
  }

  @media #{$extra-small-screen} {
    flex-direction: column;
  }

  .infoDetail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2px 18px;

    @media #{$small-screen} {
      font-size: calc(16px / 1.3);
      padding: 4px 4px;
    }

    &:last-child {
      .name {
        margin: 2px 16px 2px 0;
      }
    }

    .name {
      color: #808080;
      font-size: 14px;
      margin: 2px 6px 2px 0;
    }

    .value {
      display: flex;
      margin: 2px 0;
      color: #545454;
      font-size: 14px;

      button {
        display: flex;
        border: none;
        background: transparent;
        align-items: center;
        justify-content: flex-start;
        margin: 0 15px 0 0;
        color: #545454;

        svg {
          height: 20px;
          width: 20px;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}
