@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    a {
      @extend %violet-gradient-button;
      padding: 10px 30px;
      display: flex;
      font-size: 14px;

      span {
        &:last-child {
          @extend %big-first-letter;
        }
      }
    }
  }

  .classes {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7px;

    @media #{$small-screen} {
      flex-direction: column;
    }

    > li {
      width: 160px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 7px;

      @media #{$small-screen} {
        height: 65px;
        width: 100%;
        border-radius: 14px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $violet;
        font-weight: 300;
        font-size: 24px;
        text-decoration: none;
        padding: 10px;

        @media #{$small-screen} {
          font-size: 16px;
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
