@import "../../../variables.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;

      .inputContainer {
        display: flex;
        position: relative;
        margin: 10px 40px 10px 0;
  
        @media #{$extra-small-screen} {
          margin: 10px 10px 10px 0;
        }
  
        &:nth-child(1) {
          width: 100%;
          min-width: 150px;
          max-width: 220px;
        }

        input {
          border: none;
        }

        label {
          font-size: 14px;
          font-style: italic;
        }

        > div {
          display: flex;
          width: 100%;

          .wrapper {
            width: 100%;
            position: relative;

            > div {
              display: flex;
              width: 100%;
              margin: 0;
              position: relative;

              > div {
                margin: 0;
              }
            }

            .dropdown {
              position: absolute;
              width: calc(100% - 30px);
              background: #FCFCFC;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
              border-radius: 8px;
              padding: 10px 15px;
              margin: 0 15px;
              z-index: 3;
              max-height: 300px;
              overflow: auto;

              ul {
                width: 100%;
                list-style: none;

                li {
                  margin: 5px 0;

                  button {
                    @extend %big-first-letter;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background: transparent;
                    border: none;
                    font-size: 12px;
                    color: $dark-grey;
                    text-align: left;
                    padding: 10px 0;

                    > svg {
                      width: 16px;
                      height: 16px;
                      margin: 0 10px 0 0;
  
                      path {
                        fill: $dark-grey;
                      }
                    }

                    > span {
                      @extend %big-first-letter;
                    }
                  }

                  span {
                    font-size: 12px;
                    color: $dark-grey;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }

      .searchButton {
        @extend %big-first-letter;
        background: $violet-gradient;
        position: relative;
        color: white;
        padding: 12px 35px;
        border: none;
        border-radius: 20px;
        margin: 10px 0;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        min-width: 140px;

        &:disabled {
          @extend %disabled-button;
        }
      }
    }
  }
}

.tests {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .allTestsList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    > div {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      width: 100%;

      > div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        > svg {
          path {
            fill: $violet;
          }
        }
      }
    }
  }
}