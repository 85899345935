@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 0 10px 0;

      span {
        font-weight: 300;
        font-size: 36px;
        color: $violet;
        border: none;
        max-width: 100%;
        min-width: 220px;
        margin: 0;
        padding: 0;

        @media #{$small-screen} {
          font-size: 24px;
          max-width: 200px;
          min-width: 200px;
        }
      }

      > button {
        margin: 0 10px 0 0;
        background: transparent;
        border: none;
        padding: 0;
      }

      > svg {
        display: flex;
        height: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        margin: 0 10px 8px 10px;

        @media #{$small-screen} {
          margin: 0 10px 5px 10px;
        }

        path {
          fill: $violet;
        }
      }

      .error {
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 12px;
        color: $red;
      }
    }

    > svg {
      margin: 0 10px 0 0;

      @media #{$small-screen} {
        height: 15px;
      }
    }

    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      button {
        @extend %violet-gradient-button;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 10px 20px 10px 0;
        min-width: 145px;
        display: flex;
        font-size: 14px;
        transition: all 0.5s ease;
  
        svg {
          margin: 0 5px 0 0;
          path {
            fill: white;
          }
        }
  
        &:hover {
          background-color: $violet;
  
          span {
            color: white;
          }
        }
  
        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }

        &:disabled {
          @extend %disabled-button;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    h4 {
      font-size: 24px;
      color: $dark-grey;
      font-weight: 300;
      margin: 10px 0;
      line-height: initial;
    
      @extend %big-first-letter;
    
      .primaryHeadingText {
        font-weight: 600;
        color: $violet;
      }
    }

    .educationLevelContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 10px 0;
    }
  }
}
