@import "../../../../variables.scss";

.curriculumBox {
  width: 100%;
  margin: 10px 0 60px 0;

  h2 {
    font-size: 32px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px;
    line-height: initial;
    text-align: center;

    @extend %big-first-letter;

    .primaryHeadingText {
      font-weight: 600;
      color: $violet;
    }

    .secondaryHeadingText {
      font-size: 18px;
    }
  }

  p {
    color: $grey;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 10px;
    text-align: center;
    line-height: initial;

    @extend %big-first-letter;
  }

  .educationLevelContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;

    > svg {
      color: $violet;
      font-size: 16px;
    }
  }

  .curriculumsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    > svg {
      color: $violet;
      font-size: 16px;
    }
  }

  .saveButton {
    @extend %big-first-letter;
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
    align-self: center;
    min-width: 140px;
  }
}
