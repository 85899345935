@import "../../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: baseline;
  height: 100%;
  width: 100%;
  padding: 65px 0 0 0;
  flex: 1;
  background: $whitey-grey;
  position: relative;

  .innerContainer {
    height: 100%;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 20px;

    .topContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 210mm;
      margin: 50px auto 0 auto;

      @media #{$small-screen} {
        width: calc(100% - 50px);
      }

      .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .textContainer {
          margin: 0 15px 0 0;

          &:nth-of-type(2) {
            margin: 0 0 0 15px;
          }

          > span {
            display: inline-block;
            @extend %big-first-letter;
          }
        }
        
        @media #{$small-screen} {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
    
          .textContainer {
            margin: 0 0 5px 10px;
          }
        }
      }

      .headerBottomWrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 15px 0;

        h1 {
          align-self: flex-start;
          font-weight: 600;
          font-size: 36px;
          color: #5353AC;
          margin: 10px 10px 10px 0;
        }
      }
    }

    .summaryBox {
      display: flex;
      width: 100%;
      max-width: 210mm;
      flex-wrap: wrap;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 15px;
      align-items: center;
      margin: 0 auto;
    
      @media #{$small-screen} {
        width: calc(100% - 50px);
      }
    
      > span {
        @extend %big-first-letter;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $grey;
      }
    
      > div {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: flex-end;
    
        @media #{$small-screen} {
          flex-direction: column;
          flex: auto;
          width: 100%;
          margin: 10px 0 0 0;
          justify-content: flex-start;
        }
    
        .textContainer {
          @extend %big-first-letter;
          margin: 0 15px 0 15px;
          font-size: 14px;
          color: #545454;
    
          @media #{$small-screen} {
            margin: 3px 0;
          }
        }
      }
    }
    
    .testContainer {
      display: flex;
      flex-direction: column;
      background: transparent;
      margin: 0 auto;
      // max-width: 210mm;
      width: 100%;
      padding: 10px 0 124px 0;
      border-radius: 8px;
    }
  }
}

.containerReadOnly {
  overflow: inherit;

  .innerContainer {
    .testContainer {
      max-width: calc(210mm + 20px);
      @media #{$small-screen} {
        width: 100%;
      }
    }
  }
}

.containerNotReadOnly {
  overflow: hidden;

  .innerContainer {
    .testContainer {
      max-width: 210mm;
      @media #{$small-screen} {
        width: calc(100% - 50px);
      }
    }
  }
}
