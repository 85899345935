@import "../../../../../../../variables.scss";

@keyframes changeColor {
  from { color: $violet; }
  to { color: white; }
}

@keyframes changeColorSvg {
  from { fill: $violet; }
  to { fill: white; }
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 195px;
  height: 195px;
  margin: 20px 15px 20px 0;
  border-radius: 12px;
  box-shadow: $box-shadow;
  padding: 15px 15px 20px 15px;
  position: relative;

  @media #{$small-screen} {
    justify-content: space-between;
    width: 132px;
    height: 132px;
    font-size: 18px;
    margin: 15px 15px 15px 0;
  }

  &:hover {
    background: $violet;

    > span {
      animation-name: changeColor;
      animation-duration: 1s;
      animation-iteration-count: 1;
      color: white;

      @media #{$small-screen} {
        animation: none;
      }
    }

    > svg {
      path {
        fill: white;
        animation-name: changeColorSvg;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
    }

    .seeMoreLink {
      position: initial;
      height: auto;
      width: auto;
      opacity: 1;
      visibility: visible;
    }

    .infoContainer {
      display: none;
    }
  }

  > svg {
    flex: 1;

    @media #{$small-screen} {
      display: none;
    }
  }

  .seeMoreLink {
    @extend %big-first-letter;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    text-decoration: none;
    font-size: 14px;
    padding: 8px 10px;
    align-self: baseline;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear, background-color 0.2s ease;

    @media #{$small-screen} {
      display: none;
    }

    &:hover {
      color: $violet;
      background: white;
    }
  }

  > span {
    font-size: 14px;
    color: $dark-grey;
    text-align: left;
    width: 100%;
    align-self: flex-start;

    @media #{$small-screen} {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 52px;
    }
  }

  .infoContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .newItem {
      font-weight: 800;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1px;
      color: $pink;
      text-shadow: 0px 4px 4px rgba(216, 125, 152, 0.64);
      text-transform: uppercase;
      margin: 0 0 15px 0;

      @media #{$small-screen} {
        margin: 0 0 5px 0;
      }
    }

    .numberOfQuestionsContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 12px;
        text-transform: uppercase;
        color: #B8B8B8;

        @media #{$small-screen} {
          font-size: 10px;
        }
      }

      .number {
        font-size: 14px;
        line-height: 17px;
        color: $dark-grey;

        @media #{$small-screen} {
          font-size: 12px;
        }
      }
    }
  }
}

.deleteQuestionModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
