@import "./variables.scss";

@font-face {
  font-family: "Lato";
  font-weight: 300;
  src:
    url("./assets/fonts/Lato/Lato-Light.eot") format("eot"),
    url("./assets/fonts/Lato/Lato-Light.ttf") format("ttf"),
    url("./assets/fonts/Lato/Lato-Light.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Light.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src:
    url("./assets/fonts/Lato/Lato-Medium.eot") format("eot"),
    url("./assets/fonts/Lato/Lato-Medium.ttf") format("ttf"),
    url("./assets/fonts/Lato/Lato-Medium.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Medium.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 600;
  src:
    url("./assets/fonts/Lato/Lato-Bold.eot") format("eot"),
    url("./assets/fonts/Lato/Lato-Bold.ttf") format("ttf"),
    url("./assets/fonts/Lato/Lato-Bold.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Bold.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src:
    url("./assets/fonts/Lato/Lato-Heavy.eot") format("eot"),
    url("./assets/fonts/Lato/Lato-Heavy.ttf") format("ttf"),
    url("./assets/fonts/Lato/Lato-Heavy.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Heavy.woff") format("woff");
}

body {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
html {
	line-height: 1;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}
q, blockquote {
	quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
	content: '';
	content: none;
}
a img {
	border: none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}

* {
  box-sizing: border-box;
  outline: none;
  -ms-content-zooming: none;
  -ms-scroll-chaining: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
}

input, textarea {
  transform: translateZ(0);
}

button, input {
  outline: none;
  font-family: Lato;
}

button {
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: $violet;
}

#root {
  display: flex;
  flex: 1;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  background-clip: padding-box;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
}

-ms-scrollbar-track-color {
  background: #e0e0e0;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.stripeInput {
  background: transparent;
  border-radius: 50px;
  border: 1px solid $violet;
  padding: 10px 20px;
  color: $violet;
}

.reactQuill {
  > .ql-toolbar {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-color: #DADADA;

    > .ql-formats {
      > button {
        &:hover {
          > svg {
            .ql-stroke {
              stroke: #5a5bb4;
            }
            .ql-fill {
              fill: #5a5bb4;
            }
          }
        }
      }

      > .ql-active {
        svg {
          .ql-stroke {
            stroke: #5a5bb4;
          }
          .ql-fill {
            fill: #5a5bb4;
          }
        }
      }
    }
  }

  > .ql-container {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-color: #DADADA;
    font-family: "Lato";
    font-size: 16px;
    font-weight: 300;
    color: #4A4A4A;

    strong {
      font-weight: 600;
    }
  }
}

.reactQuillQuestionAnswer {
  margin: 5px 0 10px 0px;

  > .ql-toolbar {
    display: none;
  }

  > .ql-container {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top: 1px solid #DADADA !important;

    .ql-editor {
      padding: 10px 15px;
    }
  }
}

.reactQuillQuestionAnswerFocused {
  > .ql-toolbar {
    display: inherit;
  }

  > .ql-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    border-top: 0px !important;
  }
}

.reactQuillError {
  > .ql-toolbar {
    border-color: $red;
  }
  > .ql-container {
    border-color: $red !important;
  }
}

.mq-math-mode {
  border: 1px solid #DADADA !important;
  padding: 10px;
  border-radius: 12px;
}

.mq-focused {
  border: 1px solid #DADADA !important;
  box-shadow: none !important;
  border-radius: 12px !important;
}

.questionBodyGlobal, .lessonHTMLGlobal {
  ol, ul {
    margin-left: 1.5em;

    li {
      margin-left: 1.5em;
    }
  }

  input {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $violet;
    border: none;
    border-bottom: 1px solid $violet;

    &:disabled {
      background: transparent;
    }
  }
}
