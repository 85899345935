@import "../../variables.scss";

.app {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.view {
  flex: 1;
  height: 100%;
  background: $blue-grey;
  max-width: calc(100vw - 230px);
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @media #{$medium-screen} {
    max-width: 100vw;
  }

  .innerView {
    display: flex;
    flex: 1;
    min-height: 100%;
    padding: 30px;

    @media #{$extra-small-screen} {
      padding: 20px;
    }
  }
}
