@import "../../../../../../variables.scss";


.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 297mm;
  margin: 0 0 20mm 0;

  @media #{$medium-screen} {
    min-height: calc((297mm / 2));
    margin: 0 0 10mm 0
  }

  @media #{$small-screen} {
    min-height: calc((297mm / 2.5));
    margin: 0 0 8mm 0;
  }

  @media #{$extra-small-screen} {
    min-height: calc((297mm / 2.8));
    margin: 0 0 7.14mm 0;
  }
}

.a4 {
  position: absolute;
  top: 0;
  height: 297mm;
  width: 210mm;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;
  box-sizing: content-box;

  @media #{$medium-screen} {
    height: calc(297mm / 2);
    width: calc(210mm / 2);
    padding: calc(10px / 2);
  }

  @media #{$small-screen} {
    height: calc(297mm / 2.5);
    width: calc(210mm / 2.5);
    padding: calc(10px / 2.5);
  }

  @media #{$extra-small-screen} {
    height: calc(297mm / 2.8);
    width: calc(210mm / 2.8);
    padding: calc(10px / 2.8);
  }
}

.noteContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  
  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;
  }
}

.noteContainerFirst {
  margin: 10px auto 0 auto;

  > svg {
    margin: 0;
  }

  @media #{$medium-screen} {
    margin: 5px auto 0 auto;
  }

  @media #{$small-screen} {
    margin: 4px auto 0 auto;
  }

  @media #{$extra-small-screen} {
    margin: 3.57px auto 0 auto;
  }
}

.noteContainerLast {
  margin: 0 auto 10px auto;

  @media #{$medium-screen} {
    margin: 0 auto 5px auto;
  }

  @media #{$small-screen} {
    margin: 0 auto 4px auto;
  }

  @media #{$extra-small-screen} {
    margin: 0 auto 3.57px auto;
  }
}

.noteContainerSingle {
  margin: 10px auto;

  @media #{$medium-screen} {
    margin: 5px auto;
  }

  @media #{$small-screen} {
    margin: 4px auto;
  }

  @media #{$extra-small-screen} {
    margin: 3.57px auto;
  }
}

.noteContainerBlank {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  border-left: 1px solid #AFAFAF;
  border-right: 1px solid #AFAFAF;
  
  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;
    opacity: 0;
  }
}

.noteContainerBlankFirst {
  margin: 10px auto 0 auto;
  border-top: 1px solid #AFAFAF;

  > svg {
    margin: 0;
  }

  @media #{$medium-screen} {
    margin: 5px auto 0 auto;
  }

  @media #{$small-screen} {
    margin: 4px auto 0 auto;
  }

  @media #{$extra-small-screen} {
    margin: 3.57px auto 0 auto;
  }
}

.noteContainerBlankLast {
  margin: 0 auto 10px auto;
  border-bottom: 1px solid #AFAFAF;

  @media #{$medium-screen} {
    margin: 0 auto 5px auto;
  }

  @media #{$small-screen} {
    margin: 0 auto 4px auto;
  }

  @media #{$extra-small-screen} {
    margin: 0 auto 3.57px auto;
  }
}

.noteContainerBlankSingle {
  margin: 10px auto;
  border-top: 1px solid #AFAFAF;
  border-bottom: 1px solid #AFAFAF;

  @media #{$medium-screen} {
    margin: 5px auto;
  }

  @media #{$small-screen} {
    margin: 4px auto;
  }

  @media #{$extra-small-screen} {
    margin: 3.57px auto;
  }
}
