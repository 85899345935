@import "../../../../../variables.scss";

.boxUser {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  background: white;
  width: calc(50% - 8px);
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  padding: 23px;
  max-width: 1000px;

  @media #{$medium-screen} {
    margin: 0 0 20px 0;
    width: 100%;
    max-width: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $medium-grey;
  }

  .avatar {
    margin: 40px 0 30px 0;

    svg {
      width: 120px;
      height: 120px;
    }
  }

  .deleteAccountButton {
    display: flex;
    border: none;
    align-self: flex-start;
    margin: 10px 0 0 0;
    background: transparent;
    align-items: center;

    span {
      @extend %big-first-letter;
      color: $medium-grey;
      font-size: 14px;
    }

    svg {
      margin: 0 2px 0 0;
      path {
        fill: $medium-grey;
      }
    }
  }
}

.deleteAccountModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}

.saveChangesButton {
  @extend %violet-gradient-button;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 20px;
  padding: 10px;
  min-width: 150px;
  margin: 20px 0;

  &:disabled {
    @extend %disabled-button;
  }

  span {
    @extend %big-first-letter;
  }
}