@import "../../../../../../../../variables.scss";

.invoice {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 50%;

  span {
    @extend %big-first-letter;
    font-size: 14px;
    color: $violet;
  }

  button {
    display: flex;
    background: transparent;
    border: none;
    padding: 0;
    align-self: flex-start;
    margin: 20px 0;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  > ul {
    list-style: none;
    margin: 8px 0 0 0;
    
    li {
      font-size: 14px;
      line-height: 17px;
      color: #545454;
    }
  }
}

.modal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}
