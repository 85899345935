@import "../../../../variables.scss";

.referralBox {
  display: flex;
  flex-direction: column;
  background: #A194EF;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: 22px;
  min-width: 200px;
  width: 202px;
  width: 200px;
  margin: 10px;
  height: 420px;
  position: relative;
  overflow: hidden;

  @media #{$small-screen} {
    width: 100%;
    min-width: calc(100% - 20px);
    min-height: 200px;
    height: auto;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: white;
    margin: 15px 0;
  }

  p {
    @extend %big-first-letter;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 0 0 15px 0;
    flex: 1;
  }

  > a {
    @extend %transparent-button;
    @extend %big-first-letter;
    color: white;
    background: #A194EF;
    font-size: 14px;
    padding: 8px 20px;
    margin: 10px 0;
    align-self: flex-start;
    text-align: center;
    transition: background-color 0.5s ease;
    border-color: white;

    &:hover {
      color: #A194EF;
      background: white;
    }
  }
}
