@import "../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  margin: 0 10px;
  min-width: 500px;

  @media #{$medium-screen} {
    width: 100%;
    min-width: 260px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;

    > h4 {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: $violet;
      margin: 10px 10px 10px 0;
      letter-spacing: 1.7px;
    }

    > span {
      @extend %big-first-letter;
      font-size: 12px;
      line-height: 10px;
      color: #878787;
      margin: 10px 0;
    }
  }

  .questions {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 -7px;

    .headingItem, .listItem {
      > .questionNumber {
        width: 20%;
        display: flex;
        justify-content: flex-start;

        @media #{$small-screen} {
          width: 16%;
        }
      }

      > .scorePercentagePoints {
        width: 35%;
        display: flex;
        justify-content: center;
        font-size: 12px;

        @media #{$small-screen} {
          width: 37%;
        }
      }

      > .actions {
        width: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
          border: none;
          background: transparent;
          padding: 5px;
          margin: 0 5px;

          svg {
            cursor: pointer;
            path {
              fill: #B8B8B8;
            }
          }

          &:hover {
            svg path {
              fill: $violet;
            }
          }
        }

        .toggleIcon {
          display: none;

          @media #{$small-screen} {
            display: flex;
          }

          svg, svg path {
            transition: all 0.5s ease;
          }

          &.active {
            svg {
              transform: rotate(180deg);
              path {
                fill: $violet;
                stroke: $violet;
              }
            }
          }
        }

        .tooltipContainer {
          .tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            color: color;
            height: 25px;
            max-width: 300px;
            background: $pink;
            padding: 10px 20px;
            font-size: 12px;
            border-radius: 8px;
        
            &::after {
              display: none;
            }
        
            p {
              @extend %big-first-letter;
            }
          }
        
          i {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .headingItem {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 2px 8px;
      padding: 10px;

      div {
        height: 30px;
        align-items: center;
        > span {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 1px;
          font-weight: 600;
          text-transform: uppercase;
          color: $medium-grey;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .listItem {
      width: 100%;
      height: 45px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 8px;
      padding: 10px;

      @media #{$small-screen} {
        flex-wrap: wrap;
        height: auto;
      }

      div {
        display: flex;
        height: 100%;
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding: 5px 20px;
          font-weight: 400;
        }
      }

      .scorePoints {
        span {
          color: #545454;
        }
      }

      .scorePercentagePoints {
        > i {
          display: flex;
          align-items: center;
          background-color: #E6E6F7;
          border-radius: 16px;
          width: 100%;
          max-width: 120px;
          height: 24px;
          margin: 0 10px;
          position: relative;
          overflow: hidden;

          @media #{$small-screen} {
            margin: 0 10px 0 0;
          }

          > .percentageBar {
            background: $violet;
            height: 100%;
            z-index: 1;
            border-radius: 16px;
          }

          > .percentagePoints {
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            position: relative;
            z-index: 2;
          }
        }

        &.valueLeft {
          span {
            position: absolute;
            left: 15px;
            color: white;
          }
        }
  
        &.valueCenter {
          span {
            display: flex;
            justify-content: center;
            color: white;
            width: 100%;
            position: absolute;
          }
        }

        &.valueRight {
          span {
            position: absolute;
            right: 15px;
            color: $violet;
          }
        }
      }

      .questionNumber {
        .number {
          border-radius: 4px;
          color: #545454;
          letter-spacing: 1px;
          font-size: 12px;
        }
      }
    }
  }
}
