@import "../../../../../../../variables.scss";

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 6px 0;
  margin: 0 0 20px 0;
}
