@import "../../../../../../variables.scss";

.container {
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
  border-radius: 12px;
  flex: 1;
  min-width: 260px;
  height: 100px;
  margin: 10px;
  overflow: hidden;

  @media #{$small-screen} {
    width: calc(100% - 20px);
    height: 150px;
  }

  > svg {
    position: relative;
    left: -12px;
    min-width: 80px;
    margin: 0 20px 0 0;
  }
  
  > .textContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;

    > h4 {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: $medium-grey;
      width: 100px;

      @media #{$medium-screen} {
        width: auto;
      }
    }
  
    > h5 {
      font-weight: 600;
      font-size: 36px;
      color: #5858B1;
      margin: 0 10px;
    }
  }
}
