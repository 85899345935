@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 16px;
      color: $medium-grey;
      margin: 0 20px 15px 0;
      width: 100%;

      > a {
        text-decoration: none;
        color: $medium-grey;
      }

      > span {
        font-weight: 600;
      }

      @media #{$small-screen} {
        font-size: 14px;
      }
    }

    button {
      @extend %violet-gradient-button;
      padding: 10px 30px;
      display: flex;
      font-size: 14px;
      min-width: 135px;
      align-items: center;
      justify-content: center;

      span {
        &:last-child {
          @extend %big-first-letter;
        }
      }

      &:disabled {
        @extend %disabled-button;
      }
    }

    .tooltipContainer {
      .tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: color;
        max-height: 55px;
        max-width: 300px;
        background: $pink;
        padding: 10px 20px;
        font-size: 12px;
        border-radius: 8px;

        @media #{$small-screen} {
          max-width: 160px;
          max-height: 155px;
        }
    
        &::after {
          display: none;
        }
    
        p {
          @extend %big-first-letter;
        }
      }
    
      div {
        @extend %big-first-letter;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .statsBoxesContainer {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
