@import "../../../../../variables.scss";

.discountCodesBox {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  background: transparent;
  width: 100%;
  padding: 23px 0;
  margin: 15px 0;

  @media #{$medium-screen} {
    margin: 0 0 20px 0;
    max-width: 100%;
  }

  &.discountCodesBoxEmpty {
    background: white;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
    border-radius: 8px;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $violet;
    padding: 0 23px;
    margin: 0 0 25px 0;
  }

  .discountInfoDesc {
    background: $main-color;
    border-radius: 8px;
    padding: 15px;
    margin: 0 0 25px 0;

    > span {
      font-size: 14px;
      color: $violet;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0;

    .placeholderContent {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 23px;

      .placeholderText {
        flex: 1;
        margin: 0 35px;

        > p {
          font-size: 14px;
          color: $medium-grey;
        }

        > a {
          display: flex;
          text-decoration: none;
          font-size: 14px;
          margin: 15px 0 0 0;
          color: $violet;

          span {
            @extend %big-first-letter;
          }
        }
      }
    }

    .discountCodes {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      margin: 0;

      .headingItem, .listItem {
        > .code {
          width: 30%;
          display: flex;
          justify-content: flex-start;

          @media #{$small-screen} {
            width: 45%;
          }
        }

        > .discountNumber {
          width: 30%;
          display: flex;
          justify-content: center;

          @media #{$small-screen} {
            display: none;
          }
        }

        > .activation {
          width: 25%;
          display: flex;
          justify-content: center;

          @media #{$small-screen} {
            width: 45%;
          }
        }

        > .lock {
          width: 5%;
          display: flex;
          justify-content: left;

          @media #{$small-screen} {
            display: none;
          }
        }

        > .actions {
          width: 10%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media #{$medium-screen} {
            > span {
              display: none;
            }
          }

          @media #{$small-screen} {
            > div {
              display: none;
            }
          }

          button {
            border: none;
            background: transparent;
            padding: 5px;
            margin: 0 5px;

            svg {
              cursor: pointer;
              path {
                fill: #B8B8B8;
              }
            }

            &:hover {
              svg path {
                fill: $violet;
              }
            }
          }

          i {
            svg {
              cursor: pointer;

              path {
                transition: all 0.5s ease;
                fill: #B8B8B8;
              }

              &:hover {
                path {
                  fill: $violet;
                }
              }
            }
          }

          .toggleIcon {
            display: none;

            @media #{$small-screen} {
              display: flex;
            }

            svg, svg path {
              transition: all 0.5s ease;
            }

            svg path {
              fill: $violet;
              stroke: $violet;
            }

            &.active {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .headingItem {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background: transparent;
        margin: 2px 0;
        padding: 10px;

        @media #{$small-screen} {
          display: none;
        }

        div {
          height: 30px;
          align-items: center;
          span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            color: $medium-grey;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .listItem {
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        margin: 5px 0;
        padding: 7px 10px;

        &:nth-child(odd) {
          background: transparent;
          box-shadow: none;
        }

        @media #{$small-screen} {
          flex-wrap: wrap;
          height: auto;
        }

        > div {
          display: flex;
          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 5px 20px;
            font-weight: 400;
          }
        }

        .discountNumber {
          span {
            font-weight: 600;
            color: #545454;
          }

          @media #{$small-screen} {
            display: none;
          }
        }

        .code {
          > span {
            background: $main-color;
            border-radius: 4px;
            color: $violet;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;
            width: 130px;
          }
        }

        .activation {
          > button {
            @extend %transparent-button;
            @extend %big-first-letter;
            padding: 5px;
            width: 100%;
            max-width: 100px;
            transition: all 0.5s ease;

            &:hover {
              background: $violet;
              color: white;
            }

            &:disabled {
              pointer-events: none;
              color: #a9a9a9;
              background: #E1E1E1;
              border: 1px solid #E1E1E1;
            }
          }
        }

        .lockIcon {
          svg {
            height: 20px;
          }
        }

        .dropDownContainer {
          display: none;
          width: 100%;
          margin: 15px 0 5px 0;

          @media #{$small-screen} {
            display: flex;
            align-items: center;
          }

          > span {
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            margin: 0 15px 0 0;

            &:first-of-type {
              color: $medium-grey;
            }

            &:nth-of-type(2) {
              color: #545454;
            }
          }

          svg path {
            fill: #B8B8B8;
          }
        }
      }
    }
  }
}

.tooltipContainer {
  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    color: color;
    height: 25px;
    max-width: 300px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 8px;

    @media #{$small-screen} {
      max-width: 150px;
      height: 55px;
    }

    &::after {
      display: none;
    }

    p {
      @extend %big-first-letter;
    }
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
