@import "../../../../../../../../variables.scss";

.container {
  width: 100%;
}

.listItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px 70px;

  @media #{$medium-screen} {
    padding: 1px 35px;
  }

  @media #{$small-screen} {
    padding: 0.8px 28px;
  }

  @media #{$extra-small-screen} {
    padding: 0.7px 25px;
  }

  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    width: 100%;
    cursor: default;
    max-width: 100%;

    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0 0 15px 0;
      max-width: 100%;

      @media #{$medium-screen} {
        padding: 0 0 10px 0;
      }

      @media #{$small-screen} {
        padding: 0 0 8px 0;
      }

      @media #{$extra-small-screen} {
        padding: 0 0 7.15px 0;
      }

      .answerName {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $violet;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    input[type="radio"] {
      min-width: 19px;
      min-height: 19px;
      width: 19px;
      height: 19px;
      border: 1px solid $light-grey;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 0;
    }

    .inputContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px 0 0;

      > span {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        color: $light-grey;
        text-align: center;
        height: 100%;
        width: 100%;

        &.multipleChoiceAnswerCorrect {
          color: white;
        }
      }
    }

    input[type="checkbox"] {
      min-width: 19px;
      min-height: 19px;
      width: 19px;
      height: 19px;
      border: 1px solid $light-grey;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 0;
    }

    input:checked {
      background: $violet;
      border: 1px solid $violet;
    }
  }
}
