@import "../../../../../../../variables.scss";

.addQuestion {
  display: flex;
  @extend %violet-gradient-button;
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  padding: 14px 25px;
  z-index: 2;

  span:nth-child(2) {
    @extend %big-first-letter;
  }
}
