@import "../../../../../../variables.scss";

@mixin box-violet {
  background: $violet;

  > svg {
    path {
      fill: white;
      stroke: transparent;
    }
  }

  > .listItemInnerWrapper {
    span {
      color: white;
    }

    .radio {
      border: 1px solid white;
      i {
        background: white;
      }
    }
  }
}

.paymentMethodsContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;

  .paymentMethods {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0 0 0;
    justify-content: space-between;

    > li {
      margin: 10px 0;

      &:first-of-type {
        margin: 10px 20px 10px 0;
      }
    }

    li {
      display: flex;
      list-style: none;
      border: 2px solid $violet;
      border-radius: 14px;
      width: 240px;
      min-width: 240px;
      justify-content: space-around;
      height: 80px;
      align-items: center;
      cursor: pointer;

      &.active {
        @include box-violet;
      }

      &.disabled {
        pointer-events: none;
        border: 2px solid #c6c4ee;
        cursor: auto;

        > div {
          .radio {
            border: 1px solid #c6c4ee;
          }

          span {
            color: #c6c4ee;
          }
        }

        > svg path {
          fill: #c6c4ee;
        }
      }

      > .listItemInnerWrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        span {
          @extend %big-first-letter;
          font-size: 16px;
          color: $dark-grey;
        }
  
        .radio {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 16px;
          width: 16px;
          border: 1px solid $violet;
          border-radius: 100%;
          margin: 0 5px 0 0;
        
          i {
            background: $violet-dark;
            border-radius: 100%;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  > button {
    @extend %violet-gradient-button;
    @extend %big-first-letter;
    width: 180px;
    padding: 10px 15px;
    margin: 30px 0;
    align-self: flex-end;

    &:disabled {
      @extend %disabled-button;
    }

    span {
      font-size: 12px;
    }
  }
}

.modal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 80%;
  }

  @media #{$extra-small-screen} {
    max-width: 90%;
  }
}

.lockIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  li div svg {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: calc(50% - 15px);
  }

  .tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: color;
    height: auto;
    max-width: 300px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 8px;

    &::after {
      display: none;
    }

    p {
      @extend %big-first-letter;
      margin: 5px 0;
    }
  }
}
