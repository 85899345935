@import "../../../../variables.scss";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 8px 0;

  @media #{$small-screen} {
    justify-content: center;
    align-items: flex-start;
  }

  > span {
    font-weight: 300;
    font-size: 16px;
    color: $dark-grey;
    margin: 0 10px 0 0;
    align-self: flex-start;
    margin: 22px 10px 0 0;

    @media #{$small-screen} {
      flex: 1;
    }
  }

  .curriculumsContainer {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    @media #{$small-screen} {
      flex-direction: column;
      align-items: flex-start;
    }

    %basic-curriculum {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 16px 5px 0;
      border-radius: 8px;
      padding: 10px 20px;
      transition: background 0.5s ease;
      border: none;
    }

    .curriculum {
      @extend %basic-curriculum;
      background: white;

      @media #{$small-screen} {
        margin: 15px 8px 0 0;
      }

      span {
        font-size: 12px;
        text-align: center;
        color: $dark-grey;
      }
    }

    .curriculumSingleSubject {
      @extend %basic-curriculum;
      background: #F4F4F4;
      box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
    }
  }

  .curriculumsContainerWithSubject {
    justify-content: flex-end;

    @media #{$small-screen} {
      align-items: flex-end;
    }

    .curriculum {
      margin: 15px 8px 0 8px;
    }
  }

  .curriculum.active, .curriculumSingleSubject.active {
    background: $violet;

    span {
      color: white;
    }
  }
}
