@import "../../../../../../../variables.scss";

.questionNotes {
  display: flex;
  width: 100%;
  max-width: 95%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;

    &:first-child {
      margin: 0;
    }
  }

  .blankNoteType {
    display: flex;
    width: 100%;
    max-width: 95%;
    flex-direction: column;
    border: 1px solid #AFAFAF;
    margin: 10px 0;

    @media #{$medium-screen} {
      margin: 0 0 5px 0;
    }

    @media #{$small-screen} {
      margin: 0 0 4px 0;
    }

    @media #{$extra-small-screen} {
      margin: 0 0 3.57px 0;
    }

    svg {
      width: 100%;
      height: auto;
      opacity: 0;
    }
  }
}

.noteContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  
  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;
  }
}

.noteContainerFirst {
  padding: 10px 0 0 0;

  > svg {
    padding: 0;
  }

  @media #{$medium-screen} {
    padding: 5px 0 0 0;
  }

  @media #{$small-screen} {
    padding: 4px 0 0 0;
  }

  @media #{$extra-small-screen} {
    padding: 3.57px 0 0 0;
  }
}

.noteContainerLast {
  padding: 0 0 10px 0;

  @media #{$medium-screen} {
    padding: 0 0 5px 0;
  }

  @media #{$small-screen} {
    padding: 0 0 4px 0;
  }

  @media #{$extra-small-screen} {
    padding: 0 0 3.57px 0;
  }
}

.noteContainerSingle {
  margin: 10px auto;

  @media #{$medium-screen} {
    margin: 5px auto;
  }

  @media #{$small-screen} {
    margin: 4px auto;
  }

  @media #{$extra-small-screen} {
    margin: 3.57px auto;
  }
}

.noteContainerBlank {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  
  > svg {
    width: 100%;
    height: auto;
    margin: -1px 0 0 0;
    opacity: 0;
  }
}

.noteContainerBlankFirst {
  padding: 10px 0 0 0;

  > svg {
    margin: 0;
  }

  @media #{$medium-screen} {
    padding: 5px 0 0 0;
  }

  @media #{$small-screen} {
    padding: 4px 0 0 0;
  }

  @media #{$extra-small-screen} {
    padding: 3.57px 0 0 0;
  }
}

.noteContainerBlankLast {
  padding: 0 0 10px 0;

  @media #{$medium-screen} {
    padding: 0 0 5px 0;
  }

  @media #{$small-screen} {
    padding: 0 0 4px 0;
  }

  @media #{$extra-small-screen} {
    padding: 0 0 3.57px 0;
  }
}
