@import "../../../../../../../variables.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 40px 0 55px;
  align-items: flex-start;

  @media #{$medium-screen} {
    padding: 0 20px 0 27.5px;
  }

  @media #{$small-screen} {
    padding: 0 16px 0 22px;
  }

  @media #{$extra-small-screen} {
    padding: 0 14.28px 0 19.64px;
  }
}

.listItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px 20px 13px 0;

  @media #{$medium-screen} {
    padding: 1px 10px 6.5px 0;
  }

  @media #{$small-screen} {
    padding: 0.8px 8px 5.2px 0;
  }

  @media #{$extra-small-screen} {
    padding: 0.7px 7.14px 4.64px 0;
  }

  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    cursor: default;
  
    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      max-width: 100%;

      .answerName {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: $violet;
        text-overflow: ellipsis;
        overflow: hidden;
        
        @media #{$medium-screen} {
          font-size: calc(13px / 2);
          line-height: calc(19px / 2);
        }
      
        @media #{$small-screen} {
          font-size: calc(13px / 2.5);
          line-height: calc(19px / 2.5);
        }
      
        @media #{$extra-small-screen} {
          font-size: calc(13px / 2.8);
          line-height: calc(19px / 2.8);
        }
      }
    }
  
    input[type="radio"] {
      min-width: 17px;
      min-height: 17px;
      width: 17px;
      height: 17px;
      border: 1px solid $light-grey;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 0;

      @media #{$medium-screen} {
        min-width: calc(17px / 2);
        min-height: calc(17px / 2);
        width: calc(17px / 2);
        height: calc(17px / 2);
      }
    
      @media #{$small-screen} {
        min-width: calc(17px / 2.5);
        min-height: calc(17px / 2.5);
        width: calc(17px / 2.5);
        height: calc(17px / 2.5);
      }
    }

    .inputContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;

      @media #{$medium-screen} {
        min-width: calc(20px / 2);
        min-height: calc(20px / 2);
        width: calc(20px / 2);
        height: calc(20px / 2);
        margin: 0 5px 0 0;
      }
    
      @media #{$small-screen} {
        min-width: calc(20px / 2.5);
        min-height: calc(20px / 2.5);
        width: calc(20px / 2.5);
        height: calc(20px / 2.5);
        margin: 0 4px 0 0;
      }
    
      @media #{$extra-small-screen} {
        min-width: calc(20px / 2.8);
        min-height: calc(20px / 2.8);
        width: calc(20px / 2.8);
        height: calc(20px / 2.8);
        margin: 0 3.57px 0 0;
      }

      > span {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        font-size: 10px;
        line-height: 11px;
        color: $light-grey;
        text-align: center;
        height: 100%;
        width: 100%;

        @media #{$medium-screen} {
          font-size: calc(11px / 2);
        }
      
        @media #{$small-screen} {
          font-size: calc(11px / 2.5);
          line-height: 8px;
        }
      
        @media #{$extra-small-screen} {
          font-size: calc(11px / 2.8);
          line-height: 8px;
        }

        &.multipleChoiceAnswerCorrect {
          color: white;
        }
      }
    }

    input[type="checkbox"] {
      min-width: 17px;
      min-height: 17px;
      width: 17px;
      height: 17px;
      border: 1px solid $light-grey;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 0;

      @media #{$medium-screen} {
        min-width: calc(17px / 2);
        min-height: calc(17px / 2);
        width: calc(17px / 2);
        height: calc(17px / 2);
      }
    
      @media #{$small-screen} {
        min-width: calc(17px / 2.5);
        min-height: calc(17px / 2.5);
        width: calc(17px / 2.5);
        height: calc(17px / 2.5);
      }
    }

    input:checked {
      background: $violet;
      border: 1px solid $violet;
    }
  }
}
