@import "../../../../../../variables.scss";

.changePasswordButton {
  display: flex;
  border: none;
  align-self: flex-start;
  margin: 40px 0 0 0;
  background: transparent;
  align-items: center;

  span {
    @extend %big-first-letter;
    color: $medium-grey;
    font-size: 14px;
  }

  svg {
    width: 16px;
    margin: 0 2px 0 0;
    path {
      fill: $medium-grey;
    }
  }
}

.modal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 80%;
  }

  @media #{$extra-small-screen} {
    max-width: 90%;
  }
}

.changePasswordModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    width: 40px;
    height: 40px;
  }

  .description {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 30px 0 0 0;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
      min-width: 120px;

      &:disabled {
        @extend %disabled-button;
        border: none;
      }
    }
  }
}
