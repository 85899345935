@import "../../../variables.scss";

.container {
  position: relative;
  height: 65px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 30px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.07);
}

.logoLink {
  height: 100%;

  .logo {
    display: flex;
    height: 100%;
    width: auto;
    padding: 5px 0;
    align-self: flex-start;
  
    @media #{$medium-screen} {
      display: flex;
    }
  }
}

.links {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;

  .gradientLink {
    display: flex;
    padding: 10px 30px;
    margin: 0 0 0 15px;
    min-width: 130px;
    font-size: 14px;
    justify-content: center;
  
    span {
      @extend %big-first-letter;
    }
  }
  
  .gradientLink {
    @extend %violet-gradient-button;
  }

  .gradientLink {
    display: flex;

    @media #{$small-screen} {
      display: none;
    }
  }

  > div {
    display: none;
    @media #{$small-screen} {
      display: flex;
    }
  }
}


.hamburgerContainer {
  display: flex;
  background: transparent;
  outline: none;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  border: none;
  padding: 0;
}

.hamburger {
  margin: 0 0 0 40px;

  @media #{$medium-screen} {
    margin: 0 0 0 15px;
  }

  path {
    transition: all 0.5s ease;
  }
}

.hamburgerCross {
  path {
    &:first-child {
      opacity: 0;
    }
    &:nth-child(2) {
      transform: translate(13px, 0px) rotate(45deg);
    }
    &:last-child {
      transform: translate(-15px, 15px) rotate(-45deg);
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.24);
  width: 240px;
  z-index: 999;

  .gradientLink {
    display: flex;
    padding: 10px 20px;
    margin: 5px 0;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  
    span {
      @extend %big-first-letter;
    }
  }
  
  .gradientLink {
    @extend %violet-gradient-button;
  }
}
