@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1068px;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 70px 0 50px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 500;
      font-size: 36px;
      color: #5555A7;
      margin: 0;
      text-align: center;

      .pink {
        color: $pink;
      }
    }
  }

  > p {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #5555A7;
    margin: 40px 0 20px 0;
    max-width: 880px;

    > span:last-of-type {
      display: block;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $violet-gradient;
    color: white;
    min-width: 200px;
    border-radius: 20px;
    padding: 12px 5px;
    text-decoration: none;
    margin: 20px 0;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }
  }

  .bottomContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 100px 0 0 0;

    button {
      @extend %transparent-button;
      position: relative;
      padding: 12px 35px;
      border-radius: 20px;
      margin: 0 15px 0 0;
      font-size: 14px;
      font-weight: 400;
      align-self: flex-end;
      min-width: 140px;
  
      @extend %big-first-letter;
    }
  }
}
