@import "../../variables.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px;
  margin: 15px 0 0 0;

  input {
    margin: 5px 0;
  }

  .linkContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media #{$medium-screen} {
      flex-direction: column;
    }

    a {
      text-decoration: none;
      color: $dark-grey;
      align-self: flex-start;
      font-size: 16px;
      font-weight: 300;
      margin: 15px 0 20px 0;

      &:hover {
        color: $violet;
      }

      @extend %big-first-letter;
    }
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 45px 0 0 0;

    @media #{$medium-screen} {
      flex-direction: column;
    }

    a {
      background: white;
      color: $violet;
      padding: 12px 20px;
      border: 1px solid $violet;
      border-radius: 20px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      align-self: flex-start;

      @extend %big-first-letter;

      @media #{$medium-screen} {
        margin: 20px 0 0 0;
        order: 2;
      }
    }
  }

  .submitContainer {
    justify-content: flex-end;
  }

  button {
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
    min-width: 140px;

    @extend %big-first-letter;

    &.buttonIsLoading {
      color: transparent;
    }

    > svg {
      color: white;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    &:disabled {
      @extend %disabled-button;
    }
  }
}

.info {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  max-width: 390px;
  margin: 0 0 35px 0;
}
