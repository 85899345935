@import "../../../../../../../variables.scss";

.trueFalse {
  display: flex;
  width: 100%;
  padding: 0 40px 10px 55px;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  @media #{$medium-screen} {
    padding: 0 20px 5px 27.5px;
  }

  @media #{$small-screen} {
    padding: 0 16px 4px 22px;
  }

  @media #{$extra-small-screen} {
    padding: 0 14.28px 3.57px 19.64px;
  }

  .trueFalseBody {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $violet;
    flex: 1;
    border: 1px solid #B8B8B8;
    padding: 15px 10px;
    margin: 0px 20px 0px 0px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;

    @media #{$medium-screen} {
      font-size: calc(16px / 2);
      line-height: calc(19px / 2);
      padding: 7.5px 5px;
      margin: 0px 10px 0px 0px;
    }
  
    @media #{$small-screen} {
      font-size: calc(16px / 2.5);
      line-height: calc(19px / 2.5);
      padding: 6px 4px;
      margin: 0px 8px 0px 0px;
    }
  
    @media #{$extra-small-screen} {
      font-size: calc(16px / 2.8);
      line-height: calc(19px / 2.8);
      padding: 5.35px 3.57px;
      margin: 0px 7.14px 0px 0px;
    }
  }

  .trueFalseAnswerContainer {
    display: flex;

    .trueFalseAnswer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #B8B8B8;
      font-size: 24px;
      line-height: 29px;
      color: #B8B8B8;
      width: 40px;
      height: 40px;
      margin: 0 -1px 0 0;

      @media #{$medium-screen} {
        font-size: calc(24px / 2);
        line-height: calc(29px / 2);
        width: calc(40px / 2);
        height: calc(40px / 2);
      }
    
      @media #{$small-screen} {
        font-size: calc(24px / 2.5);
        line-height: calc(29px / 2.5);
        width: calc(40px / 2.5);
        height: calc(40px / 2.5);
      }
    
      @media #{$extra-small-screen} {
        font-size: calc(24px / 2.8);
        line-height: calc(29px / 2.8);
        width: calc(40px / 2.8);
        height: calc(40px / 2.8);
      }
    }

    .trueFalseAnswerCorrect {
      background: $violet;
      color: white;
    }
  }
}
