@import "../../../../../../../../variables.scss";

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 0 18px 0;

  .checkboxContainer {
    color: white;
    padding: 8px 0 0 25px;
    text-align: center;
    height: 35px;
    width: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 16px;
    align-self: flex-start;
    margin: 0 20px 0 0;
  }

  .body {
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $violet;
    padding: 9px 30px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;

    .bold {
      font-weight: 600;
    }
  }
}
