@import "../../../../../variables.scss";

.container {
  display: flex;
  flex: 1;
  justify-content: flext-start;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;

  .box {
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: center;
    margin: 45px 10px;
    padding: 40px;
    box-shadow: $box-shadow;
    border-radius: 8px;
    width: 100%;
    max-width: 650px;

    @media #{$extra-small-screen} {
      flex-direction: column;
      align-items: center;
    }

    h1 {
      display: flex;
      font-size: 32px;
      font-weight: 300;
      margin: 0 0 25px 0;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      color: $green;

      > span {
        @extend %big-first-letter;
      }

      svg {
        margin: 0 25px 0 0;
      }
    }

    p {
      @extend %big-first-letter;
      text-align: center;
      color: $dark-grey;
      font-size: 16px;
      font-weight: 300;

      &:last-child {
        margin: 0 0 40px 0;
      }
    }

    > span {
      align-self: center;
      margin: 20px 0 10px 0;
      color: $green;
      @extend %big-first-letter;
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media #{$small-screen} {
      flex-direction: column;
      justify-content: center;
    }
  
    a {
      @extend %transparent-button;
      @extend %big-first-letter;
      position: relative;
      padding: 12px 35px;
      border-radius: 20px;
      margin: 0 15px 0 0;
      font-size: 14px;
      font-weight: 400;
      min-width: 140px;

      @media #{$small-screen} {
        margin: 10px 0;
        order: 2;

        &:nth-child(2) {
          order: 1;
        }
      }

      &:nth-child(2) {
        background: $violet-gradient;
        color: white;
        border: none;
      }
    }
  }
}