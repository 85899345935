@import "../../../../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    font-size: 24px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px;
    line-height: initial;
    text-align: center;
  
    @extend %big-first-letter;
  
    .primaryHeadingText {
      font-weight: 600;
      color: $violet;
    }
  }

  p {
    color: $grey;
    font-weight: 400;
    font-size: 16px;
    margin: 5px 10px;
    text-align: center;
    line-height: initial;
  
    @extend %big-first-letter;
  }

  .selectedVariant {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;

    > span {
      font-size: 12px;
      color: $dark-grey;
      margin: 10px 15px;

      &:first-of-type {
        color: #B8B8B8;
        margin: 10px 0;
        text-transform: uppercase;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #5858B1;
      background: transparent;
      border: none;
      outline: none;

      svg {
        width: 12px;
        height: 12px;
        margin: 0 5px 0 0;

        path {
          fill: #5858B1;
        }
      }
    }
  }

  .educationLevelContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
  }

  .curriculumsContainer {
    display: flex;
    flex-direction: column;

    li div {
      justify-content: center;
    }
  }
}
