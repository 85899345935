@import "../../../../../../../variables.scss";

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;

  @media #{$small-screen} {
    flex-wrap: wrap;
  }

  > svg {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10px;
    width: 25px;
  }

  .order {
    background: $violet;
    color: white;
    padding: 8px 20px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 16px;
    text-align: center;
    margin: 0 20px 0 0;

    @media #{$small-screen} {
      order: 2;
      margin: 0 10px 0 0;
    }
  }

  .pointsFlex {
    flex: 1;
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 35px;

    @media #{$small-screen} {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 15px 5px 15px;
      flex: auto;
      order: 1;
    }

    b {
      font-size: 12px;
      color: #B8B8B8;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 10px 0 0;

      @media #{$small-screen} {
        display: block;
        width: 100%;
      }
    }

    span {
      font-size: 12px;
      color: #B8B8B8;
      text-transform: uppercase;

      @media #{$small-screen} {
        display: block;
        width: 100%;
        margin: 5px 0;
      }
    }

    input {
      border: none;
      background: #F9F9F9;
      border-radius: 20px;
      padding: 8px 20px;
      margin: 0 20px 0 10px;
      color: $dark-grey;
      font-size: 12px;
      max-width: 70px;
      text-align: center;

      @media #{$small-screen} {
        margin: 0 10px 0 0;
      }
    }

    .collapseQuestionButton {
      display: none;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1.71px;
      text-transform: uppercase;
      background: transparent;
      border: none;
      color: #545454;

      @media #{$small-screen} {
        display: flex;
      }
    }
  }

  .pointsDnDMode {
    input {
      background: transparent;
      pointer-events: none;
    }
  }

  .pointsLastItem {
    @media #{$small-screen} {
      margin: 0 15px 5px 15px;
    }
  }
}

.headerIsCollapsed {
  flex-wrap: wrap;

  @media #{$small-screen} {
    .pointsLastItem {
      width: 100%;
      order: 1;
    }

    .order {
      order: 2;
    }

    .body {
      order: 3;
    }
  }
}


.body {
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $violet;
  margin: 9px 30px 9px 0;
  overflow: hidden;

  @media #{$small-screen} {
    padding: 9px 15px;
  }

  .bold {
    font-weight: 600;
  }
}
