@import "../../../../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  > svg {
    align-self: center;
    color: $violet;
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px;
    line-height: initial;
    text-align: center;
  
    @extend %big-first-letter;
  
    .primaryHeadingText {
      font-weight: 600;
      color: $violet;
    }
  }

  .educationLevelContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;

    > svg {
      color: $violet;
      font-size: 16px;
    }
  }

  .curriculumsContainer {
    display: flex;
    flex-direction: column;

    li div {
      justify-content: center;
    }
  }
}
