@import "../../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1;
  background: $whitey-grey;
  overflow: auto;

  .header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
      display: flex;
      margin: 0 0 10px 0;
      align-self: flex-start;
      align-items: center;
  
      > a {
        margin: 0 10px 0 0;
        background: transparent;
        border: none;
        padding: 0;
      }
  
      span {
        @extend %big-first-letter;
        font-weight: 300;
        font-size: 36px;
        color: $violet;
        border: none;
        max-width: 100%;
        margin: 0;
        padding: 0;
  
        @media #{$small-screen} {
          font-size: 24px;
          max-width: 200px;
          min-width: 200px;
        }
      }
    }

    .centerBox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 5px 20px;

      @media #{$small-screen} {
        margin: 20px 0;
      }

      .sectionText {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: #BEBEBE;
        margin: 5px 30px 5px 0;
      }

      div {
        > span {
          @extend %big-first-letter;
        }
      }
    }
  
    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      
      a, button {
        @extend %violet-gradient-button;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        display: flex;
        font-size: 14px;
        text-align: center;
        align-self: center;
        margin: 5px 0;
    
        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }

        &:first-child {
          margin: 5px 10px 5px 0;
        }
      }
  
      a.disabled {
        @extend %disabled-button;
      }
    }
  }
}

.view {
  flex: 1;
  height: 100%;
  background: $blue-grey;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @media #{$medium-screen} {
    max-width: 100vw;
  }

  .settings {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: calc(100% - 100px);
    margin: 25px auto 0 auto;

    @media #{$small-screen} {
      width: 100%;
    }

    .sectionText {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      color: #BEBEBE;
      margin: 5px 30px 5px 30px;
    }

    .settingsBox {
      display: flex;
      flex-direction: column;
      width: 100%;

      .generatePublicLinkBox {
        display: flex;
        flex-wrap: wrap;
        background: white;
        border-radius: 8px;
        min-width: 520px;
        width: 100%;
        margin: 20px auto 0 auto;
        padding: 20px 30px;

        @media #{$small-screen} {
          min-width: auto;
        }
        
        > span {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1.7;
          text-transform: uppercase;
          color: $medium-grey;
        }

        > p {
          flex: 1;
          font-size: 14px;
          min-width: 300px;
          margin: 10px 20px 10px 0;
          font-weight: 400;
          letter-spacing: 1.7;
          color: #545454;

          @media #{$small-screen} {
            min-width: 100%;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          margin: 10px 0;

          .inputContainer {
            width: 100%;
            margin: 0;
            position: relative;

            input {
              border: 1px solid #5858B1;
              color: #5858B1;
              border-radius: 8px;
              font-size: 14px;
              padding: 12px 120px 12px 20px;
              width: 100%;
              min-width: 300px;
              margin: 0;

              @media #{$small-screen} {
                min-width: 100%;
              }
            }

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 0;
              top: 0;
              min-width: 115px;
              background: #5858B1;
              border-radius: 8px;
              border: none;
              z-index: 1;
              height: 100%;
              font-size: 14px;
              color: white;
              margin: 0;

              span {
                @extend %big-first-letter;
              }
            }
          }

          .regenerateLinkButton {
            @extend %big-first-letter;
            margin: 20px 0 0 0;
            border: none;
            color: $violet;
            font-size: 14px;
            font-weight: 600;
            background: transparent;
            padding: 0;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 30px;
    max-width: 800px;
    width: calc(100% - 100px);
    position: relative;
    margin: 40px auto 0 auto;

    @media #{$small-screen} {
      padding: 0 0 0 20px;
      width: 100%;
    }

    h1 {
      display: flex;
      align-self: flex-start;
      font-weight: 400;
      font-size: 30px;
      color: $violet;
      margin: 0 20px 16px 0;

      span {
        @extend %big-first-letter;
      }
  
      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    .top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      @media #{$small-screen} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .sectionText {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: #BEBEBE;
        margin: 5px 0 10px 0;

        @media #{$small-screen} {
          order: 2;
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 5px 0 10px 0;

        @media #{$small-screen} {
          order: 1;
        }
  
        .textContainer {
          &:first-of-type {
            margin: 0 30px 0 0;
          }
  
          > span {
            display: inline-block;
            font-size: 14px;
            @extend %big-first-letter;
          }
        }
      }
    }
  }

  .innerView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    padding: 25px 30px;
    box-shadow: $box-shadow;
    border-radius: 8px;
    max-width: 800px;
    width: calc(100% - 100px);
    position: relative;
    margin: 0 auto 20px auto;

    @media #{$small-screen} {
      padding: 20px;
      width: 100%;
    }

    .sectionText {
      @extend %big-first-letter;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1.7px;
      color: $violet;
      margin: 5px 0 10px 0;
    }

    .box {
      display: flex;
      flex-direction: column;
      margin: 20px 0 30px 0;

      .lessonHTMLText {
        font-size: 14px;
        color: $dark-grey;
      }
    }
  }
}
