@import "../../../../../../../variables.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  background: white;
  margin: 10px 0;
  border-radius: 8px;
  padding: 20px 0;
}

.container {
  width: 100%;
}

.dndMatchingQuestionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px 70px;

  @media #{$small-screen} {
    padding: 2px 20px;
  }
}

.itemWrapper {
  display: flex;
  width: 100%;
  max-width: 150mm;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.itemContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.itemText {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $violet;
}

.dropPlace {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-width: 300px;
  min-height: 40px;
  background: transparent;
  border: 0.5px dashed #7A7A7A;
  border-radius: 8px;
  margin: 0 0 0 20px;

  > span, p {
    color: $violet;
  }
}

.dropPlaceEmpty {
  border: 0.5px dashed #7A7A7A;
}

.dropPlaceIsUsingPlaceholder {
  border: 0.5px dashed $violet;

  > span, p {
    color: transparent;
  }
}

.optionsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.notMatchedWrapper {
  margin: 8px 0;
}

.matchedItem {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  max-width: 300px;
  background: #ECECEC;
  border: 0.5px dashed transparent;
  border-radius: 8px;
  margin: 0 16px 0 0;
  padding: 10px;

  > span, p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $violet;
  }
}

.matchedItemPlaced {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 40px;
  background: transparent;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  border: none;

  > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $violet;
  }
}

.matchedItemPlacedDraggingOver {
  border: 0.5px dashed $violet;
  background: white;
  opacity: 0.8;

  > span, p {
    color: $violet;
  }
}
