@import "../../../../variables.scss";

.mobileAppBox {
  display: flex;
  flex-direction: column;
  background: #D2CDFB;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: 22px;
  min-width: 200px;
  width: 424px;
  margin: 10px;
  height: 420px;
  position: relative;
  overflow: hidden;

  @media #{$small-screen} {
    width: 100%;
    min-width: calc(100% - 20px);
    min-height: 200px;
    height: auto;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.79px;
    text-transform: uppercase;
    color: $violet-dark;
  }

  p {
    @extend %big-first-letter;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: $violet-dark;
    margin: 15px 0;

    b {
      font-weight: 600;
    }
  }

  .paragraphTop {
    @media #{$medium-screen} {
      display: none;
    }
  }

  .mobileContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px 0 0 0;
    position: relative;
    z-index: 1;

    @media #{$medium-screen} {
      flex-direction: row;
      flex-wrap: wrap;
      flex: inherit;
    }

    > img {
      position: absolute;
      height: 100%;
      width: auto;
      left: 0;
      top: 0;

      @media #{$medium-screen} {
        position: initial;
        width: 140px;
      }
    }

    .QRbox {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 120px;
      width: 280px;
      background: white;
      border-radius: 8px;
      padding: 10px;

      @media #{$medium-screen} {
        display: none;
      }

      &:first-of-type {
        margin: 20px 10px 10px 10px;
      }

      &:nth-of-type(2) {
        margin: 0px 10px 0px 10px;
      }

      > div {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        margin: 0 20px 0 0;

        span {
          font-size: 14px;
          line-height: 15px;
          font-weight: 600;
          color: $violet-dark;
        }

        > img {
          flex: 1;
          max-height: 85px;
        }
      }
    }

    .buttonsContainer {
      display: none;
      flex-direction: column;
      align-items: flex-end;
      flex: 1;
      padding: 0 10px;

      @media #{$medium-screen} {
        display: flex;
      }

      > a {
        align-self: center;
        margin: 5px 0;
      }
    }
  }

  > svg {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 0;
  }
}
