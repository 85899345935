@import "../../../../../../../variables.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
  width: 100%;

  @media #{$small-screen} {
    flex-direction: column;
  }
}

.containerTrueFalse {
  margin: 0 0 30px 0;

  @media #{$small-screen} {
    margin: 0;
  }
}

.listFull {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  align-self: baseline;
  margin: 0 0 20px 0;

  @media #{$small-screen} {
    width: 100%;
  }

  li {
    > div {
      margin: 0;
      width: 100%;

      @media #{$small-screen} {
        min-width: auto;
      }
    }
  }
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 65%;
  align-self: baseline;

  @media #{$small-screen} {
    width: 100%;
  }

  li {
    > div {
      margin: 0;
      max-width: 320px;
      width: 100%;

      @media #{$small-screen} {
        min-width: auto;
      }
    }
  }
}


.list, .listFull {
  > div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .listItemContainer {
    flex-direction: column;
    margin: 2px 0;

    &:first-child {
      margin: 0 0 2px 0;
    }

    span.error {
      align-self: flex-start;
      margin: 0 0 0 45px;
    }
  }

  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    width: 100%;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="radio"] {
      min-width: 19px;
      min-height: 19px;
      width: 19px;
      height: 19px;
      border: 1px solid $light-grey;
      border-radius: 50%;
      margin: 5px 10px 10px 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;

      &:checked {
        &:after {
          content: "";
          width: 11px;
          height: 11px;
          position: absolute;
          background: $violet;
          border-radius: 50%;
        }
      }

      &.error {
        border: 1px solid $red;
      }
    }

    input[type="checkbox"] {
      min-width: 19px;
      min-height: 19px;
      width: 19px;
      height: 19px;
      border: 1px solid $light-grey;
      border-radius: 4px;
      margin: 5px 10px 10px 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;

      &:checked {
        &:after {
          content: "";
          width: 13px;
          height: 13px;
          position: absolute;
          background: $violet;
          border-radius: 3px;
        }
      }

      &.error {
        border: 1px solid $red;
      }
    }

    input[type="text"] {
      margin: 0;
      color: $dark-grey;
      font-weight: 300;

      &:focus {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
      }
    }

    > div:focus-within {
      input {
        border: 1px solid #A9A9EB;
      }
    }

    label {
      font-weight: 300;
    }

    > span.answerName {
      color: $violet;
    }

    > button {
      display: flex;
      border: none;
      background: transparent;

      svg path {
        fill: #B8B8B8;
      }
    }
  }
}

.error {
  font-size: 12px;
  color: $red;
}

.errorFullWidth {
  @extend .error;
  width: 100%;
}

.info, .infoRight {
  display: flex;
  align-items: center;

  @media #{$small-screen} {
    display: none;
    width: 100%;
    max-width: 250px;
    margin: 20px 0 10px 0;
    order: 3;
  }

  svg {
    width: 30px;
    height: 30px;
    margin: 0 20px;

    @media #{$small-screen} {
      margin: 0 20px 0 0;
    }
  }

  span {
    @extend %big-first-letter;
    flex: 1;
    font-size: 14px;
    color: $violet;
  }
}

.info {
  width: 35%;
}

.infoRight {
  width: 400px;
  order: 2;
  margin: 0 140px 32px auto;
  align-self: flex-end;
}

.addAnswerChoice {
  display: flex;
  background: transparent;
  border: none;
  font-size: 16px;
  color: $violet;
  margin: 10px 0 0 0;
  padding: 0;

  @media #{$small-screen} {
    order: 2;
    margin: 10px 0;
  }
  
  span:nth-child(2) {
    @extend %big-first-letter;
    text-align: left;
  }
}

.addAnswerChoiceOnline {
  margin: 10px 0 20px 0;
}

.matchQuestionArrow {
  padding: 0 21px;

  > svg {
    width: 20px;
  }
}