@import "../../../../../../../variables.scss";

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 0 18px 0;

  @media #{$medium-screen} {
    padding: 0 0 9px 0;
  }

  @media #{$small-screen} {
    padding: 0 0 7.2px 0;
  }

  @media #{$extra-small-screen} {
    padding: 0 0 6.42px 0;
  }

  .order {
    background: $violet;
    color: white;
    padding: 8px 5px;
    text-align: center;
    height: 35px;
    width: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 16px;
    align-self: flex-start;
    margin: 0 20px 0 0;

    @media #{$medium-screen} {
      padding: 4px 2.5px;
      height: 17.5px;
      width: calc(50px / 2);
      font-size: calc(16px / 2);
      margin: 0 10px 0 0;
    }
  
    @media #{$small-screen} {
      padding: 3.2px 2px;
      height: 14px;
      width: calc(50px / 2.5);
      font-size: calc(16px / 2.5);
      margin: 0 8px 0 0;
    }
  
    @media #{$extra-small-screen} {
      padding: 2.85px 1.78px;
      height: 12.5px;
      width: calc(50px / 2.8);
      font-size: calc(16px / 2.8);
      margin: 0 7.15px 0 0;
    }
  }

  .body {
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $violet;
    padding: 9px 30px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;

    .bold {
      font-weight: 600;
    }

    @media #{$medium-screen} {
      font-size: calc(16px / 2);
      line-height: calc(19px / 2);
      padding: 4.5px 15px 0 0;
    }
  
    @media #{$small-screen} {
      font-size: calc(16px / 2.5);
      line-height: calc(19px / 2.5);
      padding: 3.6px 12px 0 0;
    }
  
    @media #{$extra-small-screen} {
      font-size: calc(16px / 2.8);
      line-height: calc(19px / 2.8);
      padding: 3.2px 10.71px 0 0;
    }
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 40px 0 0;

    @media #{$medium-screen} {
      padding: 6px 20px 0 0;
    }
  
    @media #{$small-screen} {
      padding: 4.8px 16px 0 0;
    }
  
    @media #{$extra-small-screen} {
      padding: 4.28px 14.28px 0 0;
    }

    .pointsNumber {
      font-size: 14px;
      color: #545454;
      margin: 0 0 0 15px;

      @media #{$medium-screen} {
        font-size: calc(14px / 2);
        margin: 0 0 0 7.5px;
      }
    
      @media #{$small-screen} {
        font-size: calc(14px / 2.5);
        margin: 0 0 0 6px;
      }
    
      @media #{$extra-small-screen} {
        font-size: calc(14px / 2.8);
        margin: 0 0 0 5.35px;
      }
    }
  }
}
