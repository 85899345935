@import "../../../../../../../variables.scss";

.modal {
  max-width: 751px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 90%;
    max-height: 500px;
  }
}
