@import "../../../../../../variables.scss";

.selectedCreditCardMethod {
  > h3 {
    margin: 40px 0 20px 0;
  }

  .selectedPaymentLineSection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0 20px 0;

    > p {
      @extend %big-first-letter;
      font-size: 16px;
      flex: 1;
      color: $dark-grey;

      > span {
        @extend %big-first-letter;
        display: inline-block;
      }

      > span.upercase {
        text-transform: uppercase;
      }
    }

    > button {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.7px;
      text-transform: uppercase;
      color: $violet;
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
    }

    .changeCurrency {
      display: flex;
      width: 100%;

      .currency {
        display: flex;
        align-items: center;
        margin: 20px 20px 0 0;

        > input[type="radio"] {
          min-width: 19px;
          min-height: 19px;
          width: 19px;
          height: 19px;
          border: 1px solid $violet;
          border-radius: 50%;
          margin: 0 10px 0 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
    
          &:checked {
            &:after {
              content: "";
              width: 11px;
              height: 11px;
              position: absolute;
              background: $violet;
              border-radius: 50%;
            }
          }
        }

        > label {
          color: $dark-grey;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
