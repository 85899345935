@import "../../../../../../../../variables.scss";

.modal {
  max-width: 180mm;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  > div {
    padding: 30px 0 !important;
  }

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.addPictureModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    @extend %big-first-letter;
    font-weight: 600;
    font-size: 16px;
    color: $violet;
    margin: 15px 0;
  }

  .cropImageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F6F6;
    padding: 0 30px;
    position: relative;
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  border: 1px dashed #B8B8B8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 25px;
  margin: 30px 0;
  width: 230px;
  height: 230px;
  cursor: pointer;

  svg {
    margin: 10px 0 0 0;
  }

  p {
    @extend %big-first-letter;
    margin: 15px 0 10px 0;
    font-size: 14px;
    line-height: 17px;
    color: #B8B8B8;
  }

  span { 
    font-size: 13px;
    line-height: 14px;
    color: #B8B8B8;
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0 0 0;
  padding: 0 30px;

  @media #{$small-screen} {
    flex-direction: column;
    justify-content: center;
  }

  .changePicture {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media #{$small-screen} {
      margin: 10px 0;
      order: 1;
    }
  }

  button {
    @extend %big-first-letter;
    font-size: 14px;
    padding: 10px 30px;

    &:disabled {
      @extend %disabled-button;
    }

    @media #{$small-screen} {
      margin: 5px 0;
    }
  }

  button:nth-of-type(1) {
    @extend %transparent-button;
    min-width: 110px;

    @media #{$small-screen} {
      display: none;
    }
  }

  button:nth-of-type(2) {
    @extend %violet-gradient-button;
    min-width: 110px;

    span {
      @extend %big-first-letter;
    }

    @media #{$small-screen} {
      order: 2;
    }
  }
}

.cropImage {
  [data-ord="nw"], [data-ord="ne"], [data-ord="sw"], [data-ord="se"] {
    width: 25px;
    height: 25px;
    background: transparent;
  }

  [data-ord="nw"] {
    border-top: 3px solid #5B5BB4;
    border-right: none;
    border-bottom: none;
    border-left: 3px solid #5B5BB4;
    margin-top: -1px;
    margin-left: -1px;
  }

  [data-ord="ne"] {
    border-top: 3px solid #5B5BB4;
    border-right: 3px solid #5B5BB4;
    border-bottom: none;
    border-left: none;
    margin-top: -1px;
    margin-right: -1px;
  }

  [data-ord="n"], [data-ord="e"], [data-ord="s"], [data-ord="w"] {
    display: none;
  }

  [data-ord="sw"] {
    border-top: none;
    border-right: none;
    border-bottom: 3px solid #5B5BB4;
    border-left: 3px solid #5B5BB4;
    margin-bottom: -1px;
    margin-left: -1px;
  }

  [data-ord="se"] {
    border-top: none;
    border-right: 3px solid #5B5BB4;
    border-bottom: 3px solid #5B5BB4;
    border-left: none;
    margin-bottom: -1px;
    margin-right: -1px;
  }
}