@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;

    h3 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 0 10px 0;

      span {
        font-weight: 300;
        font-size: 36px;
        color: $violet;
        border: none;
        max-width: 100%;
        min-width: 220px;
        margin: 0;
        padding: 0;

        @media #{$small-screen} {
          font-size: 24px;
          max-width: 200px;
          min-width: 200px;
        }
      }

      > a {
        margin: 0 10px 0 0;
        background: transparent;
        border: none;
        padding: 0;
      }

      > svg {
        display: flex;
        height: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        margin: 0 10px 8px 10px;

        @media #{$small-screen} {
          margin: 0 10px 5px 10px;
        }

        path {
          fill: $violet;
        }
      }

      .error {
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 12px;
        color: $red;
      }
    }

    > svg {
      margin: 0 10px 0 0;

      @media #{$small-screen} {
        height: 15px;
      }
    }

    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      button,
      a {
        @extend %transparent-button;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        min-width: 145px;
        display: flex;
        font-size: 14px;
        transition: all 0.5s ease;

        svg {
          margin: 0 5px 0 0;
          width: 15px;
          height: 15px;
          path {
            fill: $violet;
          }
        }

        &:hover {
          background-color: $violet;

          svg {
            path {
              fill: white;
            }
          }

          span {
            color: white;
          }
        }

        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }

        &:disabled {
          @extend %disabled-button;
        }
      }

      a {
        margin: 10px 20px 10px 0;
      }

      button {
        margin: 10px 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .tip {
      display: flex;
      background: $main-color;
      border-radius: 8px;
      padding: 15px;
      display: row;
      align-items: flex-start;
      margin: 0 0 15px 0;

      @media #{$small-screen} {
        flex-direction: column;
      }

      > span {
        font-size: 14px;
        color: #5858b1;
        margin: 0 8px 0 0;
        text-transform: uppercase;

        @media #{$small-screen} {
          margin: 0 0 10px 0;
        }
      }

      p {
        font-size: 14px;
        color: #5858b1;
      }
    }

    h4 {
      font-weight: 500;
      font-size: 24px;
      color: #5858b1;
    }

    .progress {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 30px 0 10px 0;

      .tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: color;
        height: 25px;
        max-width: 70px;
        height: 35px;
        background: $pink;
        font-size: 12px;
        border-radius: 8px;

        &::after {
          display: none;
        }
      }

      > span {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: $medium-grey;
        letter-spacing: 1.7px;
      }

      > div {
        display: flex;
        margin: 10px 0 0 0;
        background: white;
        border-radius: 8px;
        width: 100%;
        height: 8px;
        overflow: hidden;

        > div {
          height: 100%;
          border-radius: 8px;
          background: $pink-gradient;
        }
      }
    }

    .curriculumInfo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      background: #5858b1;
      border-radius: 8px;
      padding: 12px 18px;
      min-height: 56px;
      margin: 30px 0 0 0;

      @media #{$small-screen} {
        align-items: center;
        justify-content: center;
      }

      > svg {
        height: 100%;
        path {
          fill: white;
        }

        @media #{$small-screen} {
          margin: 0 15px 0 0;
        }
      }

      .curriculumInfoText {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;

        @media #{$small-screen} {
          flex-direction: column;
          justify-content: flex-end;
        }

        > span {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: white;
          margin: 0 0 0 25px;

          @media #{$small-screen} {
            margin: 5px 0;
            justify-content: flex-end;
          }
        }

        .variant {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          align-items: center;
          justify-content: flex-end;

          span {
            font-size: 16px;
            color: white;

            @media #{$small-screen} {
              margin: 5px 0;
            }

            &:first-of-type {
              @extend %big-first-letter;
            }
          }
        }
      }
    }
  }
}

.deleteCurriculumModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    width: 40px;
    height: 40px;
  }

  > .description {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 250px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0 0 0;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 20px;
      margin: 0 5px 0 0;
      min-width: 120px;

      @media #{$small-screen} {
        min-width: 100px;
      }

      &:disabled {
        @extend %disabled-button;
      }

      &:nth-last-of-type(1) {
        @extend %violet-gradient-button;
        margin: 0 0 0 5px;
      }
    }
  }
}
