@import "../../../variables.scss";

.inputContainer {
  display: flex;
  width: 100%;
  position: relative;
  margin: 5px 0 10px 0;

  > svg {
    position: absolute;
    right: 20px;
    z-index: 2;
    align-self: center
  }

  input {
    position: relative;
    z-index: 1;
    width: 100%;
    border: 1px solid $light-grey;
    border-radius: 20px;    
    padding: 10px 15px;
    font-size: 16px;
    color: $violet;

    &.error {
      border: 1px solid $red;
    }

    &:disabled {
      color: grey;
      background: transparent;
    }
  }

  .labelContainer {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 10px 15px;
    align-items: center;
    cursor: text;

    .labelHiddenOnActive {
      background: transparent;
      font-size: 16px;
      padding: 0 2px 0 1px;
    }
  
    .labelHiddenOnActive.active {
      display: none;
      transform: none;
    }

    label {
      position: relative;
      z-index: 1;
      font-size: 13px;
      color: #787878;
      cursor: text;
      background: transparent;
      padding: 0 2px;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),color 150ms cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;

      @extend %big-first-letter;

      &.error {
        color: $red;
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    label.active {
      transform: translateY(-18px) translateX(-6px) scale(0.85);
      background: white;
    }
  }
}

span.error {
  display: flex;
  align-self: flex-start;
  padding: 0 55px 0 15px;
  margin: 0 0 20px 0;
  font-size: 12px;
  color: $red;
  line-height: 15px;
}

.tooltipContainer {
  position: absolute;
  right: -30px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 25px;
  height: 25px;

  .tooltip {
    color: color;
    width: 100%;
    max-width: 324px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.07);
    border-radius: 8px;

    &::after {
      display: none;
    }

    @media #{$medium-screen} {
      max-width: 200px;
    }
  }

  i {
    svg {
      cursor: pointer;
    }

    &.error svg path {
      fill: $red;
    }
  }
}
