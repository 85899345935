@import "../../../../../variables.scss";

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;

  .top {
    > h1 {
      font-size: 32px;
      color: $dark-grey;
      font-weight: 300;
      margin: 10px;
      line-height: initial;
      text-align: center;
  
      @extend %big-first-letter;
  
      span {
        font-weight: 600;
        color: $violet;
      }
    }
  
    > p {
      color: $grey;
      font-weight: 300;
      font-size: 16px;
      margin: 5px 10px;
      text-align: center;
      line-height: initial;
  
      @extend %big-first-letter;
    }
  
    .box {
      display: flex;
      background: white;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 45px 10px;
      padding: 40px;
      box-shadow: $box-shadow;
      border-radius: 8px;
      width: calc(100% - 20px);
      max-width: 650px;
      min-width: 650px;

      @media #{$small-screen} {
        width: auto;
        min-width: auto;
      }

      @media #{$extra-small-screen} {
        flex-direction: column;
        align-items: center;
      }
  
      .inputContainer {
        display: flex;
        margin: 5px 20px;
        position: relative;
  
        @media #{$extra-small-screen} {
          margin: 0;
        }
  
        &:nth-child(1) {
          flex: 1;
          min-width: 150px;
        }

        > div {
          display: flex;
          width: 100%;

          .wrapper {
            width: 100%;
            position: relative;

            > div {
              display: flex;
              width: 100%;
              margin: 0;
              position: relative;
  
              > div {
                margin: 0;
              }
            }

            .dropdown {
              position: absolute;
              width: 100%;
              background: #FCFCFC;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
              border-radius: 8px;
              padding: 10px 15px;
              z-index: 3;
              max-height: 300px;
              overflow: auto;
        
              ul {
                width: 100%;
                list-style: none;
        
                li {
                  margin: 5px 0;
        
                  button {
                    @extend %big-first-letter;
                    width: 100%;
                    background: transparent;
                    border: none;
                    font-size: 12px;
                    color: $dark-grey;
                    text-align: left;
                  }

                  span {
                    font-size: 12px;
                    color: $dark-grey;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }

      .or {
        display: flex;
        font-size: 16px;
        color: $violet;
        align-items: center;
        font-style: italic;
        margin: 5px 0;
      }

      .addClass {
        @extend %transparent-button;
        margin: 0;
        display: flex;
        padding: 10px 25px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        span {
          @extend %big-first-letter;
          display: inline-block;
        }
      }
    }
  
    .bottomContainer {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: center;
      align-items: center;
    
      .goBackButton {
        @extend %transparent-button;
        position: relative;
        padding: 12px 35px;
        border-radius: 20px;
        margin: 0 15px 0 0;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        min-width: 140px;
    
        @extend %big-first-letter;
      }

      .nextButton {
        @extend %big-first-letter;
        background: $violet-gradient;
        position: relative;
        color: white;
        padding: 12px 35px;
        border: none;
        border-radius: 20px;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        min-width: 140px;

        &:disabled {
          @extend %disabled-button;
        }
      }

      @media #{$small-screen} {
        flex-direction: column;
        align-items: center;

        .goBackButton {
          align-self: center;
          margin: 20px 0 0 0;
          order: 2;
        }

        .nextButton {
          align-self: center;
          order: 1;
        }
      }
    }
  }

  > a {
    @extend %back-to-homepage;
    text-align: center;
  }
}
