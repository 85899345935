@import "../../../../../../../variables.scss";

.questionNotes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 0 25px;
  margin: 20px 0 10px 0;

  @media #{$small-screen} {
    flex-direction: column;
    align-items: flex-start;
  }

  > span {
    font-size: 12px;
    color: #B8B8B8;
    text-transform: uppercase;
    margin: 0 10px 0 0;

    @media #{$small-screen} {
      margin: 0 10px 10px 0;
    }
  }

  .notesTypesButtons {
    display: flex;
    flex-wrap: wrap;
    > button {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      margin: 10px 20px 10px 0;
      background: transparent;

      @media #{$small-screen} {
        margin: 0 20px 10px 0;
      }

      > svg {
        line, rect {
          transition: all 0.5s ease;
        }
      }

      &:hover {
        > svg {

          line, rect {
            stroke: $violet;
          }
        }
      }
    }
  }

  .noteType {
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px 0;
    width: 100%;
    position: relative;

    svg {
      width: 100%;
      max-width: 750px;
      align-self: center;
      height: auto;
      margin: -1px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }

    .blankNoteType {
      display: flex;
      flex-direction: column;
      border: 1px solid #AFAFAF;

      svg {
        opacity: 0;
      }
    }

    .noteSettings {
      display: flex;
      align-self: center;
      margin: -10px 0 0 0;

      button {
        background: $violet-gradient;
        box-shadow: 0px 2px 16px #B5B5E5;
        border-radius: 100%;
        color: white;
        font-weight: 500;
        font-size: 24px;
        width: 42px;
        height: 42px;
        padding: 0;
        align-items: center;
        justify-content: center;
        margin: 0 7px;
        outline: none;
        border: none;

        svg {
          height: 17px;
          path {
            fill: white;
          }
        }
      }
    }
  }
}