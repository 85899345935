@import "../../../variables.scss";

@mixin box-violet {
  background: $violet;

  > span {
    color: white;
  }

  > svg {
    path {
      fill: white;
    }
  }
}

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;

  > h1 {
    @extend %big-first-letter;
    font-weight: 300;
    font-size: 36px;
    color: $violet;
    margin: 0 20px 10px 0;
    align-self: flex-start;

    @media #{$small-screen} {
      font-size: 24px;
    }
  }

  .filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0 0 30px 0;

    > span {
      margin: 0 10px 0 0;
      font-weight: 600;
      font-size: 16px;
      color: $dark-grey;
      @extend %big-first-letter;
    }

    > li {
      margin: 5px 10px;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 15px;
        background: white;
        box-shadow: $box-shadow;
        border-radius: 16px;
        border: none;
        font-size: 14px;
        color: $dark-grey;
        transition: background 0.5s ease;

        @media (hover: hover) {
          &:hover {
            @include box-violet;
          }
        }
  
        &.active {
          @include box-violet;
        }

        label {
          height: 16px;
          width: 16px;
          padding: 0;
          margin: 0 10px 0 0;
          pointer-events: none;
      
          span {
            position: initial;
            height: 16px;
            width: 16px;
          }
        }

        > svg {
          height: 18px;
          width: 18px;
          margin: 0 0 0 10px;
          
          path {
            fill: $dark-grey;
          }
        }
      }
    }
  }

  .placeholder {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .createTestContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      height: 100%;

      h3 {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: $medium-grey;
      }
    }
  }

  .createTestLink {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    width: 195px;
    height: 195px;
    margin: 20px 7px;
    background: transparent;
    border: 1px solid $border-grey;
    border-style: dashed;
    padding: 40px;
    border-radius: 12px;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    color: $dark-grey;

    span:nth-child(2) {
      @extend %big-first-letter;
    }
  }

  > a {
    @extend %back-to-homepage;
    text-align: center;
  }

  .tests {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    h3 {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      color: $medium-grey;
    }

    .allTestsList {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    }
  }
}
