@import "../../../../../variables.scss";

.reports {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;

  .headingItem, .listItem {
    > .testName {
      width: 45%;
      display: flex;
      justify-content: flex-start;

      @media #{$small-screen} {
        width: 80%;
      }
    }

    > .subject {
      width: 15%;
      display: flex;
      justify-content: center;
      font-size: 12px;

      @media #{$small-screen} {
        display: none;
      }
    }

    > .percentagePoints {
      width: 15%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }

    > .createdAt {
      width: 15%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }

    > .actions {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media #{$small-screen} {
        width: 20%;
      }

      button, a {
        justify-content: center;
        display: flex;
        border: none;
        background: transparent;
        padding: 5px;
        margin: 0 5px;

        svg {
          cursor: pointer;
          path {
            fill: #B8B8B8;
          }
        }

        &:hover {
          svg path {
            fill: $violet;
          }
        }
      }

      .toggleIcon {
        display: none;

        @media #{$small-screen} {
          display: flex;
        }

        svg, svg path {
          transition: all 0.5s ease;
        }

        &.active {
          svg {
            transform: rotate(180deg);
            path {
              fill: $violet;
              stroke: $violet;
            }
          }
        }
      }

      .tooltipContainer {
        .tooltip {
          display: flex;
          justify-content: center;
          align-items: center;
          color: color;
          height: 25px;
          max-width: 300px;
          background: $pink;
          padding: 10px 20px;
          font-size: 12px;
          border-radius: 8px;
      
          &::after {
            display: none;
          }
      
          p {
            @extend %big-first-letter;
          }
        }
      
        i {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .headingItem {
    width: 100;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 2px 0;
    padding: 10px;

    div {
      height: 30px;
      align-items: center;
      > span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
        color: $medium-grey;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .listItem {
    width: 100;
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 8px 0;
    padding: 10px;

    @media #{$small-screen} {
      flex-wrap: wrap;
      height: auto;
    }

    div {
      display: flex;
      height: 100%;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 5px 20px;
        font-weight: 400;
      }
    }

    .testName {
      span {
        font-weight: 600;
        font-size: 12px;
        color: $violet;
        padding: 0;
      }
    }

    .subject {
      span {
        font-weight: 600;
        font-size: 12px;
        color: #545454;
      }
    }

    .createdAt {
      span {
        font-weight: 600;
        font-size: 12px;
        color: #545454;
      }
    }

    .percentagePoints {
      span {
        font-weight: 600;
        font-size: 12px;
        color: #A194EF;
      }
    }

    .dropDownContainer {
      display: none;
      width: 100%;

      @media #{$small-screen} {
        display: flex;
      }

      > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
          padding: 0;

          > span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            color: $medium-grey;
            text-overflow: ellipsis;
            justify-content: flex-start;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
            padding: 5px 5px 5px 0;
          }

          &.subject {
            span:nth-of-type(2) {
              font-weight: 600;
              color: #545454;
            }
          }

          &.createdAt {
            span:nth-of-type(2) {
              font-weight: 600;
              color: #545454;
            }
          }

          &.percentagePoints {
            span:nth-of-type(2) {
              font-weight: 600;
              color: #A194EF;
            }
          }
        }
      }
    }
  }
}
