@import "../../../../../variables.scss";

@mixin box-violet {
  background: $violet;

  > span {
    color: white;
  }

  > svg {
    path {
      fill: white;
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  box-shadow: $box-shadow;
  transition: background 0.5s ease;
  border: none;

  > span {
    color: $dark-grey;
    margin: 5px 0 0 0;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: normal;
    font-size: 12px;
  }

  > svg {
    height: 50px;
    margin: 0 0 2px 0;
  }

  @media (hover: hover) {
    &:hover {
      @include box-violet;
    }
  }
}

.box.active {
  @include box-violet;
}
