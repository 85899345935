@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;

  .summaryBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background: white;
    border-radius: 8px;
    padding: 15px;
    align-items: center;
    margin: 0 0 16px 0;

    @media #{$small-screen} {
      width: 100%;
    }

    > span {
      @extend %big-first-letter;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $grey;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      justify-content: flex-end;

      @media #{$small-screen} {
        flex-direction: column;
        flex: auto;
        width: 100%;
        margin: 10px 0 0 0;
        justify-content: flex-start;
      }

      .textContainer {
        @extend %big-first-letter;
        margin: 0 15px 0 15px;
        font-size: 14px;
        color: #545454;

        @media #{$small-screen} {
          margin: 3px 0;
        }
      }
    }

    span.violet {
      color: $violet;
    }

    span.darkGrey {
      color: $dark-grey;
    }
  }

  .questionsBaseContainer {
    background: white;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 8px;

    .nextPageButton {
      @extend %violet-gradient-button;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      padding: 10px 60px;
      margin: 20px auto;
      height: 40px;

      span {
        @extend %big-first-letter;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .questionsBaseResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .questionsBaseNoResultsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 150px;
    flex-wrap: wrap;

    > h3 {
      font-size: 16px;
      color: $medium-grey;
      font-weight: 400;
      @extend %big-first-letter;
    }
  }

  .controlsContainer {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    align-items: center;
    justify-content: space-between;
    margin: 55px 0 45px 0;

    @media #{$small-screen} {
      flex-direction: column;
      margin: 45px 0 0 0;
      width: 100%;
    }

    .cancelButton {
      @extend %transparent-button;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 10px;
      padding: 10px 60px;
      background: transparent;
      height: 40px;

      span {
        @extend %big-first-letter;
        font-size: 14px;
        text-align: center;
      }

      @media #{$small-screen} {
        width: calc(100% - 40px);
        max-width: 250px;
        order: 2;
        margin: 0 20px 10px 20px;
      }
    }

    .addButton {
      @extend %violet-gradient-button;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 10px;
      margin: 0 20px;
      padding: 12px 60px;
      color: white;
      height: 100%;

      span, i {
        @extend %big-first-letter;
        font-size: 14px;
        text-align: center;
      }

      &:disabled {
        @extend %disabled-button;
      }

      @media #{$small-screen} {
        width: calc(100% - 40px);
        max-width: 250px;
        order: 1;
        margin: 0 20px 10px 20px;
      }
    }

    button {
      @extend %violet-gradient-button;
      @extend %big-first-letter;

      padding: 10px 50px;
      font-size: 14px;
      margin: 0 0 0 15px;
      height: 40px;
      text-align: center;
      min-width: 162px;
    }
  }
}
