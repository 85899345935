@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 16px;
      color: $medium-grey;
      margin: 0 20px 15px 0;
      width: 100%;

      > a {
        text-decoration: none;
        color: $medium-grey;
      }

      > span {
        font-weight: 600;
      }

      @media #{$small-screen} {
        font-size: 14px;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .heading {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;
      color: $medium-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 15px 20px;
    }

    .questionPreview {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      width: 100%;

      section {
        margin: 5px 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 10px 0;
      }
    }

    .skills {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: 100%;

      > li {
        margin: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}
