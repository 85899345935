@import "../../../variables.scss";

.container {
  display: flex;
  align-items: center;

  span {
    margin: 0 2px 0 15px;
    font-size: 14px;
    color: #545454;

    &:first-of-type {
      margin: 0 15px 0 2px;
    }
  }

  > .inputContainer {
    display: flex;
    align-items: center;
    position: relative;

    input {
      background: #FFFFFF;
      border-radius: 20px;
      width: 62px;
      height: 32px;
      cursor: pointer;
      border: none;
    }

    i {
      width: 28px;
      height: 28px;
      position: absolute;
      border-radius: 100px;
      left: 0px;
      transform: translate(20%, 0px);
      background: linear-gradient(180deg, #7474CB 1%, #40409B 112%);
      transition: all 0.5s ease-in-out;
      cursor: pointer;
    }
  }

  .isChecked {
    i {
      transform: translate(120%, 0px);
    } 
  }

  .isGrayedOut {
    i {
      background: #9F9F9F;
    }
  }

  .isDisabled {
    i {
      background: $disabled-gradient;
    }
  }
}
