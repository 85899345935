@import "../../../../../../../../../variables.scss";

.fillInTheGapsSettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;

  .checkboxContainer {
    display: flex;
    margin: 0 0 10px 0;

    @media #{$small-screen} {
      margin: 10px 0 0 0;
    }

    label {
      padding-left: 19px;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      outline: none;

      span {
        line-height: 19px;
        font-size: 14px;
        color: #545454;
      }
    }
  }

  .infoRight {
    display: flex;
    align-items: center;
    margin: 0px 0 20px 0;
    max-width: 550px;
  
    @media #{$small-screen} {
      margin: 10px 0 20px 0;
    }
  
    svg {
      width: 30px;
      height: 30px;
      margin: 0 20px;
    }
  
    span {
      @extend %big-first-letter;
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 14px;
      color: $violet;
    }
  }
}
