@import "../../../../../../variables.scss";

.addQuestionFromQuestionsBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 25px 15px 15px 15px;
  box-shadow: $box-shadow;
  border-radius: 8px;
  max-width: 694px;
  position: relative;
  z-index: 1;
  margin: 0 0 12px 0;

  @media #{$medium-screen} {
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  @media #{$small-screen} {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .closeButton {
    position: absolute;
    right: 25px;
    top: 25px;
    background: transparent;
    border: none;
  }

  > span {
    @extend %big-first-letter;
    color: $violet;
    font-size: 16px;
  }

  > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;

    @media #{$small-screen} {
      flex-direction: column;
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 150px;
      width: 150px;
      background: #F5F5F5;
      border-radius: 12px;
      margin: 20px 15px;
      cursor: pointer;
      transition: background-color 0.5s ease;

      @media #{$small-screen} {
        flex-direction: row;
        width: 100%;
        height: 110px;
        margin: 20px 0px;
      }

      .tooltip {
        color: color;
        max-width: 300px;
        background: $pink;
        padding: 10px 20px;
        font-size: 12px;
        box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
    
        &::after {
          display: none;
        }
    
        p {
          @extend %big-first-letter;
        }
    
        @media #{$medium-screen} {
          max-width: 200px;
        }
      }

      .disabledButton {
        cursor: auto;
        background: $disabled-gradient;
        color: white;
        border-radius: 12px;
      }

      button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        flex: 1;
        padding: 25px 20px;
        margin: 0;

        @media #{$small-screen} {
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
        }

        > span {
          display: block;
          @extend %big-first-letter;
          margin: 10px 0 0 0;
          font-size: 16px;
          font-weight: 400;
          color: white;

          @media #{$small-screen} {
            font-size: 14px;
            margin: 10px 10px 10px 20px;
            text-align: left;
          }
        }
      }

      &.add {
        background-color: $pink;

        &:hover {
          background-color: $violet;
        }

        svg {
          width: 100%;
          max-width: 55px;
          min-width: 55px;

          @media #{$small-screen} {
            max-width: 50px;
            min-width: 50px;
          }
        }
      }
    }
  }
}
