@import "../../../../../../../variables.scss";

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;

  input[type="checkbox"] {
    min-width: 19px;
    min-height: 19px;
    width: 19px;
    height: 19px;
    border: 1px solid $light-grey;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 0;
  }
  
  input:checked {
    background: $violet;
    border: 1px solid $violet;
  }

  label {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    height: 100%;
    width: 100%;
  }
}

.inputContainerChecked {
  > label {
    color: white;
  }
}

.inputContainerNotChecked {
  > label {
    color: #545454;
  }
}
