@import "../../../../../../variables.scss";

.questionTypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 25px 15px 15px 15px;
  box-shadow: $box-shadow;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  margin: 0 12px 12px 0;

  @media #{$medium-screen} {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }

  .closeButton {
    position: absolute;
    right: 25px;
    top: 25px;
    background: transparent;
    border: none;
  }

  > span {
    @extend %big-first-letter;
    color: $violet;
    font-size: 16px;
  }

  > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;

    @media #{$small-screen} {
      flex-direction: column;
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 150px;
      width: 150px;
      min-width: 150px;
      background: #F5F5F5;
      border-radius: 12px;
      margin: 20px 15px;
      cursor: pointer;
      transition: background-color 0.5s ease;
      position: relative;

      &:hover {
        background: $violet;

        button {
          > span {
            color: white;
          }

          svg {
            path {
              fill: white;
            }

            rect {
              stroke: white;
            }
          }
        }
      }

      &.disabled {
        cursor: auto;
        &:hover {
          background: #F5F5F5;
          button {
            > span {
              color: #dadada;
            }
          }
        }

        button {
          pointer-events: none;

          svg {
            path {
              fill: #dadada;
            }

            rect {
              stroke: #dadada;
            }
          }

          > span {
            color: #dadada;
          }
        }
      }

      @media #{$small-screen} {
        flex-direction: row;
        width: 100%;
        height: 110px;
        margin: 20px 0px;
      }

      button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        flex: 1;
        padding: 25px 20px;
        margin: 0;

        @media #{$small-screen} {
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
        }

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        > span {
          display: block;
          @extend %big-first-letter;
          margin: 10px 0 0 0;
          font-size: 16px;
          font-weight: 400;
          color: #8C8C8C;

          @media #{$small-screen} {
            font-size: 14px;
            margin: 10px 10px 10px 20px;
            text-align: left;
          }
        }
      }

      &.multiple_choice {
        order: 1;

        svg {
          width: 100%;
          max-width: 55px;
          min-width: 55px;

          @media #{$small-screen} {
            max-width: 50px;
            min-width: 50px;
          }

          path {
            stroke: transparent;
          }
        }
      }

      &.open {
        order: 2;

        svg {
          width: 100%;
          max-width: 55px;
          min-width: 55px;

          @media #{$small-screen} {
            max-width: 50px;
            min-width: 50px;
          }
        }
      }

      &.true_false {
        order: 3;

        svg {
          width: 100%;
          max-width: 55px;
          min-width: 55px;

          @media #{$small-screen} {
            max-width: 50px;
            min-width: 50px;
          }
        }
      }
    }
  }
}

.soonLabel {
  color: $violet;
  position: absolute;
  font-weight: 900;
  text-transform: uppercase;
  padding: 20px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 20px;
}
