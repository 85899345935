@import "../../../variables.scss";

.generateNewPublicLinkModal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }

  .generateNewPublicLinkContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    h2 {
      display: flex;
      align-items: center;
      font-size: 32px;
      color: #5858B1;
      margin: 0 0 20px 0;
  
      > svg {
        width: 40px;
        height: 40px;
      }

      span {
        @extend %big-first-letter;
      }
    }
  
    > .description {
      @extend %big-first-letter;
      color: $dark-grey;
      font-size: 14px;
      max-width: 350px;
      text-align: center;
      margin: 10px 0 10px 0;
    }
  
    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin: 20px 0;
  
      button {
        @extend %big-first-letter;
        @extend %transparent-button;
        font-size: 14px;
        padding: 10px 20px;
        margin: 0 5px 0 0;
        min-width: 120px;
  
        @media #{$small-screen} {
          min-width: 100px;
          margin: 5px 0;
        }
  
        &:disabled {
          @extend %disabled-button;
        }
  
        &:nth-last-of-type(1) {
          @extend %violet-gradient-button;
          margin: 0 0 0 5px;

          @media #{$small-screen} {
            margin: 5px 0;
          }
        }
      }
    }
  }
}
