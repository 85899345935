@import "../../../../../../../../../variables.scss";

.fillInTheGapsMoreCorrectAnswers {
  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    width: 100%;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="text"] {
      margin: 0;
      color: $dark-grey;
      font-weight: 300;

      &:focus {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
      }
    }

    > div:focus-within {
      input {
        border: 1px solid #A9A9EB;
      }
    }

    label {
      font-weight: 300;
    }

    > span.answerName {
      color: $violet;
    }

    > button {
      display: flex;
      border: none;
      background: transparent;

      svg path {
        fill: #B8B8B8;
      }
    }
  }

  .mainGapName {
    display: flex;
    color: $violet;
    font-size: 16px;
    border-bottom: 1px solid $violet;
    padding: 5px 0;
    margin: 0 0 10px 0;

    > span:first-child {
      font-weight: 900;
      margin: 0 10px 0 0;
    }
  }

  .addAlternativeAnswerButton {
    color: $violet;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    margin: 5px 0 20px 0;
  }
}