@import "../../../../../variables.scss";

.listItem {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  margin: 16px 0 0 0;

  > .header {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    align-items: center;

    label {
      margin-bottom: 20px;
    }

    > div {
      flex: 1;
      font-weight: 600;
      font-size: 18px;
      color: #5858B1;
    }

    > button {
      font-weight: 600;
      font-size: 12px;
      background: transparent;
      border: none;
      letter-spacing: 1.7px;
      text-transform: uppercase;
      color: $violet-dark;
    }
  }

  > ul {
    margin: 10px 0;
  }

  .collapsedHeader {
    border-bottom: 1px solid #DDDFE2;
  }
}

.listItemDarkMode {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: 16px 0 0 0;

  > .header {
    display: flex;
    border-radius: 8px;
    flex-wrap: wrap;
    padding: 15px 25px;
    background: #5858B1;

    > div {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
      color: white;
    }

    > button {
      font-weight: 600;
      font-size: 12px;
      background: transparent;
      border: none;
      letter-spacing: 1.7px;
      text-transform: uppercase;
      color: white;
    }
  }

  > ul {
    margin: 10px 0;
    border-radius: 8px;
    background: $blue-grey;

    label {
      > span {
        border: 1px solid #979797;
      }
    }
  }
}
