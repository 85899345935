@import "../../../variables.scss";

.modal {
  max-width: 460px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.duplicateTestModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 32px;
    color: $dark-grey;
    font-weight: 300;
    margin: 10px 10px 20px 10px;
    line-height: initial;
    text-align: center;

    @extend %big-first-letter;

    span {
      font-weight: 600;
      color: $violet;
    }
  }

  > p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #545454;
    padding: 0 30px;
  }

  > div {
    display: flex;
    width: 100%;
    padding: 0 30px;
  }

  > button {
    @extend %big-first-letter;
    @extend %violet-gradient-button;
    font-size: 14px;
    padding: 10px 30px;
    min-width: 145px;
    margin: 30px 0 0 0;

    &:disabled {
      @extend %disabled-button;
    }
  }
}

.wrapper {
  width: 100%;
  position: relative;
  margin: 30px 0 0 0;

  > div {
    display: flex;
    width: 100%;
    margin: 0;
    position: relative;

    > div {
      margin: 0;
    }
  }

  .dropdown {
    position: absolute;
    width: 100%;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 10px 15px;
    z-index: 3;
    max-height: 300px;
    overflow: auto;
    margin: 0 0 20px 0;

    ul {
      width: 100%;
      list-style: none;

      li {
        margin: 5px 0;

        button {
          @extend %big-first-letter;
          width: 100%;
          background: transparent;
          border: none;
          font-size: 12px;
          color: $dark-grey;
          text-align: left;
        }

        span {
          font-size: 12px;
          color: $dark-grey;
          text-align: left;
        }
      }
    }
  }
}
