@import "../../variables.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px;
  margin: 15px 0 0 0;

  input {
    margin: 5px 0;
  }

  > label {
    margin: 8px 0;
    line-height: 19px;
  }

  @media #{$medium-screen} {
    > div {
      width: calc(100% - 30px) !important;
      align-self: flex-start;
    }
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 45px 0 0 0;

    @media #{$medium-screen} {
      flex-direction: column;
    }

    a {
      background: white;
      color: $violet;
      padding: 12px 20px;
      border: 1px solid $violet;
      border-radius: 20px;
      text-decoration: none;
      font-size: 14px;
      align-self: flex-start;

      @extend %big-first-letter;

      @media #{$medium-screen} {
        margin: 20px 0 0 0;
        order: 2;
      }
    }
  }
  
  .submitContainer {
    justify-content: flex-end;
  }

  .linkContainer {
    justify-content: flex-start;
  }

  button {
    background: $violet-gradient;
    position: relative;
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 20px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
    min-width: 140px;

    @extend %big-first-letter;

    &:disabled {
      @extend %disabled-button;
    }

    &.buttonIsLoading {
      color: transparent;
    }

    > svg {
      color: white;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }
}

.error {
  font-size: 12px;
  line-height: 14px;
  color: $red;
  max-width: 360px;
}
