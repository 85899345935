@import "../../../../../../../../variables.scss";

.subtype {
  flex: 1;
  display: flex;
  align-items: center;
  height: 35px;

  @media #{$small-screen} {
    flex: 2;
    flex-wrap: nowrap;
    order: 2;
  }

  > div:first-of-type {
    display: flex;
    flex: 1;
  }

  span {
    font-size: 12px;
    color: #B8B8B8;
    text-transform: uppercase;

    @media #{$small-screen} {
      max-width: 50%;
    }
  }

  .wrapper {
    max-width: 150px;
    width: 100%;
    position: relative;

    @media #{$small-screen} {
      max-width: 120px;
    }

    .inputContainer {
      display: flex;
      width: 100%;
      position: relative;


      button {
        @extend %big-first-letter;
        border: none;
        background: #F9F9F9;
        border-radius: 20px;
        padding: 8px 25px 8px 20px;
        margin: 0 10px;
        color: $dark-grey;
        font-size: 12px;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .dropdown {
      position: absolute;
      width: 100%;
      background: #FCFCFC;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 10px 15px;
      z-index: 3;

      ul {
        list-style: none;

        li {
          margin: 5px 0;

          button {
            @extend %big-first-letter;
            background: transparent;
            border: none;
            font-size: 12px;
            color: $dark-grey;
            text-align: left;
          }
        }
      }
    }
  }
}