@import "../../../../../../../variables.scss";

.questionImg {
  align-self: flex-start;
  margin: 0 0 0 71px;
  padding: 0 0 25px 0;
  max-width: calc(100% - 100px);
  height: auto;

  @media #{$small-screen} {
    margin: 0 40px;
    max-width: calc(100% - 80px);
  }
}
