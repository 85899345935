@import "../../../../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.testInfo {
  border-bottom: 1px solid #5E5EB6;
  margin: 0 0 20px 0;

  @media #{$medium-screen} {
    margin: 0 0 10px 0;
  }

  @media #{$small-screen} {
    margin: 0 0 4px 0;
  }

  @media #{$extra-small-screen} {
    margin: 0 0 3.57px 0;
  }

  .top {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    margin: 0 0 10px 0;

    @media #{$medium-screen} {
      font-size: calc(12px / 2);
      margin: 0 0 5px 0;
    }
  
    @media #{$small-screen} {
      font-size: calc(12px / 2.5);
      margin: 0 0 4px 0;
    }
  
    @media #{$extra-small-screen} {
      font-size: calc(12px / 2.8);
      margin: 0 0 3.57px 0;
    }

    .textContainer {
      margin: 0 30px 0 0;

      @media #{$medium-screen} {
        margin: 0 15px 0 0;
      }
    
      @media #{$small-screen} {
        margin: 0 12px 0 0;
      }
    
      @media #{$extra-small-screen} {
        margin: 0 10.71px 0 0;
      }

      span {
        display: inline-block;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .bottom {
    margin: 15px 0;

    @media #{$medium-screen} {
      margin: 7.5px 0
    }
  
    @media #{$small-screen} {
      margin: 6px 0
    }
  
    @media #{$extra-small-screen} {
      margin: 5.35px 0
    }

    h1 {
      font-size: 20px;
      line-height: 20px;
      color: $dark-grey;
      font-weight: 500;

      @media #{$medium-screen} {
        font-size: calc(28px / 2);
        line-height: calc(34px / 2);
      }
    
      @media #{$small-screen} {
        font-size: calc(28px / 2.5);
        line-height: calc(34px / 2.5);
      }
    
      @media #{$extra-small-screen} {
        font-size: calc(28px / 2.8);
        line-height: calc(34px / 2.8);
      }
    }
  }
}
