@import "../../../variables.scss";

.printOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px 0;

  span {
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey;

    @media #{$small-screen} {
      font-size: 10px;
    }
  }

  .options {
    display: flex;
    align-items: center;

    @media #{$small-screen} {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .checkboxContainer {
      display: flex;
      margin: 0 0 0 20px;

      @media #{$small-screen} {
        margin: 10px 0 0 0;
      }

      label {
        padding-left: 19px;
      }

      button {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        outline: none;

        span {
          line-height: 19px;
        }
      }
    }
  }
}
