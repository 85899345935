@import "../../../variables.scss";
@import "../Modal/styles.module.scss";

.quillContainer {
  width: 100%;
}

.formulaModal {
  width: 90vw;
  max-width: 400px;
  max-height: 400px;
  overflow: auto;
}

.formulaModalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 30px 0 0 0;

  h2 {
    @extend %big-first-letter;
    font-size: 18px;
    margin: 0 0 20px 0;
    text-align: center;
    color: $violet;
  }

  .saveFormulaButton {
    @extend %violet-gradient-button;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 20px 0 10px 0;
    min-width: 145px;
    display: flex;
    font-size: 14px;
    transition: all 0.5s ease;

    span {
      @extend %big-first-letter;
    }
  }

  .listOfTheSymbolsLink {
    text-align: center;
    margin: 20px 0 0 0;
    display: flex;
    align-self: flex-start;
    font-size: 14px;
    text-decoration: underline;
    color: $violet;
  }
}
