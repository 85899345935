@import "../../../../../../variables.scss";

@keyframes changeColor {
  from { color: $violet; }
  to { color: white; }
}

.listItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 195px;
  height: 195px;
  margin: 20px 15px 20px 0;
  border-radius: 12px;
  box-shadow: $box-shadow;
  padding: 15px 15px 30px 15px;
  position: relative;

  @media #{$small-screen} {
    width: 132px;
    height: 132px;
    font-size: 18px;
    margin: 15px 15px 15px 0;
  }

  &:hover {
    background: $violet;

    .header .statusContainer >span {
      color: white;
    }

    > span {
      animation-name: changeColor;
      animation-duration: 1s;
      animation-iteration-count: 1;
      color: white;

      @media #{$small-screen} {
        animation: none;
      }
    }

    > svg {
      display: none;
    }

    .seeMoreLink {
      position: initial;
      height: auto;
      width: auto;
      opacity: 1;
      visibility: visible;
    }

    .onlineTestLabel {
      background: white;
  
      > svg {
        path {
          fill: #5858B1;;
        }
      }
    }
  }

  > svg {
    @media #{$small-screen} {
      display: none;
    }
  }

  .seeMoreLink {
    @extend %big-first-letter;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    text-decoration: none;
    font-size: 14px;
    padding: 8px 10px;
    align-self: baseline;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear, background-color 0.2s ease;

    @media #{$small-screen} {
      display: none;
    }

    &:hover {
      color: $violet;
      background: white;
    }
  }

  .header {
    display: flex;
    width: 100%;

    .statusContainer {
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;

      > span {
        text-transform: uppercase;
        color: $dark-grey;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon_in_progress, .icon_completed {
        width: 8px;
        min-width: 8px;
        height: 8px;
        min-height: 8px;
        border-radius: 50%;
        margin: 0 10px 0 0;
      }

      .icon_in_progress {
        background: $yellow;
      }

      .icon_completed {
        background: $green-light;
      }
    }
  }

  > span {
    font-size: 14px;
    color: $dark-grey;
    text-align: left;
    width: 100%;
  }

  .testSettings {
    display: flex;
    z-index: 1;

    @media #{$small-screen} {
      display: none;
    }

    .testSettingsButton {
      display: flex;
      border: none;
      background: transparent;

      svg circle {
        transition: all 0.5s ease;
      }

      &:hover {
        svg circle {
          fill: white;
        }
      }
    }

    .testSettingsDropdown {
      position: absolute;
      left: 0;
      top: 0;
      margin: 15px 20px;
      padding: 5px;
      width: calc(100% - 50px);
      background: white;
      list-style: none;
      border-radius: 8px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

      li {
        display: flex;
        padding: 3px 5px;

        button, a {
          display: flex;
          align-items: center;
          width: 100%;
          border: none;
          background: transparent;
          font-size: 12px;
          color: $dark-grey;
          text-transform: uppercase;
          letter-spacing: 1px;
          text-decoration: none;
          padding: 2px 7px;

          &:hover {
            color: $violet;

            svg path {
              fill: $violet;
            }
          }

          svg {
            width: 12px;
            height: 12px;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }

  .onlineTestLabel {
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    right: 0;
    width: 40px;
    height: 40px;
    background: #5858B1;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 8px;
    top: 50%;
    transform: translate(0%, -50%);

    @media #{$small-screen} {
      width: 30px;
      height: 30px;
      padding: 5px;
    }

    > svg {
      path {
        fill: white;
      }
    }
  }
}

.deleteQuestionModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
