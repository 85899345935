@import "../../../../../../../variables.scss";

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 0 18px 0;

  @media #{$small-screen} {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .order {
    background: $violet;
    color: white;
    padding: 8px 5px;
    text-align: center;
    height: 35px;
    width: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 16px;
    align-self: flex-start;
    margin: 0 20px 0 0;

    @media #{$small-screen} {
      order: 1;
    }
  }

  .body {
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $violet;
    padding: 9px 30px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;

    .bold {
      font-weight: 600;
    }

    @media #{$small-screen} {
      flex: auto;
      width: 100%;
      order: 3;
      padding: 9px 20px;
    }
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 40px 0 0;

    .pointsNumber {
      font-size: 14px;
      color: #545454;
      margin: 0 0 0 15px;
    }

    @media #{$small-screen} {
      order: 2;
      padding: 12px 30px 0 0;
    }
  }
}
