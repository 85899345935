@import "../../../../variables.scss";


.greenText {
  color: $green;
}

.navyBlueText {
  color: #5858B1;
}

.referrals {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 -7px;

  @media #{$small-screen} {
    width: calc(100% + 14px);
  }

  .headingItem, .listItem {
    > .identificationNumber {
      width: 5%;
      display: flex;
      justify-content: flex-start;
      padding: 0 5px;

      @media #{$small-screen} {
        width: 20%;
        height: 30px;
      }
    }
    > .emailAddress {
      width: 25%;
      display: flex;
      justify-content: flex-start;

      @media #{$small-screen} {
        width: 60%;
      }
    }
    > .signUpDate {
      width: 18%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .signUpConfirmationDate {
      width: 18%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .registrationStatus {
      width: 19%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .discountReceived {
      width: 15%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .actions {
      display: none;
      width: 20%;
      justify-content: flex-end;
      align-items: center;

      @media #{$small-screen} {
        display: flex;
      }

      button, a {
        justify-content: center;
        display: flex;
        border: none;
        background: transparent;
        padding: 5px;
        margin: 0 5px;

        svg {
          cursor: pointer;
          path {
            fill: #B8B8B8;
          }
        }

        &:hover {
          svg path {
            fill: $violet;
          }
        }
      }

      .toggleIcon {
        display: none;

        @media #{$small-screen} {
          display: flex;
        }

        svg, svg path {
          transition: all 0.5s ease;
        }

        &.active {
          svg {
            transform: rotate(180deg);
            path {
              fill: $violet;
              stroke: $violet;
            }
          }
        }
      }
    }
  }

  .headingItem {
    width: calc(100% - 16px);
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 2px 8px;
    padding: 10px;

    @media #{$small-screen} {
      display: none;
    }

    div {
      height: 30px;
      align-items: center;
      span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
        color: $medium-grey;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .listItem {
    width: calc(100% - 16px);
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 8px;
    padding: 10px;

    @media #{$small-screen} {
      flex-wrap: wrap;
      height: auto;
    }

    div {
      display: flex;
      height: 100%;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 5px 20px;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .identificationNumber {
      span {
        padding: 0;
      }
    }

    .emailAddress {
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: initial;
        align-self: center;
        justify-content: flex-start;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        color: $medium-grey;
        padding: 0;
      }
    }

    .signUpDate, .signUpConfirmationDate, .registrationStatus, .discountReceived {
      span {
        font-weight: 900;
      }
    }

    .registrationStatus {
      text-transform: uppercase;

      span {
        padding: 0;
        span {
          padding: 0;
        }
      }

      svg {
        min-width: 20px;
      }
    }

    .dropDownContainer {
      display: none;
      width: 100%;

      @media #{$small-screen} {
        display: flex;
      }

      > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
          padding: 0;

          > span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            color: $medium-grey;
            text-overflow: ellipsis;
            justify-content: flex-start;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
            padding: 5px 5px 5px 0;
          }

          &.signUpDate, &.signUpConfirmationDate, &.registrationStatus, &.discountReceived {
            span:nth-of-type(1) {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: initial;
              align-self: center;
            }

            span:nth-of-type(2) {
              font-weight: 900;
              color: black;
            }
          }
        }
      }
    }
  }
}
