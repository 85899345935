@import "../../../variables.scss";

.violet {
  color: $violet;
}

.darkGrey {
  color: $dark-grey;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
}

.settingsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 794px;
  justify-content: flex-end;
  margin: 25px 0 100px 0;

  @media #{$small-screen} {
    margin: 45px 0 0 0;
  }

  .dndEnabledBtn {
    @extend %transparent-button;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    right: 10px;
    padding: 10px 30px;
    background: transparent;
    height: 40px;
    margin: 0 10px;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }

    @media #{$small-screen} {
      margin: 0 0 10px 10px;
    } 
  }

  .previewBtn {
    @extend %transparent-button;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    right: 10px;
    padding: 10px 30px;
    background: transparent;
    height: 40px;

    span {
      @extend %big-first-letter;
      font-size: 14px;
      text-align: center;
    }

    @media #{$small-screen} {
      margin: 0 0 10px 10px;
    }
  }

  button {
    @extend %violet-gradient-button;
    @extend %big-first-letter;

    padding: 10px 50px;
    font-size: 14px;
    margin: 0 0 0 15px;
    height: 40px;
    text-align: center;

    &:disabled {
      @extend %disabled-button;
    }
  }
}

.testContent {
  display: flex;
  align-items: center;
  max-width: 794px;
  width: 100%;
  flex-wrap: wrap;
}

.summaryBox {
  display: flex;
  width: calc(100% - 100px);
  max-width: 794px;
  flex-wrap: wrap;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
  align-items: center;
  margin: 0 0 16px 0;

  @media #{$small-screen} {
    width: 100%;
  }

  > span {
    @extend %big-first-letter;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-end;

    @media #{$small-screen} {
      flex-direction: column;
      flex: auto;
      width: 100%;
      margin: 10px 0 0 0;
      justify-content: flex-start;
    }

    .textContainer {
      @extend %big-first-letter;
      margin: 0 15px 0 15px;
      font-size: 14px;
      color: #545454;

      @media #{$small-screen} {
        margin: 3px 0;
      }
    }
  }
}

.dndQuestionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 8px;
  max-width: 794px;
  width: 100%;
  margin: 0 0 10px 0;

  &:last-child {
    margin: 0;
  }

  > div {
    width: 100%;
  }
}

.dndQuestionDraggable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  width: calc(100% - 100px);
  max-width: 794px;
  position: relative;
  margin: 0 0 20px 0;
  z-index: 2;
  user-select: none;
  padding: 15px 0;

  > div {
    width: 100%;
  }

  @media #{$small-screen} {
    width: 100%;
  }
}
