@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;

    h3 {
      display: flex;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      > a {
        text-decoration: none;
        color: $violet;
      }

      span {
        @extend %big-first-letter;
      }

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-wrap: wrap;

      button {
        margin: 5px 20px 5px 0;

        &:disabled {
          @extend %disabled-button;
        }
      }

      a {
        margin: 5px 0;
      }

      > a,
      button {
        @extend %violet-gradient-button;
        padding: 10px 30px;
        display: flex;
        font-size: 14px;

        span {
          &:last-child {
            @extend %big-first-letter;
          }
        }
      }
    }
  }

  .view {
    flex: 1;
    height: 100%;
    background: $blue-grey;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    @media #{$medium-screen} {
      max-width: 100vw;
    }

    .settings {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 25px auto 40px auto;

      @media #{$small-screen} {
        width: 100%;
      }

      .sectionText {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: #bebebe;
        margin: 5px 30px 5px 30px;
      }

      .settingsBox {
        display: flex;
        flex-direction: column;
        width: 100%;

        .generatePublicLinkBox {
          display: flex;
          flex-wrap: wrap;
          background: white;
          border-radius: 8px;
          min-width: 520px;
          width: 100%;
          margin: 20px auto 0 auto;
          padding: 20px 30px;

          @media #{$small-screen} {
            min-width: auto;
          }

          > span {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.7;
            text-transform: uppercase;
            color: $medium-grey;
          }

          > p {
            flex: 1;
            font-size: 14px;
            min-width: 300px;
            margin: 10px 20px 10px 0;
            font-weight: 400;
            letter-spacing: 1.7;
            color: #545454;

            @media #{$small-screen} {
              min-width: 100%;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            margin: 10px 0;

            .inputContainer {
              width: 100%;
              margin: 0;
              position: relative;

              input {
                border: 1px solid #5858b1;
                color: #5858b1;
                border-radius: 8px;
                font-size: 14px;
                padding: 12px 120px 12px 20px;
                width: 100%;
                min-width: 300px;
                margin: 0;

                @media #{$small-screen} {
                  min-width: 100%;
                }
              }

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                min-width: 115px;
                background: #5858b1;
                border-radius: 8px;
                border: none;
                z-index: 1;
                height: 100%;
                font-size: 14px;
                color: white;
                margin: 0;

                span {
                  @extend %big-first-letter;
                }
              }
            }

            .regenerateLinkButton {
              @extend %big-first-letter;
              margin: 20px 0 0 0;
              border: none;
              color: $violet;
              font-size: 14px;
              font-weight: 600;
              background: transparent;
              padding: 0;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .lessons {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      margin: 0 -7px;

      .headingItem,
      .listItem {
        > .lessonTopic {
          width: 28%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @media #{$small-screen} {
            flex: 1;
            width: 50%;
            height: 30px;
          }
        }
        > .lessonSubject {
          width: 20%;
          display: flex;
          justify-content: center;

          @media #{$small-screen} {
            display: none;
          }
        }
        > .lessonDate {
          width: 10%;
          display: flex;
          justify-content: center;

          @media #{$small-screen} {
            display: none;
          }
        }
        > .lessonStatus {
          width: 19%;
          display: flex;
          justify-content: center;

          @media #{$small-screen} {
            display: none;
          }
        }
        > .actions {
          width: 23%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media #{$small-screen} {
            flex: 1;
            width: 50%;
            height: 30px;
            min-width: 200px;
          }

          button,
          a {
            justify-content: center;
            display: flex;
            border: none;
            background: transparent;
            padding: 5px;
            margin: 0 5px;

            svg {
              cursor: pointer;
              width: 18px;
              max-height: 16px;
              path {
                fill: #b8b8b8;
              }
            }

            &:hover {
              svg path {
                fill: $violet;
              }
            }
          }

          .toggleIcon {
            display: none;

            @media #{$small-screen} {
              display: flex;
            }

            svg,
            svg path {
              transition: all 0.5s ease;
            }

            &.active {
              svg {
                transform: rotate(180deg);
                path {
                  fill: $violet;
                  stroke: $violet;
                }
              }
            }
          }

          .tooltipContainer {
            .tooltip {
              display: flex;
              justify-content: center;
              align-items: center;
              color: color;
              height: 25px;
              max-width: 300px;
              background: $pink;
              padding: 10px 20px;
              font-size: 12px;
              border-radius: 8px;

              &::after {
                display: none;
              }

              p {
                @extend %big-first-letter;
              }
            }

            i {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .headingItem {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background: transparent;
        margin: 2px 8px;
        padding: 10px;

        @media #{$small-screen} {
          display: none;
        }

        div {
          height: 30px;
          align-items: center;
          span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            color: $medium-grey;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .listItem {
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        margin: 8px;
        padding: 10px;

        @media #{$small-screen} {
          flex-wrap: wrap;
          height: auto;
        }

        div {
          display: flex;
          height: 100%;
          span {
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 5px;
            font-weight: 400;
          }
        }

        .lessonDate,
        .lessonSubject {
          span {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: #545454;
          }
        }

        .lessonSubject {
          text-transform: uppercase;
        }

        .lessonTopic {
          .tooltipContainer {
            .tooltip {
              color: color;
              height: auto;
              max-width: 200px;
              background: $pink;
              padding: 10px 20px;
              font-size: 12px;
              border-radius: 8px;

              &::after {
                display: none;
              }

              p {
                display: flex;
                @extend %big-first-letter;
              }
            }
          }

          span {
            display: block;
            background: $main-color;
            border-radius: 4px;
            color: $violet;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;
            max-width: 250px;
            padding: 5px 15px;
          }
        }

        .lessonStatus {
          div {
            label {
              input {
                height: 16px;
                width: 31px;
                background: #f4f4f4;
              }

              i {
                height: 14px;
                width: 14px;
              }
            }

            span {
              font-size: 12px;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }
        }

        .dropDownContainer {
          display: none;
          width: 100%;

          @media #{$small-screen} {
            display: flex;
          }

          > ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 10px 0 0 0;
            padding: 0;
            list-style: none;
            li {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              width: 100%;
              margin: 0;
              padding: 0;

              > span {
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 1px;
                font-weight: 600;
                text-transform: uppercase;
                color: $medium-grey;
                text-overflow: ellipsis;
                justify-content: flex-start;
                overflow: hidden;
                white-space: nowrap;
                flex: 1;
                padding: 5px 5px 5px 0;
              }

              &.lessonSubject,
              &.lessonDate {
                span:nth-of-type(2) {
                  font-weight: 600;
                  color: #545454;
                }
              }

              &.lessonStatus {
                div {
                  flex: 1;
                  padding: 0 5px 0 0;

                  label {
                    input {
                      margin: 0 5px 0 0;
                    }
                  }

                  span {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.deleteLessonModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}

.hiddenInput {
  opacity: 0;
  position: absolute;
  width: 10px;
  z-index: -1;
}

.containerPublic {
  background: $blue-grey;
  flex: 1;
  overflow: auto;

  .view {
    .lessons {
      width: 100%;
      padding: 0 30px;
      margin: 20px 0;

      @media #{$small-screen} {
        padding: 0 10px;
      }

      .headingItem,
      .listItem {
        .lessonTopic {
          width: 47%;
        }
      }

      .headingItem {
        width: calc(100% - 16px);
      }

      .listItem {
        margin: 8px 0;
      }
    }
  }
}
