@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  max-width: 1500px;
  justify-self: center;
  margin: 0 auto 0 0;

  > h1 {
    @extend %big-first-letter;
    font-size: 32px;
    color: $violet-dark;
    font-weight: 600;

    span {
      text-transform: capitalize;
      font-weight: 400;
    }
  }

  > p {
    @extend %big-first-letter;
    color: $dark-grey;
    margin: 5px 0;
  }
}

.topContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 25px -10px 20px -10px;
}

.userBox {
  display: flex;
  background: white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: 22px;
  width: 424px;
  min-width: 424px;
  height: 200px;
  margin: 10px;
  position: relative;
  overflow: hidden;

  @media #{$small-screen} {
    width: 100%;
    min-width: calc(100% - 20px);
    height: auto;
  }

  > svg {
    position: absolute;
    left: -1px;
    bottom: -1px;
  }

  > div {
    z-index: 1;
  }

  .userBoxContent {
    display: flex;
    flex: 1;
    max-height: 100px;
    max-width: 100%;
    align-items: center;

    svg {
      width: 100px;
      height: 100px;
    }

    .info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0 0 0 30px;
      flex: 1;
      height: 100%;
      max-width: 100%;
      justify-content: flex-end;

      span {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 270px;
        color: $dark-grey;
        align-self: flex-start;
        white-space: nowrap;
      }

      a {
        @extend %transparent-button;
        @extend %big-first-letter;
        background: white;
        font-size: 14px;
        padding: 8px 20px;
        margin: 10px 0;
        align-self: flex-start;
        text-align: center;
        transition: background-color 0.5s ease;

        &:hover {
          color: white;
          background: $violet;
        }
      }

      @media #{$small-screen} {
        justify-content: center;
        align-items: center;
        height: auto;
        margin: 20px;

        a {
          margin: 20px 0 0 0;
          align-self: center;
        }

        span {
          align-self: center;
          max-width: 100%;
        }
      }
    }

    @media #{$small-screen} {
      justify-content: center;
      max-height: initial;
      flex-wrap: wrap;
    }
  }
}

.boxesLeft {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 444px;
}

.boxesMiddle {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 220px;

  @media #{$small-screen} {
    width: 444px;
  }
}

.boxesRight {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 444px;
}