@import "../../../../../../../variables.scss";

.questionImg {
  align-self: flex-start;
  margin: 0 0 0 71px;
  padding: 0 0 25px 0;
  max-width: 100%;
  height: auto;

  @media #{$medium-screen} {
    width: 45%;
    margin: 0 0 0 35.5px;
    padding: 0 0 12.5px 0;
  }

  @media #{$small-screen} {
    margin: 0 0 0 28.4px;
    padding: 0 0 10px 0;
  }

  @media #{$extra-small-screen} {
    margin: 0 0 0 25.35px;
    padding: 0 0 8.92px 0;
  }
}
