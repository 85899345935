@import "../../../../../../../../variables.scss";

.assignSkillModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0 0 0;

    @media #{$small-screen} {
      flex-direction: column;
      align-items: center;
    }

    button {
      @extend %big-first-letter;
      @extend %violet-gradient-button;
      font-size: 14px;
      padding: 10px 30px;
      min-width: 140px;
      margin: 10px 0;

      &:first-of-type {
        @extend %transparent-button;
      }

      &:disabled {
        @extend %disabled-button;
      }
    }
  }
}
