@import "../../../../../../variables.scss";

.questions {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;

  .headingItem, .listItem {
    > .questionNumber {
      width: 7%;
      display: flex;
      justify-content: flex-start;

      @media #{$small-screen} {
        height: 30px;
        width: 10%;
      }
    }
    > .questionType {
      width: 30%;
      display: flex;
      justify-content: flex-start;

      @media #{$small-screen} {
        width: 70%;
        height: 30px;
      }
    }
    > .answerGiven {
      width: 20%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .correctAnswer {
      width: 20%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .gainedPoints {
      width: 15%;
      display: flex;
      justify-content: center;

      @media #{$small-screen} {
        display: none;
      }
    }
    > .actions {
      width: 8%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media #{$medium-screen} {
        > span {
          display: none;
        }
      }

      @media #{$small-screen} {
        width: 20%;
        height: 30px;
      }

      button {
        border: none;
        background: transparent;
        padding: 5px;
        margin: 0 5px;

        svg {
          cursor: pointer;
          path {
            fill: #B8B8B8;
          }
        }

        &:hover {
          svg path {
            fill: $violet;
          }
        }
      }

      .toggleIcon {
        display: none;

        @media #{$small-screen} {
          display: flex;
        }

        svg, svg path {
          transition: all 0.5s ease;
        }

        &.active {
          svg {
            transform: rotate(180deg);
            path {
              fill: $violet;
              stroke: $violet;
            }
          }
        }
      }

      .tooltipContainer {
        .tooltip {
          display: flex;
          justify-content: center;
          align-items: center;
          color: color;
          height: 25px;
          max-width: 300px;
          background: $pink;
          padding: 10px 20px;
          font-size: 12px;
          border-radius: 8px;
      
          &::after {
            display: none;
          }
      
          p {
            @extend %big-first-letter;
          }
        }
      
        i {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .headingItem {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 2px 10px;
    padding: 10px;

    @media #{$small-screen} {
      display: none;
    }

    div {
      height: 30px;
      align-items: center;
      span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
        color: $medium-grey;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .listItem {
    width: calc(100% - 20px);
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 8px 10px;
    padding: 10px 15px;

    @media #{$small-screen} {
      flex-wrap: wrap;
      height: auto;
    }

    div {
      display: flex;
      height: 100%;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        font-weight: 400;
      }
    }

    .questionNumber {
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        font-weight: 600;
        color: #545454;
        padding: 0;
      }
    }

    .questionType {
      display: flex;
      align-items: center;
      span {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: #BEBEBE;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0;
      }
    }

    .answerGiven {
      > span {
        font-weight: 600;
        color: #545454;
        font-size: 12px;

        &.correct {
          color: $green;
        }

        &.incorrect {
          color: $red;
        }
      }

      > button {
        @extend %big-first-letter;
        @extend %violet-gradient-button;
        font-size: 12px;
        padding: 2px 5px;
        margin: 0 5px 0 0;
        min-width: 90px;
      }
    }

    .correctAnswer {
      span {
        font-weight: 600;
        color: #545454;
        font-size: 12px;
      }
    }

    .gainedPoints {
      span {
        font-weight: 600;
        color: #545454;
        font-size: 12px;

        > input {
          border-radius: 20px;
          max-width: 100px;
          width: 100%;
          padding: 5px;
          margin: 0 5px 0 0;
          text-align: center;
          font-weight: 600;
          height: 100%;
        }

        > input.empty {
          border: 1px solid $red;
        }

        > input.filled {
          border: 1px solid $light-grey;
        }

        span {
          padding: 0;
          &.zero {
            color: $red;
          }
        }
      }
    }

    .dropDownContainer {
      display: none;
      width: 100%;

      @media #{$small-screen} {
        display: flex;
      }

      > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
          padding: 0;

          > span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            color: $medium-grey;
            text-overflow: ellipsis;
            justify-content: flex-start;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
            max-width: 50%;
            padding: 5px 5px 5px 0;
          }

          &.answerGiven {            
            span:nth-of-type(2) {
              font-weight: 600;
              color: #545454;

              &.correct {
                color: $green;
              }
      
              &.incorrect {
                color: $red;
              }
            }

            .answerChoiceCheckboxContainer {
              flex: 1;
            }
          }

          &.correctAnswer {
            span:nth-of-type(2) {
              font-weight: 600;
              color: #545454;
            }
          }

          &.gainedPoints {
            span:nth-of-type(2) {
              font-weight: 600;
              color: #545454;

              > input.empty {
                border: 1px solid $red;
              }
      
              > input.filled {
                border: 1px solid $light-grey;
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  max-width: 500px;
  height: auto;
  max-height: 90vh;
  overflow: auto;

  @media #{$small-screen} {
    max-width: 300px;
  }
}

.openQuestionAnswerModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    margin: 10px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0 0 0;

    button {
      @extend %big-first-letter;
      @extend %violet-gradient-button;
      font-size: 14px;
      padding: 10px 20px;
      margin: 0 5px 0 0;
      min-width: 120px;

      @media #{$small-screen} {
        min-width: 100px;
      }

      &:disabled {
        @extend %disabled-button;
      }
    }
  }
}
