@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1;
  background: $whitey-grey;
  overflow: auto;
  padding: 65px 0 0 0;

  .innerContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    .box {
      display: flex;
      width: calc(100% - 50px);
      max-width: 775px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      background: white;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
      border-radius: 8px;
      padding: 70px 40px;
      margin: 40px 0;

      .left {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 50%;

        @media #{$small-screen} {
          max-width: 100%;
        }

        h1 {
          @extend %big-first-letter;
          font-weight: 600;
          font-size: 32px;
          color: #5858b1;
          margin: 10px 0;
        }

        p {
          font-weight: 300;
          font-size: 16px;
          color: $grey;
          margin: 10px 0;
        }
      }

      svg {
        flex: 1;

        @media #{$small-screen} {
          width: 100%;
          flex: auto;
        }
      }
    }
  }
}
