@import "../../../../../../../../variables.scss";

.trueFalse {
  display: flex;
  width: 100%;
  padding: 0 30px 17px 70px;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  @media #{$small-screen} {
    flex-direction: column;
    padding: 0 30px 17px 25px;
  }

  .trueFalseBody {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $violet;
    flex: 1;
    border: 1px solid #B8B8B8;
    padding: 20px 10px;
    margin: 0px 20px 0px 0px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;

    @media #{$small-screen} {
      margin: 0 0 20px 0;
    }
  }

  .trueFalseAnswerContainer {
    display: flex;

    @media #{$small-screen} {
      align-self: flex-start;
    }

    .trueFalseAnswer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #B8B8B8;
      font-size: 24px;
      line-height: 29px;
      color: #B8B8B8;
      width: 40px;
      height: 40px;
      margin: 0 -1px 0 0;
    }

    .trueFalseAnswerCorrect {
      background: $violet;
      color: white;
    }
  }
}
