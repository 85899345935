@import "../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 440px;
  margin: 15px 0 0 0;

  @media #{$medium-screen} {
    > div {
      width: calc(100% - 30px) !important;
      align-self: flex-start;
    }
  }

  .linkContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 45px 0 0 0;

    @media #{$medium-screen} {
      flex-direction: column;
    }

    a {
      background: $violet-gradient;
      color: white;
      padding: 12px 20px;
      border-radius: 20px;
      text-decoration: none;
      font-size: 14px;
      align-self: flex-start;
      font-weight: 400;
      border: none;

      @extend %big-first-letter;

      @media #{$medium-screen} {
        margin: 20px 0 0 0;
        order: 2;
      }
    }
  }
}

.info {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin: 0 0 35px 0;
}
