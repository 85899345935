@import "../../../../../variables.scss";

.boxSubscription {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  background: $main-color;
  width: calc(50% - 8px);
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  padding: 23px;
  max-width: 1000px;

  @media #{$medium-screen} {
    margin: 0 0 20px 0;
    width: 100%;
    max-width: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $violet;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 35px 0 0 0;
    > p {
      @extend %big-first-letter;
      font-size: 14px;
      color: $violet;
    }

    .daysTillOver {
      display: flex;
      flex-direction: column;
      margin: 30px 0 0 0;

      > .heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        > p {
          font-size: 14px;
          color: $violet;
        }

        > div {
          span {
            color: $violet;
            font-weight: 600;
          }
          span:first-of-type {
            font-size: 36px;
            line-height: 29px;
          }
          span:last-of-type {
            font-size: 14px;
          }
        }
      }

      > i {
        width: 100%;
        background: white;
        height: 8px;
        border-radius: 8px;
        margin: 10px 0 0 0;
        position: relative;

        > i {
          content: "";
          position: absolute;
          background: $violet-gradient;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 8px;
        }
      }
    }

    > button {
      @extend %violet-gradient-button;
      @extend %big-first-letter;
      padding: 10px 12px;
      margin: 30px 0;
      align-self: flex-end;

      span {
        font-size: 12px;
      }
    }

    .subscriptionDescription {
      margin: 10px 0 0 0;
    }
  }

  .bottomIcon {
    margin: 25px 0 0 0;
    max-width: 100%;

    @media #{$medium-screen} {
      display: none;
    }
  }
}
