@import "../../../../../../variables.scss";

.questionMainContainer {
  display:  flex;
  flex-direction: row;
  width: 100%;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  background: white;
  padding: 15px 0;
  box-shadow: $box-shadow;
  border-radius: 8px;
  width: calc(100% - 100px);
  position: relative;
  z-index: 2;
  margin: 0 0 20px 0;

  @media #{$small-screen} {
    width: 100%;
  }

  .fullQuestionInProgress {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f4f67a;
    border-radius: 8px;

    svg {
      font-size: 18px;
      color: $violet;
    }
  }

  .content {
    display: flex;
    width: 100%;
    padding: 25px;

    @media #{$small-screen} {
      flex-direction: column;
    }
  }

  > .questionImgContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    flex-wrap: wrap;
    margin: 0 25px 25px 25px;

    .questionImg {
      max-width: 100%;
      align-self: flex-start;
      padding: 0;
      margin: 0;
    }

    > button {
      display: flex;
      align-self: center;
      border: none;
      background: transparent;
      margin: 10px;
      padding: 0;

      svg path {
        fill: #B8B8B8;
      }
    }
  }
}

.questionContainerNotCollapsed {
  min-height: 350px;
}

.settingsMobile {
  display: none;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100%;
  flex-wrap: wrap;

  @media #{$small-screen} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 10px;
    border: none;
    font-size: 10px;
    text-transform: uppercase;
    color: #545454;
    transition: all 0.3s ease;
    text-align: left;
    max-width: 150px;

    @media #{$small-screen} {
      justify-content: flex-start;
      padding: 10px 0;
    }

    svg {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;

      path {
        fill: #545454;
      }
    }

    &:hover {
      color: #5858B1;
      svg {
        margin: 0 8px 0 0;
  
        path {
          fill: #5858B1;
        }
      } 
    }
  }
}

.fillInTheGapsGapsOptionsContent {
  display: flex;
  width: 100%;
  padding: 25px 25px 0 25px;

  @media #{$small-screen} {
    flex-direction: column;
  }
}

.fillInTheGapsGapsOptions {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid $violet;
  list-style: none;

  > li {
    font-size: 14px;
    color: $violet;
    margin: 5px;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;

  @media #{$small-screen} {
    display: none;
  }

  %settings-button {
    background: #F9F9F9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0;
    outline: none;
    width: 100px;
    text-align: center;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }

  .collapseQuestionButton {
    @extend %settings-button;
    font-size: 12px;
    padding: 15px;
    color: #545454;
    margin: 0 0 15px 0;

    &:hover {
      background: #5858B1;
      color: white;
    }
  }

  .collapseQuestionButtonActive {
    @extend .collapseQuestionButton;

    background: #5858B1;
    color: white;

    @media #{$small-screen} {
      background: transparent;
      color: #545454;
    }
  }

  button:nth-of-type(2) {
    @extend %settings-button;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 5px;
    font-size: 10px;
    color: #5858B1;
    margin: 0 0 15px 0;
  
    &:hover {
      background: #5858B1;
      color: white;
  
      svg {
        path {
          fill: white;
        }
      }
    }
  
    svg {
      width: 25px;
      height: 25px;
      margin: 0 0 7px 0;
      path {
        fill: #5858B1;
      }
    }
  }

  button:nth-of-type(3) {
    @extend %settings-button;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 5px;
    font-size: 10px;
    color: #5858B1;
    margin: 0 0 15px 0;

    svg {
      width: 25px;
      height: 25px;
      path {
        fill: #5858B1;
      }
    }
  
    &:hover {
      background: #5858B1;
      color: white;
  
      svg {
        path {
          fill: white;
        }
      }
    }
  
    svg {
      margin: 0 0 7px 0;
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    margin: 0 0 20px 0;

    > button {
      @extend %settings-button;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-end;
      padding: 15px 2px;
      font-size: 10px;
      text-transform: uppercase;
      color: #545454;
  
      &:hover {
        background: #5858B1;
        color: white;
  
        svg {
          path {
            fill: white;
          }
        }
      }
  
      svg {
        margin: 0 0 7px 0;
        height: 22px;
        width: 22px;
  
        path {
          fill: #545454;
        }
      }
    }
  }

  .bottomContainerFolded {
    display: none;
  }
}
