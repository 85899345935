@import "../../../../../../../variables.scss";

.questionImg {
  align-self: flex-start;
  margin: 0 0 0 55px;
  padding: 0 0 15px 0;
  max-width: 100%;
  height: auto;

  @media #{$medium-screen} {
    width: 45%;
    margin: 0 0 0 27.5px;
    padding: 0 0 7.5px 0;
  }

  @media #{$small-screen} {
    margin: 0 0 0 22px;
    padding: 0 0 6px 0;
  }

  @media #{$extra-small-screen} {
    margin: 0 0 0 19.64px;
    padding: 0 0 5.35px 0;
  }
}
