@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  max-width: 1500px;
  justify-self: center;
  margin: 0 auto 0 0;

  > h1 {
    @extend %big-first-letter;
    font-weight: 300;
    font-size: 36px;
    color: $violet;
    margin: 0 20px 0 0;

    @media #{$small-screen} {
      font-size: 24px;
    }
  }
}

.topContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 25px -10px 20px -10px;

  @media #{$small-screen} {
    justify-content: center;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:hover {
      a {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      width: 100%;
    }

    a {
      @extend %violet-gradient-button;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      min-width: 120px;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.2s, opacity 0.2s linear;

      @media #{$small-screen} {
        opacity: 1;
        visibility: visible;
      }

      span {
        @extend %big-first-letter;
        font-size: 14px;
      }
    }
  }
}
