@import "../../../../variables.scss";

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: 22px;
  min-width: 424px;
  width: 424px;
  margin: 10px;
  height: 88px;
  position: relative;
  overflow: hidden;

  @media #{$small-screen} {
    width: 100%;
    min-width: calc(100% - 20px);
    height: auto;
    flex-wrap: wrap;
  }

  svg {
    margin: 10px;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $medium-grey;
    margin: 10px;
    flex: 1;
    min-width: 100px;
  }

  > a {
    @extend %transparent-button;
    @extend %big-first-letter;
    background: white;
    align-self: center;
    font-size: 14px;
    padding: 8px 40px;
    margin: 5px 0;
    align-self: flex-end;
    text-align: center;
    transition: background-color 0.5s ease;

    &:hover {
      color: white;
      background: $violet;
    }

    @media #{$small-screen} {
      margin: 15px 0;
    }
  }
}
