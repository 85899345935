@import "../../../../../variables.scss";

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 30px 0;
  z-index: 1;

  .textContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;

    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    .statusContainer {
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;

      > span {
        text-transform: uppercase;
        color: $dark-grey;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon_in_progress, .icon_completed {
        width: 8px;
        min-width: 8px;
        height: 8px;
        min-height: 8px;
        border-radius: 50%;
        margin: 0 10px 0 0;
      }

      .icon_in_progress {
        background: $yellow;
      }

      .icon_completed {
        background: $green-light;
      }
    }
  }

  a, button {
    @extend %violet-gradient-button;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    display: flex;
    font-size: 14px;
    text-align: center;

    span {
      &:last-child {
        @extend %big-first-letter;
      }
    }
  }

  button {
    min-width: 140px;
    &:disabled {
      @extend %disabled-button;
    }
  }

  .buttonContainer {
    display: flex;
    
    > *:first-child {
      margin: 0 10px 0 0;
    }

    a.disabled {
      @extend %disabled-button;
    }
  }
}


.changeTestStatusModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 200px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
