@import "../../../../../../variables.scss";

.container {
  h4.title {
    @extend %big-first-letter;
    font-size: 32px;
    color: $dark-grey;
    font-weight: 400;
    text-align: center;
    margin: 10px 0;

    span {
      font-size: 32px;
      color: $violet;
      font-weight: 600;
    }
  }

  h5 {
    @extend %big-first-letter;
    font-size: 14px;
    color: $dark-grey;
    font-weight: 400;
    text-align: center;
    margin: 20px 0;
  }

  form {
    > * {
      margin: 17px 0;
    }

    .row {
      display: flex;
      justify-content: space-between;

      > * {
        width: 48%;
        margin: 0;
      }

      @media #{$small-screen} {
        flex-direction: column;

        > * {
          width: 100%;
          margin: 0;

          &:nth-child(2) {
            margin: 17px 0 0 0;
          }
        }
      }
    }

    > button {
      @extend %violet-gradient-button;
      display: flex;
      margin: 30px 0 25px auto;
      min-width: 160px;
      align-items: center;
      justify-content: center;
      padding: 10px;

      &:disabled {
        @extend %disabled-button;
      }

      span {
        @extend %big-first-letter;
        font-size: 12px;
      }
    }

    .inputContainer {
      display: flex;
      position: relative;

      @media #{$extra-small-screen} {
        margin: 0;
      }

      &:nth-child(1) {
        flex: 1;
        min-width: 150px;
      }

      > div {
        display: flex;
        width: 100%;

        .wrapper {
          width: 100%;
          position: relative;
          align-items: flex-start;

          > div {
            display: flex;
            width: 100%;
            margin: 0;
            position: relative;

            > div {
              width: 100%;
              margin: 0;
            }
          }

          .dropdown {
            position: absolute;
            width: 100%;
            background: #FCFCFC;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            padding: 10px 15px;
            z-index: 3;
            max-height: 200px;
            overflow: auto;
      
            ul {
              width: 100%;
              list-style: none;
      
              li {
                margin: 5px 0;
      
                button {
                  @extend %big-first-letter;
                  width: 100%;
                  background: transparent;
                  border: none;
                  font-size: 12px;
                  color: $dark-grey;
                  text-align: left;
                }

                span {
                  font-size: 12px;
                  color: $dark-grey;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
