@import "../../../variables.scss";

.container {
  position: relative;
  height: 65px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  background: white;
  
  @media #{$medium-screen} {
    justify-content: space-between;
    padding: 10px 30px;
  }

  @media #{$small-screen} {
    padding: 10px 15px;
  }

  button {
    display: flex;
    background: white;
    flex-direction: row;
    align-items: center;
    justify-self: flex-end;
    border: none;
    padding: 0;
    margin: 0 60px 0 0;

    &:hover {
      svg:nth-of-type(2) path {
        fill: $violet;
      } 
    }

    @media #{$small-screen} {
      margin: 0;
    }

    > span {
      color: $grey;
      font-size: 12px;
      white-space: pre;
      text-overflow: ellipsis;
      text-transform: uppercase;
      overflow: hidden;
      line-height: normal;
    }

    svg {
      &.active {
        path {
          fill: $violet;
        }
      }
    }

  }
}

.avatar {
  min-width: 43px;
  margin: 0 10px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 65px;
  right: 0;
  width: 265px;
  background: white;
  padding: 0;
  box-shadow: $box-shadow;
  z-index: 3;
  margin: 0 0 30px 0;

  @media #{$medium-screen} {
    width: 265px;
  }

  > a, button {
    display: flex;
    align-items: center;
    color: #545454;
    font-size: 14px;
    text-decoration: none;
    padding: 15px 24px;

    &:hover {
      color: $violet;
    }

    > span {
      @extend %big-first-letter;
    }

    > svg {
      width: 16px;
      margin: 0 15px 0 0;
    }
  }

  > a:last-of-type {
    margin: 0 0 15px 0;
  }

  .signOutButton {
    display: flex;
    background: white;
    width: 100%;
    border: none;
    padding: 0;
    background: $main-color;
    padding: 30px 24px;
    margin: 0;

    span {
      @extend %big-first-letter;
    }
  }
}

.dropdown.hidden {
  display: none
}

.logoLink {
  height: 100%;

  .logo {
    display: flex;
    height: 100%;
    width: auto;
    padding: 5px 0;
    align-self: flex-start;
    display: none;
  
    @media #{$medium-screen} {
      display: flex;
    }
  }
}
