@import "../../../../variables.scss";

.mainContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;

  .top {
    h2 {
      font-size: 32px;
      color: $dark-grey;
      font-weight: 300;
      margin: 10px;
      line-height: initial;
      text-align: center;
  
      @extend %big-first-letter;
  
      .primaryHeadingText {
        font-weight: 600;
        color: $violet;
      }

      .secondaryHeadingText {
        font-size: 18px;
      }
    }
  
    p {
      color: $grey;
      font-weight: 300;
      font-size: 16px;
      margin: 5px 10px;
      text-align: center;
      line-height: initial;
  
      @extend %big-first-letter;
    }
  
    .box {
      display: flex;
      background: white;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 45px auto;
      padding: 40px;
      box-shadow: $box-shadow;
      border-radius: 8px;
      width: 100%;
      max-width: 650px;

      @media #{$small-screen} {
        width: auto;
      }

      @media #{$extra-small-screen} {
        flex-direction: column;
        align-items: center;
      }
  
      .inputContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        margin: 0 20px;
        min-width: 150px;
  
        @media #{$extra-small-screen} {
          margin: 0;
        }
  
        &:nth-child(2) {
          max-width: 150px;
        }
      }
    }
  
    .bottomContainer {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: flex-end;
      align-items: center;
    
      .goBackButton {
        @extend %transparent-button;
        position: relative;
        padding: 12px 35px;
        border-radius: 20px;
        margin: 0 15px 0 0;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        min-width: 140px;
    
        @extend %big-first-letter;
      }

      .nextButton {
        @extend %big-first-letter;
        background: $violet-gradient;
        position: relative;
        color: white;
        padding: 12px 35px;
        border: none;
        border-radius: 20px;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        min-width: 140px;

        &:disabled {
          @extend %disabled-button;
        }
      }

      @media #{$small-screen} {
        flex-direction: column;
        align-items: center;

        .goBackButton {
          align-self: center;
          margin: 20px 0 0 0;
          order: 2;
        }

        .nextButton {
          align-self: center;
          order: 1;
        }
      }
    }
  }
}

.tooltipContainer {
  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    color: color;
    max-height: 55px;
    max-width: 300px;
    background: $pink;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 8px;

    &::after {
      display: none;
    }

    p {
      @extend %big-first-letter;
    }
  }

  div {
    @extend %big-first-letter;
  }
}
