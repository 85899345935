@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .settingsBox {
    display: flex;
    flex-direction: column;
    width: 100%;


    .onlineTestLinkBox {
      display: flex;
      flex-wrap: wrap;
      background: white;
      border-radius: 8px;
      min-width: 520px;
      max-width: calc(210mm + 20px);
      width: 100%;
      margin: 20px auto 20px;
      padding: 20px 30px;

      @media #{$small-screen} {
        min-width: auto;
        flex-direction: column;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        margin: 0;

        > span {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1.7;
          text-transform: uppercase;
          color: $medium-grey;
        }

        > p {
          @extend %big-first-letter;
          flex: 1;
          font-size: 14px;
          min-width: 300px;
          margin: 10px 20px 10px 0;
          font-weight: 400;
          letter-spacing: 1.7;
          color: #545454;

          @media #{$small-screen} {
            min-width: 100%;
          }
        }

        .error {
          color: $red;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        margin: 10px 0;

        .inputContainer {
          width: 100%;
          margin: 0;
          position: relative;

          input {
            border: 1px solid #5858B1;
            color: #5858B1;
            border-radius: 8px;
            font-size: 14px;
            padding: 12px 120px 12px 20px;
            width: 100%;
            min-width: 300px;
            margin: 0;

            @media #{$small-screen} {
              min-width: 100%;
            }
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            min-width: 115px;
            background: #5858B1;
            border-radius: 8px;
            border: none;
            z-index: 1;
            height: 100%;
            font-size: 14px;
            color: white;
            margin: 0;

            span {
              @extend %big-first-letter;
            }
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 100px;
  z-index: 2;

  > button {
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 8px;
    border: none;
    transition: background-color 0.2s ease;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
    margin: 0 0 16px 0;

    &.active {
      background: $violet;

      svg path {
        fill: white;
      }
    }

    svg path {
      fill: $violet;
    }

    &:hover {
      background: $violet;

      svg path {
        fill: white;
      }
    }
  }
}
