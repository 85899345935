@import "../../../../../../../../variables.scss";

.contentEditable {
  width: 100%;
  padding: 10px;
  border: 1px solid #D8D8D8;
  border-radius: 20px;
  font-size: 16px;
  line-height: 22px;
  color: $violet;
  overflow-wrap: break-word;

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $violet;
    background: transparent;
    font-size: 16px;
    line-height: 22px;
    border-radius: 0px;
    outline: none;
    color: $violet;
  }
}

.contentEditableError {
  border: 1px solid $red;
}

.addGapButton {
  align-self: flex-start;
  background: transparent;
  color: $violet;
  border: none;
  padding: 0;
  margin: 10px 0 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
