@import "../../../../../variables.scss";

.paymentInfoBox {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  background: white;
  width: 100%;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.054);
  border-radius: 8px;
  padding: 23px;

  @media #{$medium-screen} {
    margin: 0 0 20px 0;
    max-width: 100%;
  }

  .discountTextInfo {
    font-weight: 800;
    font-size: 10px;
    text-transform: uppercase;
    color: $pink;
    text-shadow: 0px 4px 4px rgba(216, 125, 152, 0.64);
    margin: 0 0 5px 60px;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: $violet;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 25px 0 0 0;

    .heading {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      > .actualSubscriptionAmount {
        @extend %big-first-letter;
        font-size: 14px;
        color: #545454;
      }

      .rightContainer {
        display: flex;
        align-items: center;

        .amount {
          display: flex;
          flex-direction: column;

          .old {
            display: flex;
            align-self: flex-end;
            position: relative;
            font-size: 18px;
            color: $violet;
            margin: -22px 0 0 0;

            &:before {
              position: absolute;
              content: "";
              left: 0;
              top: 50%;
              right: 0;
              border-top: 1px solid;
              border-color: inherit;
              color: #E27992;
            
              -webkit-transform:rotate(-5deg);
              -moz-transform:rotate(-5deg);
              -ms-transform:rotate(-5deg);
              -o-transform:rotate(-5deg);
              transform:rotate(-5deg);
            }
          }
          .current {
            span {
              font-size: 14px;
              color: $violet;
              text-transform: uppercase;
              font-weight: 600;
              &:first-of-type {
                font-size: 36px;
              }
            }
          }
        }
  
        .tooltipContainer {
          margin: 0 20px;
          .tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            color: color;
            height: 45px;
            max-width: 300px;
            background: $pink;
            padding: 10px 20px;
            font-size: 12px;
            border-radius: 8px;

            @media #{$small-screen} {
              max-width: 200px;
              height: 55px;
            }
        
            &::after {
              display: none;
            }
        
            p {
              @extend %big-first-letter;
            }
          }
        
          i {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    > .greyText {
      @extend %big-first-letter;
      font-size: 14px;
      color: $medium-grey;

      > span {
        text-transform: uppercase;
      }

      &:nth-of-type(2) {
        margin: 25px 0 0 0;
      }
    }
  }

  .promotionDurationText {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    color: $pink;
    margin: 10px 0 0 0;
  }
}
