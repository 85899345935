@import "../../../variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(88, 88, 177, 0.05);

  .modalSmall {
    height: 100%;
    max-width: 300px;
    max-height: 250px;
  }

  .modalBig {
    height: 100%;
    max-width: 400px;
    max-height: 320px;

    @media #{$small-screen} {
      max-width: 90vw;
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    opacity: 1;

    > div {
      display: flex;
      flex: 1;
      padding: 30px;
      position: relative;

      > button {
        position: absolute;
        border: none;
        background: transparent;
        right: 20px;
        top: 20px;
      }
    }

    .modal {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}
