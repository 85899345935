@import "../../../variables.scss";


.containerBottomBar {
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 80px;
  max-width: 100vw;
  width: 100%;
  padding: 20px 0;
  box-shadow: 8px 0px 16px rgba(0, 0, 0, 0.16);
  display: none;
  align-items: center;
  z-index: 1;

  @media #{$medium-screen} {
    display: flex;
  }

  a, button {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    width: 20%;
    height: 100%;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border: none;
    background: transparent;

    svg {
      height: 21px;
      width: 21px;
    }

    &.active {
      svg path {
        fill: $violet;
      }

      span {
        color: $violet;
      }
    }

    span {
      @extend %big-first-letter;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      color: #ADADAD;
      text-decoration: none;
      font-size: 10px;
      text-align: center;
    }
  }
}

.otherMenu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  width: 100%;
  
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    a {
      @extend %big-first-letter;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #545454;
      text-decoration: none;
    }
  }
}