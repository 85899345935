@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 30px 0;
    
    h3 {
      @extend %big-first-letter;
      font-weight: 300;
      font-size: 36px;
      color: $violet;
      margin: 0 20px 10px 0;

      @media #{$small-screen} {
        font-size: 24px;
      }
    }

    button {
      @extend %transparent-button;
      padding: 10px 30px;
      display: flex;
      font-size: 14px;
      transition: all 0.5s ease;

      svg {
        path {
          fill: $violet;
        }
      }

      &:hover {
        background-color: $violet;

        svg {
          path {
            fill: white;
          }
        }

        span {
          color: white;
        }
      }

      span {
        &:last-child {
          @extend %big-first-letter;
        }
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    > span {
      @extend %big-first-letter;
      margin: 0 10px;
      font-size: 24px;
      color: #5858B1;
      width: 100%;
    }

    .reportsContainer {
      display: flex;
      flex-direction: column;
      padding: 5px 10px;
      min-width: 800px;
      max-height: 467px;
      overflow: auto;
      margin: 0 20px 0 -7px;

      @media #{$medium-screen} {
        min-width: 100%;
        width: 100%;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      margin: 47px 10px 47px 0;
      padding: 20px;
      display: flex;
      min-width: 250px;
      width: 250px;
      min-height: 180px;
      height: 180px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.054);
      border-radius: 8px;

      @media #{$small-screen} {
        width: 100%;
        min-height: 100px;
        height: 100px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      span {
        &:first-of-type {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 1.7px;
          text-transform: uppercase;
          color: $medium-grey;
          width: 90%;

          @media #{$small-screen} {
            width: 50%;
          }
        }

        &:nth-of-type(2) {
          font-weight: 600;
          font-size: 48px;
          color: #A194EF;
          margin: 10px 0 0 0;

          @media #{$small-screen} {
            margin: 0;
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
}

.deleteStudentModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    @extend %big-first-letter;
    color: $dark-grey;
    font-size: 14px;
    max-width: 150px;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      @extend %big-first-letter;
      @extend %transparent-button;
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
